import { useEffect, useState } from 'react';
import useAxios from 'src/hooks/useAxios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import SaveAltOutlined from '@mui/icons-material/SaveAltOutlined';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import SimCardDownloadOutlined from '@mui/icons-material/SimCardDownloadOutlined';
import { SECONDARY_BACKGROUND_COLOR, PRIMARY_COLOR } from 'src/constants';
import UploadFile from '../common/fileUpload';
import { StyledLoadMoreBtn } from '../check';
import CommonAdHocTable from '../adHocTable/table';
import { Tooltip, Typography } from '@mui/material';
import useUploadS3Files from 'src/hooks/useUploadS3Files';
import {
  getPreSignedUrlAPI,
  processFileAPI,
  getFileTransactionsAPI,
  getTransactionUploadHistoryAPI
} from '../apiActions';
import {
  AD_HOC_FILE_TEMPLATES,
  FILE_ACTION,
  PARTNER_UPLOAD_TYPE,
  transactionStatus,
  UPLOAD_STATUS
} from '../constants';
import {
  adhocTransactionsExportToExcel,
  PAGE_SIZE
} from 'src/modules/PartnerUpload/constant';
import CustomUploadDialog from '../uploadDialog';
import { ReactComponent as FileUploadSuccessLogo } from 'src/resources/images/file-upload-success.svg';
import { ReactComponent as FileUploadErrorLogo } from 'src/resources/images/file-upload-error.svg';
import CustomButton from 'src/components/CustomButton';
import CustomDialog from 'src/components/customDialog';
import { downloadReport } from 'src/modules/PaymentCalculations/VendorPayment/helper';
import SimpleBackdrop from 'src/components/Backdrop';
import { RefreshOutlined } from '@mui/icons-material';

function AdHocUploadTransactions() {
  const API = useAxios();
  const [currentPage, setCurrentPage] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [uploadHistoryData, setUploadHistoryData] = useState<any>([]);
  const axiosInstance = useAxios();
  const { enqueueSnackbar } = useSnackbar();

  const fetchUploadHistoryAfterUploadFile = async () => {
    try {
      setShowLoader(true);
      const body = {
        pageNumber: 0,
        limit: PAGE_SIZE,
        partnerUploadType: PARTNER_UPLOAD_TYPE.maker
      };
      const response = await getTransactionUploadHistoryAPI(
        axiosInstance,
        body
      );
      if (response?.data?.dataList?.length) {
        setUploadHistoryData(response?.data?.dataList);
        setCurrentPage(1);
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchUploadHistoryList = async (pageNumber = currentPage) => {
    try {
      setShowLoader(true);
      const body = {
        pageNumber,
        limit: PAGE_SIZE,
        partnerUploadType: PARTNER_UPLOAD_TYPE.maker
      };
      const response = await getTransactionUploadHistoryAPI(
        axiosInstance,
        body
      );
      if (response?.data?.dataList?.length) {
        setUploadHistoryData([
          ...uploadHistoryData,
          ...response?.data?.dataList
        ]);
        setCurrentPage((page) => page + 1);
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchUploadHistoryList();
  }, []);

  //download files
  const handleDownload = async (row) => {
    try {
      setShowLoader(true);
      const body = {
        fileAction: FILE_ACTION.download,
        fileName: row?.fileName || '',
        fileExtension: 'xlsx',
        partnerUploadType: PARTNER_UPLOAD_TYPE.maker,
        uploadId: row?.fileUploadId || ''
      };
      const response = await getPreSignedUrlAPI(API, body);
      if (response?.data?.data?.preSignedUrl) {
        downloadReport(response?.data?.data?.preSignedUrl);
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  // get errors and validation data and then download
  const downloadAdhocFiles = async (row, transactionStatus?: any) => {
    try {
      setShowLoader(true);
      const body = {
        fileUploadId: row?.fileUploadId || '',
        transactionStatus,
        partnerUploadType: PARTNER_UPLOAD_TYPE.maker
      };
      const fileData = await getFileTransactionsAPI(API, body);
      if (fileData?.data?.transactionRequest?.length) {
        const isError = adhocTransactionsExportToExcel(
          fileData?.data?.transactionRequest,
          row?.fileName,
          'maker'
        );
        if (isError) {
          enqueueSnackbar('Something went wrong. Please try again later', {
            variant: 'error'
          });
        }
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  const createCellData = (
    value,
    rowData,
    callback,
    transactionStatus?: any
  ) => {
    return (
      <strong>
        <Button
          color="primary"
          sx={{
            borderRadius: '10px',
            textTransform: 'capitalize',
            color: value === 0 ? SECONDARY_BACKGROUND_COLOR : PRIMARY_COLOR
          }}
          endIcon={<SaveAltOutlined />}
          size="small"
          onClick={() => {
            if (value) {
              callback(rowData, transactionStatus);
            }
          }}>
          {value} rows
        </Button>
      </strong>
    );
  };

  const createFileNameCellData = (rowData) => {
    return (
      <Tooltip
        title={rowData?.fileName}
        TransitionProps={{ timeout: 800 }}
        placement="right-start"
        arrow>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <SimCardDownloadOutlined
            sx={{
              color: 'rgba(0, 0, 0, 0.7)',
              marginRight: '2px'
            }}
          />
          <Typography
            onClick={() => handleDownload(rowData)}
            sx={{ color: PRIMARY_COLOR, cursor: 'pointer', fontSize: '14px' }}
            noWrap>
            {rowData?.fileName}
          </Typography>
          <Typography
            component={'div'}
            sx={{
              color: 'rgba(0, 0, 0, 0.5)',
              fontSize: '14px',
              marginLeft: '6px'
            }}>
            {`(${rowData?.totalRows} rows)`}
          </Typography>
        </Box>
      </Tooltip>
    );
  };

  const columns = [
    {
      field: 'fileUrl',
      headerName: 'File',
      flex: 3,
      renderCell: (params) => createFileNameCellData(params?.row)
    },
    {
      field: 'submittdBy',
      headerName: 'Submitted By',
      flex: 1.2,
      renderCell: (params) => (
        <Typography sx={{ fontSize: '14px' }} noWrap>
          {params?.row?.createdByActorName}
        </Typography>
      )
    },
    {
      field: 'submittdOn',
      headerName: 'Submitted On',
      flex: 1,
      renderCell: (params) =>
        moment(params?.row?.createdOn).format('DD MMM hh:mm a')
    },
    {
      field: 'uploadStatus',
      headerName: 'Upload Status',
      flex: 1,
      renderCell: (params) => UPLOAD_STATUS[params?.row?.uploadStatus]
    },
    {
      field: 'validationErrors',
      headerName: 'Validation Errors',
      flex: 1,
      renderCell: (params) =>
        createCellData(
          params.row?.validationErrorCount,
          params?.row,
          downloadAdhocFiles,
          transactionStatus.invalid
        )
    },
    {
      field: 'pending',
      headerName: 'Pending',
      flex: 0.8,
      renderCell: (params) =>
        createCellData(
          params.row?.submittedCount,
          params?.row,
          downloadAdhocFiles,
          transactionStatus.submitted
        )
    },
    {
      field: 'approved',
      headerName: 'Approved',
      flex: 0.8,
      renderCell: (params) =>
        createCellData(
          params.row?.approvedCount,
          params?.row,
          downloadAdhocFiles,
          transactionStatus.approved
        )
    },
    {
      field: 'rejected',
      headerName: 'Rejected',
      flex: 1,
      renderCell: (params) =>
        createCellData(
          params.row?.rejectedCount,
          params?.row,
          downloadAdhocFiles,
          transactionStatus.rejected
        )
    }
  ];

  const handleFileChange = (file: File) => {
    uploadFile(file);
  };

  const [
    uploadedFileData,
    uploadFile,
    isLoading,
    fileUploadedState,
    setFileUploadedState
  ]: any = useUploadS3Files(
    getPreSignedUrlAPI,
    processFileAPI,
    PARTNER_UPLOAD_TYPE.maker
  );

  const onLoadMoreData = () => {
    fetchUploadHistoryList();
  };

  useEffect(() => {
    if (Object.keys(fileUploadedState)?.length) {
      fetchUploadHistoryAfterUploadFile();
    }
  }, [fileUploadedState]);

  return (
    <>
      <Box>
        <UploadFile
          handleFileChange={handleFileChange}
          downloadTemplateS3Link={AD_HOC_FILE_TEMPLATES.MAKER}
        />
        <Box
          sx={{
            fontWeight: 600,
            mb: 4,
            display: 'flex',
            alignItems: 'center'
          }}>
          <Typography>Upload History</Typography>
          <RefreshOutlined
            sx={{
              color: PRIMARY_COLOR,
              height: '24px',
              width: '24px',
              ml: 2,
              cursor: 'pointer'
            }}
            onClick={() => {
              fetchUploadHistoryAfterUploadFile();
            }}
          />
        </Box>
        <CommonAdHocTable
          getRowId={(row) => row.fileUploadId}
          tableWidth={'1500px'}
          tableData={uploadHistoryData || []}
          columns={columns}
          rowHeight={60}
        />
        <StyledLoadMoreBtn
          onClick={onLoadMoreData}
          startIcon={<Add />}
          disabled={uploadHistoryData.length % PAGE_SIZE !== 0}
          variant="text">
          Load More
        </StyledLoadMoreBtn>
      </Box>
      {isLoading && (
        <CustomUploadDialog handleClose={() => {}} showModal={isLoading} />
      )}
      {fileUploadedState?.showModal && (
        <CustomDialog
          handleClose={() =>
            setFileUploadedState({
              showModal: false,
              isError: false,
              message: ''
            })
          }
          dialogContent={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              {fileUploadedState.isError ? (
                <FileUploadErrorLogo />
              ) : (
                <FileUploadSuccessLogo />
              )}

              {fileUploadedState.isError && (
                <>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.8)',
                      fontSize: '16px',
                      mt: 4,
                      mb: 1
                    }}
                    component={'div'}>
                    File invalid:
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      fontSize: '14px',
                      mb: 4
                    }}
                    component={'div'}>
                    {fileUploadedState?.message}
                  </Typography>
                </>
              )}

              {!fileUploadedState?.isError && (
                <Box sx={{ mt: 4, mb: 4 }}>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.9)',
                      fontSize: '16px',
                      mb: 1,
                      textAlign: 'center'
                    }}
                    component={'div'}>
                    Upload Successful
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      fontSize: '14px'
                    }}
                    component={'div'}>
                    Checking entries... check back later.
                  </Typography>
                </Box>
              )}
              {!fileUploadedState?.isError && (
                <CustomButton
                  title="Continue"
                  variant="contained"
                  onClick={() =>
                    setFileUploadedState({
                      showModal: false,
                      isError: false,
                      message: ''
                    })
                  }
                />
              )}
            </Box>
          }
          showModal={fileUploadedState?.showModal}
        />
      )}
      {showLoader && <SimpleBackdrop open={showLoader} />}
    </>
  );
}

export default AdHocUploadTransactions;
