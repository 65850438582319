import { Box, Button, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import loginBG from 'src/resources/images/loginBG.jpeg';
import sustainLogo from 'src/resources/images/sustain-logo.svg';
import { useMsalAuth } from 'src/hooks/useMsalAuth';

const MsalLogin = () => {
  const { loginMsal, isAuthenticated } = useMsalAuth();
  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (userAuthenticated && isAuthenticated) {
      history.replace('/app');
    } else if (isAuthenticated) {
      setUserAuthenticated(true);
    }
  }, [userAuthenticated, isAuthenticated]);

  const login = async () => {
    await loginMsal();
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${loginBG})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Box
        sx={{
          background: '#fff',
          boxShadow:
            '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)',
          height: '300px',
          width: '380px',
          borderRadius: '4px',
          padding: '20px'
        }}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1]
          }}>
          <Box
            sx={{
              textAlign: 'center',
              width: '100%'
            }}>
            <img src={sustainLogo} alt="Nurture Logo" height={'35px'}></img>
          </Box>
        </Toolbar>
        <Box textAlign={'center'}>
          <Typography>nurture.farm</Typography>
        </Box>
        <Box
          textAlign={'center'}
          sx={(theme) => ({
            color: theme.palette.primary.main,
            marginBottom: '20px'
          })}>
          <Typography>Partner</Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              fontSize: '20px'
            }}>
            Welcome to
          </Typography>
          <Typography fontSize="20px">Partner Admin Portal</Typography>
        </Box>
        <Button
          sx={{ marginTop: '20px' }}
          variant="contained"
          onClick={login}
          fullWidth>
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default MsalLogin;
