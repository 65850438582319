import { DataGrid } from '@mui/x-data-grid'

interface tableProps {
    headers: any;
    tableData: any;
    getRowId?: any;
    getRowHeight?: any;
    onRowClick?: any;
    rowPadding?: any;
    getRowClassName?: any;
    checkboxSelection?: any;
    selectionModel?: any;
    handleSelectionModelChange?: any;
    isRowSelectable?:any;
    rowsPerPage?:any
}

const AdvanceTable = (props: tableProps) => {
    const {
        headers,
        tableData,
        getRowId,
        getRowHeight,
        onRowClick,
        rowPadding,
        getRowClassName,
        checkboxSelection,
        selectionModel,
        handleSelectionModelChange,
        isRowSelectable,
        rowsPerPage
    } = props;
    return (
        <DataGrid
            columns={headers.map((column: any) => ({
                ...column,
                sortable: false
            }))}
            rows={tableData.map((row, index) => ({ ...row, uniqueId: index }))}
            getRowId={getRowId ? (row) => getRowId(row) : (row) => row.uniqueId}
            autoHeight
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            isRowSelectable={isRowSelectable}
            checkboxSelection={checkboxSelection}
            getRowClassName={getRowClassName}
            onRowClick={onRowClick ? (row) => onRowClick(row) : undefined}
            disableColumnMenu
            disableSelectionOnClick
            pageSize={rowsPerPage || 10}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}

            sx={{
                '& .MuiDataGrid-root': {
                    border: 'none'
                },
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '22px'
                },
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer'
                },
                '& .MuiDataGrid-cellContent': {
                    padding: rowPadding
                },
                '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                },
                '& .MuiDataGrid-cell': {
                    wordBreak: 'break-all'
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
                    outline: 'none'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                    opacity: '0.7',
                    wordBreak: 'break-all',
                    textOverflow: 'initial',
                    lineHeight: 'normal',
                    whiteSpace: 'initial'
                },
                // '& .MuiDataGrid-footerContainer': {
                //     display: 'none'
                // },
                '& .MuiDataGrid-columnSeparator--sideRight': {
                    display: 'none'
                }
            }}
        />
    )
}

export default AdvanceTable
