export const PERMISSIONS = {
  PARTNER_PORTAL_SUPERADMIN: 'partner_portal_superadmin',
  PARTNER_PERFORMANCE: 'PARTNER_PERFORMANCE',
  PARTNER_PAYROLL_FILES: 'PARTNER_PAYROLL_FILES',
  PARTNER_EARNINGS: 'PARTNER_EARNINGS',
  CONTENT_MANAGEMENT: 'view_content_management_tab',
  VIEW_PARTNER_UPLOADS: 'view_partner_details_tab',
  VIEW_POSP: 'view_posp_tab',
  VIEW_LEDGER_RECONCILIATION: 'view-ledger-reconciliation',
  VIEW_HONDURAS_PARTNERS: 'manage_hn_partners',
  AD_HOC_TRANSACTIONS_ROLES: {
    admin: 'view_adhoc_admin',
    maker: 'view_adhoc_maker',
    checker: 'view_adhoc_checker'
  }
};

export const AUTH_STATUS = {
  pending: 'pending',
  unauthenticated: 'unauthenticated',
  authenticated: 'authenticated'
};

export interface UserDataResponseInterface {
  activated: boolean;
  active: boolean;
  authorities: Array<{
    serverId: number;
    active: boolean;
    authority: string;
  }>;
  firstName: string;
  login: string;
  oauthResponseDTO: {
    access_token: string;
    active: boolean;
    refresh_token: string;
    scope: string;
    token_type: string;
  };
  roles: Array<string>;
}
export const DEFAULT_USER_DATA: UserDataResponseInterface = {
  activated: false,
  active: false,
  authorities: [],
  firstName: '',
  login: '',
  oauthResponseDTO: {
    access_token: '',
    active: false,
    refresh_token: '',
    scope: '',
    token_type: 'bearer'
  },
  roles: []
};

export const DRAWER_WIDTH = 280;
export const NURTURE_THEME_COLOR_LIGHT = 'rgba(84,42,177,1)';
export const NURTURE_COLOR_GRADIENT =
  'linear-gradient(87.29deg, #192C4A -38.84%, #332E93 37.04%, #542AB1 114.49%)';
export const PRIMARY_BACKGROUND_COLOR =
  'linear-gradient(267.6deg, #672FB7 -12%, #4225AB 124.73%)';
export const PRIMARY_COLOR = '#4003A6';
export const SECONDARY_BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.3)';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
export const downloadFileEvent = 'download_report';
export const searchPartnerByMobileEvent = 'search_partner_by_mobile';
export const ALERT_COLOR = '#C31515';
export const SUCCESS_COLOR = '#308706';
export const WARNING_COLOR = '#C8850B';

export const SUPPORT_MAIL_ID = 'https://partner-admin.nurture.farm';
export const ACCESS_MAIL_ID = 'partner-admin-access@nurture.farm';
export const BORDER_COLOR = '#4003A6';

export const getErrorMsg = (err) => {
  if (err && err.status === 401) {
    return err?.statusText ? err.statusText : 'Session Expired';
  } else if (err.status === 400) {
    if (err?.error?.title) {
      return err?.error?.title
    } else if (err?.data?.responseMessage) {
      return err?.data?.responseMessage;
    } else {
      return 'Bad Request'
    }
  } else if (err.status === 403) {
    return err?.error?.detail ? err.error.detail : 'Access is denied.';
  } else if (err.status === 404) {
    return err?.error?.responseMessage
      ? err.error?.responseMessage
      : err.error?.message;
  } else if (err.status === 500) {
    return err?.statusText ? err.statusText : 'Something went wrong.';
  } else if (err.status === 503) {
    return err.statusText;
  } else {
    return 'Some error occured please open console or network mode to see';
  }
};

export const createRolesData = (roles) => {
  const rolesData = roles.map((role) => {
    const [vertical, name] = role.displayText.split(' - ');
    return {
      label: role.displayText,
      value: `${vertical}-${role.role}` // need vertical for payment cycle api's.
    };
  });

  return rolesData;
};

export const SPECIAL_CHARACTERS_REGEX =
  //eslint-disable-next-line
  '^[0-9a-zA-Z \b]+$';

export const convertDateIntoMilles = (value) => {
  const dateObject = new Date(value);
  const milliseconds = dateObject.getTime();
  return milliseconds;
}


export const convertEndDateIntoMilles = (value) => {
  const dateObject = new Date(value);
  dateObject.setHours(23, 59, 59, 999);
  const milliseconds = dateObject.getTime();
  return milliseconds;
}