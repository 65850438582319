import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import * as _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useAxios from 'src/hooks/useAxios';
import {
  ENDPOINTS,
  JobType,
  PartnerActivity as PartnerActivityInterface,
  PARTNER_ACTIVITY_COLUMNS
} from 'src/modules/PartnerDetails/constants';
import { getPartnerActivityRowsData } from 'src/modules/PartnerDetails/helper';

const PartnerActivity = (props: { employeeId: string | number }) => {
  const [params, setParams] = useState({
    searchString: '',
    jobType: ''
  });
  const [jobTypeValue, setJobTypeValue] = useState<any>(null);
  const emplIdRef = useRef(props.employeeId);
  const [partnerActivityData, setPartnerActivityData] = useState<
    Array<PartnerActivityInterface>
  >([]);
  const [partnerActivityRowsData, setPartnerActivityRowsData] = useState<
    Array<any>
  >([]);
  const [jobTypeData, setJobTypeData] = useState<Array<JobType>>([]);
  const [loading, setLoading] = useState(false);
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setParams((prev) => ({ ...prev, searchString: newValue }));
  };
  const handleJobTypeChange = (event: any, value: JobType | null) => {
    setJobTypeValue(value);
    if (value) {
      setParams((prev) => ({
        ...prev,
        jobType: value.entity
      }));
    } else {
      setParams((prev) => ({
        ...prev,
        jobType: ''
      }));
    }
  };
  const checkLastUpdatedPerformance = useCallback(() => {
    if (partnerActivityData.length) {
      return moment(
        moment.max(
          partnerActivityData.map((activity) => moment(activity.createdTime))
        )
      ).format('DD MMM, YYYY hh:mm:ss');
    } else {
      return moment(
        moment.max(jobTypeData.map((job) => moment(job.lastRunAt * 1000)))
      ).format('DD MMM, YYYY hh:mm:ss');
    }
  }, [partnerActivityData, jobTypeData]);
  const API = useAxios();

  useEffect(() => {
    setLoading(true);
    API(ENDPOINTS.PARTNER_JOB_TYPES)
      .then((resp) => {
        setJobTypeData(resp.data.dataList);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const validatePartnerActivityParams = ({
    searchString
  }: {
    searchString: string;
    jobType: string;
  }) => {
    if (params.searchString) {
      const regexExp = /^[0-9]\d{9}$/gi;
      if (regexExp.test(searchString)) {
        return true;
      } else return false;
    }
    return true;
  };
  useEffect(() => {
    if (emplIdRef.current !== props.employeeId) {
      setParams({
        searchString: '',
        jobType: ''
      });
      setJobTypeValue(null);
      emplIdRef.current = props.employeeId;
    } else if (validatePartnerActivityParams(params)) {
      setLoading(true);
      API.post(ENDPOINTS.PARTNER_JOB_INFORMATION, {
        employeeId: props.employeeId,
        pageNumber: 0,
        limit: 5000,
        ...(params.jobType && { jobTypeId: params.jobType }),
        searchString: params.searchString
      })
        .then((resp) => {
          setPartnerActivityData(resp.data.dataList);
        })
        .catch(() => {
          setPartnerActivityData([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params, props.employeeId]);

  useEffect(() => {
    setPartnerActivityRowsData(
      getPartnerActivityRowsData(partnerActivityData, jobTypeData)
    );
  }, [partnerActivityData]);
  const [pageSize, setPageSize] = useState(10);
  return (
    <>
      <Box display={'flex'}>
        <TextField
          label="Search"
          placeholder="Enter Mobile Number"
          size="medium"
          value={params.searchString}
          onChange={onSearchChange}
          sx={{
            width: '250px',
            '& div': {
              borderRadius: '12px'
            }
          }}
          inputProps={{
            maxLength: 10
          }}
        ></TextField>
        <Autocomplete
          sx={{
            paddingLeft: '15px',
            width: '250px',
            '& div': {
              borderRadius: '12px'
            }
          }}
          disablePortal
          id="job-type-select"
          options={jobTypeData}
          size="medium"
          getOptionLabel={(option) => {
            return option.action
              .split('_')
              .map((item) => _.capitalize(item))
              .join(' ');
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Job Name"
              placeholder="Enter Job Name"
            />
          )}
          onChange={handleJobTypeChange}
          value={jobTypeValue}
        />
        <Box margin={'auto 0'} paddingLeft={'60px'}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Performance Last updated:&nbsp;{checkLastUpdatedPerformance()}
          </Typography>
        </Box>
        <Box margin="auto 0" paddingLeft={'60px'}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Earnings Last Updated:&nbsp;{checkLastUpdatedPerformance()}
          </Typography>
        </Box>
      </Box>
      <Box height={'20px'}></Box>
      <Box display="flex" flexGrow="1">
        <DataGrid
          rows={partnerActivityRowsData}
          columns={PARTNER_ACTIVITY_COLUMNS}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
          }}
          autoHeight
          loading={loading}
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: 'completedTime', sort: 'desc' }]
            }
          }}
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none'
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              opacity: '0.7'
            }
          }}
        />
      </Box>
    </>
  );
};

export default PartnerActivity;
