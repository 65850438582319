import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

const Dashboard = () => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6">Welcome to Partner Admin Portal</Typography>
      </CardContent>
    </Card>
  );
};
export default Dashboard;
