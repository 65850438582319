import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { AUTH_STATUS, NURTURE_COLOR_GRADIENT } from 'src/constants';
import { UserAuthContext } from 'src/modules/Home';
import { DRAWER_WIDTH } from 'src/constants';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { PARTNER_SUPERADMIN_ITEMS, ROUTES } from 'src/modules/routingConstant';

const StyledDrawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    marginTop: '64px',
    paddingTop: '30px'
  }
}));

const Drawer = ({ userRoles }) => {
  const history = useHistory();
  const { authStatus } = React.useContext(UserAuthContext);
  const location = useLocation();
  const [expandedMenuItem, setExpandedMenuItem] = React.useState('');

  React.useEffect(() => {
    const item = location?.pathname?.split('/')[1];
    setExpandedMenuItem(item);
  }, []);

  const getNavItems = (initialNavItems: any = [], allNavItems) => {
    if (userRoles.indexOf('partner_portal_superadmin') >= 0) {
      return [...initialNavItems, ...allNavItems];
    }
    const assignedTabs = allNavItems.filter((item) => {
      if (item.permission) {
        return userRoles.some(
          (authority) => item.permission?.trim() == authority?.trim()
        );
      } else if (!item?.permission && !item.children) {
        return item;
      } else {
        if (item?.children?.length) {
          // check if user has access of any child item, then only show parent
          const childItems = getNavItems([], item.children);
          if (childItems?.length) {
            return item;
          }
        }
      }
    });

    return [...initialNavItems, ...assignedTabs];
  };

  const handleClick = (selectedBaseRoute) => {
    if (expandedMenuItem === selectedBaseRoute) {
      setExpandedMenuItem('');
    } else {
      setExpandedMenuItem(selectedBaseRoute);
    }
  };

  const createMenuItem = (item: any) => {
    let selected;
    selected =
      location.pathname.replace('/create', '').toLowerCase() ===
      item.route.toLowerCase();

    return (
      <StyledListIemButton
        onClick={() => {
          history.push(item.route);
        }}
        key={item.route}
        sx={{
          ...(selected && {
            background: NURTURE_COLOR_GRADIENT,
            color: '#ffffff'
          })
        }}>
        <ListItemIcon sx={{ minWidth: '32px' }}>
          <item.icon
            sx={{
              color: '#929DC1',
              ...(selected && { color: '#ffffff' })
            }}
          />
        </ListItemIcon>
        <ListItemText
          sx={{
            color: '#929DC1',
            ...(selected && { color: '#ffffff' })
          }}
          primary={item.title}
        />
      </StyledListIemButton>
    );
  };

  return (
    <StyledDrawer variant="permanent">
      {authStatus === AUTH_STATUS.authenticated && (
        <List component="nav">
          {true &&
            getNavItems(
              [
                {
                  route: ROUTES.dashboard,
                  icon: DashboardIcon,
                  title: 'Dashboard'
                }
              ],
              PARTNER_SUPERADMIN_ITEMS
            ).map((item: any) => {
              if (item.children?.length > 0) {
                return (
                  <div key={item.route}>
                    <StyledListIemButton
                      onClick={() => handleClick(item.route)}
                      key={item.route}
                      sx={{
                        margin: '6px 10px',
                        background: `${
                          location.pathname.includes(item.route)
                            ? 'rgba(0, 0, 0, 0.04)'
                            : ''
                        }`
                      }}>
                      <ListItemIcon sx={{ minWidth: '32px' }}>
                        {expandedMenuItem === item.route ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: '#929DC1'
                        }}
                        primary={item.title}
                      />
                    </StyledListIemButton>
                    <Collapse
                      in={expandedMenuItem === item.route}
                      timeout="auto">
                      {getNavItems([], item.children).map((child: any) => {
                        return (
                          <div
                            key={child.route}
                            style={{ marginLeft: `${child.depth * 20}px` }}>
                            {createMenuItem(child)}
                          </div>
                        );
                      })}
                    </Collapse>
                  </div>
                );
              }
              return createMenuItem(item);
            })}
        </List>
      )}
    </StyledDrawer>
  );
};

const StyledListIemButton = styled(ListItemButton)`
  border-radius: 10px;
  margin: 10px;
  padding: 4px 16px;
`;

export default Drawer;
