import { trace } from 'firebase/performance';
import { perf } from 'src/modules/Firebase/app';
import { AxiosInstance } from 'axios';
import { IFetchAllPartnerRolesResponse } from 'src/modules/Inbox/Interfaces';
import { PAGE_SIZE, END_POINTS } from '../constants';

const {
  roles,
  paymentCycleByRole,
  partnerPayoutCycle,
  partnerPaymentBreakdown
} = END_POINTS;

export const getRoles = async (API: AxiosInstance) => {
  const res: IFetchAllPartnerRolesResponse = await API.get(roles);
  return res;
};

export const getPaymentCycle = async (API, roles) => {
  const [vertical, role] = roles.split('-');
  const body = {
    role: role,
    vertical: vertical
  };
  const t = trace(perf, 'getPaymentCycleByRole');
  t.start();
  const res = await API.post(paymentCycleByRole, body);
  t.stop();
  return res;
};

export const getPartnerPayoutList = async (
  API,
  roles,
  paymentCycleId,
  page,
  partnerMobileNumber
) => {
  const [vertical, role] = roles.split('-');
  const body = {
    role,
    vertical,
    payoutCycleId: paymentCycleId,
    partnerMobileNumber,
    pageNumber: page,
    pageSize: PAGE_SIZE
  };
  const t = trace(perf, 'getPartnerEarnings');
  t.start();
  const res = await API.post(partnerPayoutCycle, body);
  t.stop();
  return res;
};

export const getPaymentBreakdown = async (
  API,
  roles,
  partnerIds,
  payoutCycleId
) => {
  const [vertical, role] = roles.split('-');
  const body = {
    role: role,
    vertical: vertical,
    ids: partnerIds,
    payoutCycleId: payoutCycleId
  };
  const t = trace(perf, 'getPartnerPayoutCycleBreakdown');
  t.start();
  const res = await API.post(partnerPaymentBreakdown, body);
  t.stop();
  return res;
};
