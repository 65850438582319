// @ts-nocheck
import { Redirect, Route, Router, Switch, useHistory } from 'react-router-dom';
import HomeWithRouter from 'src/modules/Home';
import MsalLogin from 'src/modules/MsalAuth';
import { ROUTES } from '../routingConstant';
import { useMsalAuth } from 'src/hooks/useMsalAuth'
import { useEffect, useState } from 'react';
import CustomSecureRoute from 'src/components/CustomSecureRoute/Index'

const AppWithMsalAuth = () => {

  const { isAuthenticated } = useMsalAuth();
  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      setUserAuthenticated(true);
    }
  }, [isAuthenticated])

  const history = useHistory();

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={ROUTES.login}
          render={() => <MsalLogin />}
        />
        <Route exact path="/">
          {userAuthenticated ? (
            <Redirect to="/app" />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <CustomSecureRoute path={ROUTES.appRoot} component={HomeWithRouter} />
        <Route path="*">
          <Redirect to={ROUTES.login} />
        </Route>
      </Switch>
    </Router>
  );
};
export default AppWithMsalAuth;
