import { useSnackbar } from 'notistack';
import useAxios from 'src/hooks/useAxios';
import { bulkUploadFile, postSAPTransactionRequest } from './api';
import * as AWS from 'aws-sdk';
import { useEffect } from 'react';

let awsService, s3Obj;

const useUploadFile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosInstance = useAxios();

  useEffect(() => {
    setPartnerAwsCredentials();
  }, []);

  const setPartnerAwsCredentials = () => {
    /**
     * AWS config initialization
     */
    awsService = AWS;
    awsService.config.accessKeyId = process.env.REACT_APP_PARTNER_AWS_ACCESS_KEY;
    awsService.config.secretAccessKey = process.env.REACT_APP_PARTNER_AWS_SECRET_KEY;
    awsService.config.region = process.env.REACT_APP_AWS_REGION;
    s3Obj = new AWS.S3({ params: { Bucket: process.env.REACT_APP_PARTNER_s3_bucket } });
  }

  const uploadXlsxfile = async (
    excelData,
    selectedUploadStatus,
    fetchUploadHistoryAfterUploadFile,
    uploadServiceType: any = undefined
  ) => {
    const fileUpdateResponse: any = await bulkUploadFile(
      axiosInstance,
      selectedUploadStatus,
      excelData,
      uploadServiceType
    );
    if (!fileUpdateResponse) {
      return;
    }
    if (fileUpdateResponse?.errorMap) {
      const mappedError = Object.keys(fileUpdateResponse.errorMap).map(
        (key) => ({
          type: key,
          value: fileUpdateResponse.errorMap[key]
        })
      );
      if (mappedError.length) {
        enqueueSnackbar('Error In Uploaded Details', {
          variant: 'error'
        });
      } else {
        enqueueSnackbar('File Uploaded Sucessfully', {
          variant: 'success'
        });
        fetchUploadHistoryAfterUploadFile();
      }
    } else {
      enqueueSnackbar('File Uploaded Sucessfully', {
        variant: 'success'
      });
      fetchUploadHistoryAfterUploadFile();
    }
  };

  const uploadFile = (
    file,
    selectedUploadStatus,
    fetchUploadHistoryAfterUploadFile,
    uploadServiceType: any = undefined
  ) => {
    let fileType = '';
    if (file && file.name) {
      fileType = file.name.substr(file.name.lastIndexOf('.') + 1);
    }
    if (file) {
      if (fileType === 'xlsx') {
        let excelData = new FormData();
        if (fileType === 'xlsx') {
          excelData.append('file', file, file.name);
          uploadXlsxfile(
            excelData,
            selectedUploadStatus,
            fetchUploadHistoryAfterUploadFile,
            uploadServiceType
          );
        }
      } else {
        enqueueSnackbar('Please upload file in xlsx format only', {
          variant: 'error'
        });
      }
    } else {
      enqueueSnackbar('Please attach xlsx format excel file size upto 10 MB', {
        variant: 'error'
      });
    }
  };

  const getUniqueName = (name: string) => {
    let _utils = {
      _extension_r: /\.[a-z]+$/i,
      _filename_r: /[^a-zA-Z0-9]/g
    };
    let extension = name.match(_utils._extension_r);
    let filename: string =
      name.replace(_utils._extension_r, '') +
      '_' +
      Math.random().toString(36).substr(2, 2);
    return filename + extension?.[0];
  };

  const uploadPayrollFile = (file: File, setShowLoader) => {
    setShowLoader(true);
    if (file) {
      let fileType = '';
      if (file && file?.name) {
        fileType = file?.name.substr(file?.name.lastIndexOf('.') + 1).toLowerCase();
      }
      if (fileType === 'csv') {
        uploadFileOnS3(file, setShowLoader);
      } else {
        enqueueSnackbar('Please upload file in CSV format only', {
          variant: 'error'
        });
        setShowLoader(false);
      }
    }
  }

  const uploadFileOnS3 = (file: File, setShowLoader) => {
    let params = {
      Bucket: process.env.REACT_APP_PARTNER_s3_bucket,
      Key: 'payroll-reports/permanent/' + file?.name,
      Body: file,
      ACL: 'public-read'
    };
    s3Obj.upload(params, async (err, data) => {
      if (err) {
        enqueueSnackbar('There is some error occurred while uploading a file', {
          variant: 'error'
        });
        setShowLoader(false);
      }
      else if (data) {
        let fileObject = {
          path: data?.Location,
          key: data?.Key,
          bucket: data?.Bucket
        };
        const mediaObject = await postSAPTransactionRequest(axiosInstance, fileObject);
        if (mediaObject) {
          enqueueSnackbar('File uploaded successfully', {
            variant: 'success'
          });
        }
        setShowLoader(false);
      }
    },);
  }

  return [uploadFile, uploadPayrollFile];
};

export default useUploadFile;
