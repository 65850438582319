import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import styled from '@emotion/styled';
import moment from 'moment';
import useAxios from 'src/hooks/useAxios';
import SaveAltOutlined from '@mui/icons-material/SaveAltOutlined';
import SimCardDownloadOutlined from '@mui/icons-material/SimCardDownloadOutlined';
import { useSnackbar } from 'notistack';
import {
  PRIMARY_COLOR,
  PRIMARY_BACKGROUND_COLOR,
  SECONDARY_BACKGROUND_COLOR
} from 'src/constants';
import UploadFile from '../common/fileUpload';
import CommonAdHocTable from '../adHocTable/table';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomDialog from 'src/components/customDialog';
import useUploadS3Files from 'src/hooks/useUploadS3Files';
import {
  getFileTransactionsAPI,
  getPreSignedUrlAPI,
  getTransactionUploadHistoryAPI,
  processFileAPI
} from '../apiActions';
import {
  AD_HOC_FILE_TEMPLATES,
  FILE_ACTION,
  PARTNER_UPLOAD_TYPE,
  transactionStatus,
  CHECKER_TRANSACTIONS_STATUS
} from '../constants';
import CustomButton from 'src/components/CustomButton';
import { ReactComponent as FileUploadSuccessLogo } from 'src/resources/images/file-upload-success.svg';
import CustomUploadDialog from '../uploadDialog';
import { downloadReport } from 'src/modules/PaymentCalculations/VendorPayment/helper';
import SimpleBackdrop from 'src/components/Backdrop';
import { ReactComponent as FileUploadErrorLogo } from 'src/resources/images/file-upload-error.svg';
import {
  adhocTransactionsExportToExcel,
  PAGE_SIZE
} from 'src/modules/PartnerUpload/constant';
import { Chip, Tooltip } from '@mui/material';
import { RefreshOutlined } from '@mui/icons-material';

function AdHocCheckTransactions() {
  const API = useAxios();
  const [currentPage, setCurrentPage] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);

  const [uploadHistoryData, setUploadHistoryData] = useState<any>([]);
  const axiosInstance = useAxios();
  const { enqueueSnackbar } = useSnackbar();

  const fetchUploadHistoryAfterUploadFile = async () => {
    try {
      setShowLoader(true);
      const body = {
        pageNumber: 0,
        limit: PAGE_SIZE,
        partnerUploadType: PARTNER_UPLOAD_TYPE.checker
      };
      const response = await getTransactionUploadHistoryAPI(
        axiosInstance,
        body
      );
      if (response?.data?.dataList?.length) {
        setUploadHistoryData(response?.data?.dataList);
        setCurrentPage(1);
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchUploadHistoryList = async (pageNumber = currentPage) => {
    try {
      setShowLoader(true);
      const body = {
        pageNumber,
        limit: PAGE_SIZE,
        partnerUploadType: PARTNER_UPLOAD_TYPE.checker
      };
      const response = await getTransactionUploadHistoryAPI(
        axiosInstance,
        body
      );
      if (response?.data?.dataList?.length) {
        setUploadHistoryData([
          ...uploadHistoryData,
          ...response?.data?.dataList
        ]);
        setCurrentPage((page) => page + 1);
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchUploadHistoryList();
  }, []);

  const handleDownload = async (row) => {
    try {
      setShowLoader(true);
      const body = {
        fileAction: FILE_ACTION.download,
        fileName: row?.fileName || '',
        fileExtension: 'xlsx',
        partnerUploadType: PARTNER_UPLOAD_TYPE.checker,
        uploadId: row?.fileUploadId || ''
      };
      const response = await getPreSignedUrlAPI(API, body);
      if (response?.data?.data?.preSignedUrl) {
        downloadReport(response?.data?.data?.preSignedUrl);
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  const downloadAdhocFiles = async (row, transactionStatus: any = null) => {
    try {
      setShowLoader(true);
      const body = {
        fileUploadId: row?.fileUploadId || '',
        transactionStatus,
        partnerUploadType: PARTNER_UPLOAD_TYPE.checker
      };
      const fileData = await getFileTransactionsAPI(API, body);
      if (fileData?.data?.transactionRequest?.length) {
        const isError = adhocTransactionsExportToExcel(
          fileData?.data?.transactionRequest,
          row?.fileName,
          'checker'
        );
        if (isError) {
          enqueueSnackbar('Something went wrong. Please try again later', {
            variant: 'error'
          });
        }
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  const createFileNameCellData = (rowData) => {
    return (
      <Tooltip
        title={rowData?.fileName}
        TransitionProps={{ timeout: 800 }}
        placement="right-start"
        arrow>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SimCardDownloadOutlined
              sx={{
                color: 'rgba(0, 0, 0, 0.7)',
                marginRight: '2px'
              }}
            />

            <Typography
              onClick={() => {
                let transactionStatus =
                  rowData.uploadStatus === 'PARTNER_UPLOAD_PENDING'
                    ? 'SUBMITTED'
                    : null;
                downloadAdhocFiles(rowData, transactionStatus);
              }}
              sx={{ color: PRIMARY_COLOR, cursor: 'pointer', fontSize: '14px' }}
              noWrap>
              {rowData?.fileName}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: 'rgba(0, 0, 0, 0.5)',
              fontSize: '14px',
              ml: 1,
              mt: 0.6
            }}
            component="div">
            {rowData?.totalRows} rows{' '}
            {moment(rowData?.createdOn).format('MMM DD YYYY hh:mm a')}
          </Typography>
        </Box>
      </Tooltip>
    );
  };

  const createCellData = (
    value,
    rowData,
    callback,
    transactionStatus?: any
  ) => {
    return (
      <strong>
        <Button
          color="primary"
          sx={{
            borderRadius: '10px',
            textTransform: 'capitalize',
            color: value === 0 ? SECONDARY_BACKGROUND_COLOR : PRIMARY_COLOR
          }}
          endIcon={<SaveAltOutlined />}
          size="small"
          onClick={() => callback(rowData, transactionStatus)}>
          {value} rows
        </Button>
      </strong>
    );
  };

  const createStatus = (uploadStatus) => {
    if (uploadStatus === 'PARTNER_UPLOAD_PENDING') {
      return (
        <Button
          variant="contained"
          sx={{
            borderRadius: '10px',
            textTransform: 'capitalize',
            background: PRIMARY_BACKGROUND_COLOR
          }}
          size="small"
          onClick={() => setShowUploadFileModal(true)}>
          {CHECKER_TRANSACTIONS_STATUS[uploadStatus]?.label}
        </Button>
      );
    } else {
      return (
        <Chip
          sx={{
            color: CHECKER_TRANSACTIONS_STATUS[uploadStatus]?.color,
            background:
              CHECKER_TRANSACTIONS_STATUS[uploadStatus]?.backgroundColor,
            width: '72%'
          }}
          label={CHECKER_TRANSACTIONS_STATUS[uploadStatus]?.label}
        />
      );
    }
  };

  const columns = [
    {
      field: 'fileUrl',
      headerName: 'File',
      flex: 2.6,
      renderCell: (params) => createFileNameCellData(params?.row)
    },
    {
      field: 'submittdBy',
      headerName: 'Submitted By',
      flex: 1.2,
      renderCell: (params) => (
        <Typography sx={{ fontSize: '14px' }} noWrap>
          {params?.row?.createdByActorName}
        </Typography>
      )
    },
    {
      field: 'totalCredit',
      headerName: 'Total Credit',
      flex: 1.2,
      renderCell: (params) => {
        return (
          <>
            Rs {params?.row?.totalCreditAmount} /{' '}
            {params?.row?.creditPartnerCount} partners
          </>
        );
      }
    },
    {
      field: 'totalDebit',
      headerName: 'Total Debit',
      flex: 1.2,
      renderCell: (params) => {
        return (
          <>
            Rs {params?.row?.totalDebitAmount} /{' '}
            {params?.row?.debitPartnerCount} partners
          </>
        );
      }
    },
    {
      field: 'uploadStatus',
      headerName: 'Status',
      flex: 1.5,
      renderCell: (params) => createStatus(params.row?.uploadStatus)
    },
    {
      field: 'validationErrors',
      headerName: 'Validation Errors',
      flex: 1,
      renderCell: (params) =>
        createCellData(
          params.row?.validationErrorCount,
          params?.row,
          downloadAdhocFiles,
          transactionStatus.invalid
        )
    },
    {
      field: 'approved',
      headerName: 'Approved',
      flex: 0.8,
      renderCell: (params) =>
        createCellData(
          params.row?.approvedCount,
          params?.row,
          downloadAdhocFiles,
          transactionStatus.approved
        )
    },
    {
      field: 'rejected',
      headerName: 'Rejected',
      flex: 0.8,
      renderCell: (params) =>
        createCellData(
          params.row?.rejectedCount,
          params?.row,
          downloadAdhocFiles,
          transactionStatus.rejected
        )
    }
  ];

  const handleFileChange = (file) => {
    uploadFile(file);
    setShowUploadFileModal(false);
  };

  const onLoadMoreData = () => {
    fetchUploadHistoryList();
  };

  const [
    uploadedFileData,
    uploadFile,
    isLoading,
    fileUploadedState,
    setFileUploadedState
  ]: any = useUploadS3Files(
    getPreSignedUrlAPI,
    processFileAPI,
    PARTNER_UPLOAD_TYPE.checker
  );
  useEffect(() => {
    if (Object.keys(fileUploadedState)?.length) {
      fetchUploadHistoryAfterUploadFile();
    }
  }, [fileUploadedState]);

  return (
    <>
      {showUploadFileModal && (
        <CustomDialog
          handleClose={() => setShowUploadFileModal(false)}
          dialogContent={
            <UploadFile
              handleFileChange={handleFileChange}
              marginBottom="0px"
              width="100%"
              downloadTemplateS3Link={AD_HOC_FILE_TEMPLATES.CHECKER}
            />
          }
          showModal={showUploadFileModal}
        />
      )}
      <Box
        sx={{
          fontWeight: 600,
          mb: 4,
          display: 'flex',
          alignItems: 'center'
        }}>
        <Typography>Check</Typography>
        <RefreshOutlined
          sx={{
            color: PRIMARY_COLOR,
            height: '24px',
            width: '24px',
            ml: 2,
            cursor: 'pointer'
          }}
          onClick={() => {
            fetchUploadHistoryAfterUploadFile();
          }}
        />
      </Box>
      <CommonAdHocTable
        getRowId={(row) => row.fileUploadId}
        tableData={uploadHistoryData || []}
        columns={columns}
        rowHeight={72}
        tableWidth={'1500px'}
      />
      <StyledLoadMoreBtn
        onClick={onLoadMoreData}
        startIcon={<Add />}
        disabled={uploadHistoryData.length % PAGE_SIZE !== 0}
        variant="text">
        Load More
      </StyledLoadMoreBtn>
      {isLoading && (
        <CustomUploadDialog handleClose={() => {}} showModal={isLoading} />
      )}
      {fileUploadedState?.showModal && (
        <CustomDialog
          handleClose={() =>
            setFileUploadedState({
              showModal: false,
              isError: false,
              message: ''
            })
          }
          dialogContent={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              {fileUploadedState.isError ? (
                <FileUploadErrorLogo />
              ) : (
                <FileUploadSuccessLogo />
              )}
              {fileUploadedState.isError && (
                <>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.8)',
                      fontSize: '16px',
                      mt: 4,
                      mb: 1
                    }}
                    component={'div'}>
                    File invalid:
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      fontSize: '14px',
                      mb: 4
                    }}
                    component={'div'}>
                    {fileUploadedState?.message}
                  </Typography>
                </>
              )}
              {!fileUploadedState?.isError && (
                <Box sx={{ mt: 4, mb: 4 }}>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.9)',
                      fontSize: '16px',
                      mb: 1,
                      textAlign: 'center'
                    }}
                    component={'div'}>
                    Upload Successful
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      fontSize: '14px'
                    }}
                    component={'div'}>
                    Checking entries... check back later.
                  </Typography>
                </Box>
              )}
              {!fileUploadedState?.isError && (
                <CustomButton
                  title="Continue"
                  variant="contained"
                  onClick={() =>
                    setFileUploadedState({
                      showModal: false,
                      isError: false,
                      message: ''
                    })
                  }
                />
              )}
            </Box>
          }
          showModal={fileUploadedState?.showModal}
        />
      )}
      {showLoader && <SimpleBackdrop open={showLoader} />}
    </>
  );
}

const StyledLoadMoreBtn = styled(Button)`
  position: relative;
  left: 50%;
  transform: translateX(-70%);
  margin-bottom: 60px;
  text-transform: capitalize;
  color: #4003a6;
  margin-top: 20px;
`;

export { AdHocCheckTransactions, StyledLoadMoreBtn };
