export const announcementColumns = [
  { field: 'created', headerName: 'Created Date', flex: 1 },
  { field: 'createdBy', headerName: 'Created By', flex: 1 },
  { field: 'title', headerName: 'Title', flex: 1 },
  { field: 'description', headerName: 'Description', flex: 1 },
  { field: 'startingDate', headerName: 'Starting Date', flex: 1 },
  { field: 'endingDate', headerName: 'Ending Date', flex: 1 },
  { field: 'likeCount', headerName: 'Like Count', flex: 1 },
  { field: 'sharedCount', headerName: 'Shared Count', flex: 1 },
  { field: 'commentCount', headerName: 'Comment Count', flex: 1 }
];

export const getCurrentDate = (descValue) => {
  let todayDate = new Date();
  let month = String(todayDate.getMonth() + 1);
  let year = todayDate.getUTCFullYear() - 0;
  let tdate = String(todayDate.getDate());
  if (Number(month) < 10) {
    month = '0' + month;
  }
  if (Number(tdate) < 10) {
    tdate = '0' + tdate;
  }
  return year - descValue + '-' + month + '-' + tdate;
};

export const announcementTypesOptions = [
  {
    label: 'Text',
    value: 'TEXT'
  },
  {
    label: 'Image',
    value: 'IMAGE'
  },
  {
    label: 'Video',
    value: 'VIDEO'
  }
];

export const textPrimaryColor = '#4d5a77';

export const ANNOUNCEMENT_TYPES = {
  image: 'IMAGE',
  video: 'VIDEO',
  text: 'TEXT'
};

const BASE_URI = '/afs/api';

export const END_POINTS = {
  allAnnouncenmentsRoute: `${BASE_URI}/top-announcements-paginated`,
  uploadMediaRoute: `${BASE_URI}/create-update-media`,
  geographyData: `${BASE_URI}/geographies/getByGeoTypeId/2`
};

export const PAGE_SIZE = 20;

export const LINK_REGEX =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/; //eslint-disable-line

export const NOTICE_TYPES = {
  announcement: 'ANNOUNCEMENT',
  tutorial: 'TUTORIAL'
};

export const MEDIA_SOURCE = {
  s3: 'S3',
  youtube: 'YOUTUBE'
};

export const BUCKET_PATH = 'top_announcement_media/';

export const APP_NAME = 'CLIENT_PARTNER_APP';
