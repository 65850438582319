import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { initialValues, validationSchema } from "../constants"
import { useFormik } from "formik";
import CustomTextField from "src/components/CustomTextField"
import CommonDatePicker from "src/components/CustomDatePicker";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { PRIMARY_COLOR, convertDateIntoMilles } from "src/constants";
import ClearIcon from '@mui/icons-material/Clear';

const CommonFilterForReconciliation = ({ setFormValues, onSearch, openedTab }) => {

    const {
        values,
        errors,
        handleBlur,
        touched,
        setFieldValue,
        handleSubmit
    } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (value) => {
            setFormValues(value)
            onSearch()
        }
    })

    const handleMobileNumberChange = (value) => {
        let regex = /^[0-9]+$/;
        let charArr = value.split('');
        if (charArr.length && !charArr[charArr.length - 1].match(regex)) {
            return;
        }
        setFieldValue('operatorMobileNo', value);
    }


    const onChangeStartDate = (newValue) => {
        if (values.endDate && convertDateIntoMilles(newValue)) {
            const startDate = convertDateIntoMilles(newValue);
            const endDate = convertDateIntoMilles(values.endDate)
            if (startDate > endDate) {
                setFieldValue('endDate', null);
            }
        }
        setFieldValue('startDate', newValue);
    }

    const onChangeEndDate = (newValue) => {
        if (values.startDate && convertDateIntoMilles(newValue)) {
            const startDate = convertDateIntoMilles(values.startDate);
            const endDate = convertDateIntoMilles(newValue);
            if (endDate < startDate) {
                setFieldValue('startDate', null);
            }
        }
        setFieldValue('endDate', newValue);
    }

    const getCurrentDate = () => {
        return dayjs();
    }


    const getPastDisableDate = (selectedDate) => {
        const disablePastDate = (date) => selectedDate.diff(date, 'days') > 29;
        return disablePastDate;
    }

    const getEndDateMaxDate = (endDate, startDate) => {
        if (endDate && (endDate.format('DD/MM/YYYY') == getCurrentDate().format('DD/MM/YYYY'))) {
            return endDate
        } else {
            const disableFutureDates = dayjs(startDate).add(29, 'day');
            return disableFutureDates;
        }
    }

    const onClearDates = () => {
        setFieldValue('endDate', null);
        setFieldValue('startDate', null);
    }

    return (
        <Box>
            <Box style={{ backgroundColor: '#00000008', paddingBottom: '25px' }}>
                <form noValidate onSubmit={handleSubmit}>
                    <Grid container style={{ "display": "flex" }}>
                        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: '0 10px', marginTop: '25px !important' }}>
                            <Box display='flex'>
                                <StyledFilerLabels>
                                    Operator No.
                                </StyledFilerLabels>
                                <Box component="span">
                                    <CustomTextField
                                        label={'Operator Mobile No.'}
                                        name="operatorMobileNo"
                                        placeholder='Enter Mobile No.'
                                        required
                                        helperText={touched.operatorMobileNo ? errors.operatorMobileNo : ''}
                                        error={touched.operatorMobileNo && Boolean(errors.operatorMobileNo)}
                                        type="text"
                                        value={values.operatorMobileNo}
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleMobileNumberChange(e.target.value)}
                                        inputProps={{
                                            minLength: 10,
                                            maxLength: 10
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: '0 10px', marginTop: '25px !important' }}>
                            <Box display='flex'>
                                <StyledFilerLabels>
                                    {openedTab == 'booking' && <Typography>Booking Completion Date :</Typography>}
                                    {openedTab == 'expense' && <Typography>Expense Verification Date :</Typography>}
                                    {openedTab == 'collectionSettlements' && <Typography>Payment date :</Typography>}
                                </StyledFilerLabels>
                                <Box component="span">
                                    <CommonDatePicker
                                        label={'From'}
                                        name="startDate"
                                        value={values.startDate}
                                        onChange={(newValue) => onChangeStartDate(newValue)}
                                        onBlur={handleBlur}
                                        placeholder={'Select Start Date'}
                                        required={true}
                                        helperText={touched.startDate ? errors.startDate : ''}
                                        error={touched.startDate && Boolean(errors.startDate)}
                                        disabled={false}
                                        disableFutureDates={true}
                                        shouldDisableDate={values.endDate ? getPastDisableDate(values.endDate) : undefined}
                                        maxDate={values.endDate ? values.endDate : undefined}
                                    />
                                </Box>
                                <Box component="span" style={{ marginLeft: '5px' }}>
                                    <CommonDatePicker
                                        label={'To'}
                                        name="endDate"
                                        value={values.endDate}
                                        onChange={(newValue) => onChangeEndDate(newValue)}
                                        onBlur={handleBlur}
                                        placeholder={'Select End Date'}
                                        required={true}
                                        helperText={touched.endDate ? errors.endDate : ''}
                                        error={touched.endDate && Boolean(errors.endDate)}
                                        disabled={false}
                                        disableFutureDates={true}
                                        minDate={values.startDate ? values.startDate : undefined}
                                        maxDate={values.startDate ? getEndDateMaxDate(values.endDate, values.startDate) : undefined}
                                    />
                                </Box>
                                <Box component="span">
                                    <Tooltip title={'Reset Dates'}>
                                        <IconButton
                                            onClick={onClearDates}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>

                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2} sx={{ padding: '0 10px', marginTop: '25px !important' }}>
                            <Button
                                color="primary"
                                size="medium"
                                type="submit"
                                endIcon={<KeyboardArrowRightIcon />}
                                sx={{
                                    border: `2px solid ${PRIMARY_COLOR}`,
                                    borderRadius: "8px",
                                    marginLeft: "10px",
                                    textTransform: 'capitalize',
                                    color: PRIMARY_COLOR
                                }}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

const StyledFilerLabels = styled(Box)`
    margin-right: 5px;
    white-space: nowrap;
    margin-top: 8px;
`;

export default CommonFilterForReconciliation