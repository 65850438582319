import { useState, useEffect } from 'react';
import useAxios from 'src/hooks/useAxios';
import { PAGE_SIZE, RolesInterface } from '../constants';
import { getPartnerPayoutCycleHistory, getRoles } from './actions';
import PaymentCalculationsHeader from './Header';
import VendorPaymentCalculationList from './List';
import SimpleBackdrop from 'src/components/Backdrop';

function VendorPaymentCalculation() {
  const [roles, setRoles] = useState<RolesInterface | []>([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [tableData, setTableData] = useState<any>([]);
  const [showTable, setShowTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [availablePages, setAvailablePages] = useState<any>([]);

  const API = useAxios();

  const fetchRolesData = async () => {
    setIsLoading(true);
    try {
      const res = await getRoles(API);
      if (res && res.data && res.data.dataList) {
        setRoles(res.data.dataList);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPaymentData = async (
    role,
    changedPage = 1,
    pagesList = availablePages
  ) => {
    setShowTable(true);
    try {
      const response = await getPartnerPayoutCycleHistory(
        API,
        role,
        changedPage - 1
      );
      if (response && response.data && response.data.dataList) {
        if (response.data.dataList.length) {
          if (
            response.data.dataList.length >= PAGE_SIZE &&
            pagesList.indexOf(changedPage) === -1
          ) {
            setpageCount((pageCount) => pageCount + 1);
            setAvailablePages([...pagesList, changedPage]);
          }
        }
        setTableData(response.data.dataList);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRoleChange = async (role) => {
    setIsLoading(true);
    setSelectedRole(role);
    setpageCount(1);
    setAvailablePages([]);
    fetchPaymentData(role, 1, []);
  };

  useEffect(() => {
    fetchRolesData();
  }, []);

  return (
    <>
      <PaymentCalculationsHeader
        selectedRole={selectedRole}
        roles={roles}
        handleRoleChange={handleRoleChange}
      />
      {showTable && (
        <VendorPaymentCalculationList
          tableData={tableData}
          selectedRole={selectedRole}
          fetchPaymentData={fetchPaymentData}
          pageCount={pageCount}
        />
      )}
      {<SimpleBackdrop open={isLoading} />}
    </>
  );
}

export default VendorPaymentCalculation;
