import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';

const CustomDialog = ({ showModal, handleClose }) => {
  return (
    <Dialog open={showModal} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '100px 0',
          flexDirection: 'column'
        }}>
        <>
          <Box sx={{ position: 'relative' }}>
            <CircularProgress
              variant="determinate"
              sx={{
                color: (theme) =>
                  theme.palette.grey[theme.palette.mode === 'light' ? 600 : 800]
              }}
              size={50}
              thickness={5}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'light'
                    ? 'hsl(0deg 0% 77% / 87%)'
                    : '#308fe8',
                animationDuration: '800ms',
                position: 'absolute',
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round'
                }
              }}
              size={50}
              thickness={5}
            />
          </Box>
          <Typography
            sx={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '14px', mt: 4 }}
            component={'div'}>
            Uploading...
          </Typography>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
