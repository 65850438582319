import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PRIMARY_COLOR } from 'src/constants';

const StyledPagination = styled(Pagination)(() => ({
  ' & ul > li:not(:first-of-type):not(:last-child) > button.Mui-selected': {
    border: `1px solid ${PRIMARY_COLOR}`,
    color: 'black',
    backgroundColor: '#d7c2f4'
  }
}));

export default function CustomPagination(props) {
  const { page, setPage, pageCount, justifyContent = 'flex-end' } = props;
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box display={'flex'} paddingRight="25px" justifyContent={justifyContent}>
      <StyledPagination
        count={pageCount}
        variant="outlined"
        shape="rounded"
        page={page}
        onChange={handleChange}
        color="primary"
        sx={{
          position: 'relative',
          right: '0',
          paddingTop: '20px'
        }}
      />
    </Box>
  );
}
