import styled from '@emotion/styled';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import * as Yup from 'yup';

export const initialValues = {
    operatorMobileNo: '',
    startDate: null,
    endDate: null,
};

export const getCurrentDate = () => {
    return dayjs();
}

export const validationSchema = Yup.object().shape({
    operatorMobileNo: Yup.string().required('Operator number is required')
        .min(10, 'Mobile number must be of 10 digit')
        .max(10, 'Mobile number must be of 10 digit'),
    startDate: Yup.object().nullable().required('Start Date is required'),
    endDate: Yup.object().nullable().required('End date is required'),
});

export const yesNoFilterScheme = [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' }
];

export const fuelExpenseScheme = [
    { label: 'Fuel', value: 'FUEL' },
    { label: 'Maintenance', value: 'MAINTENANCE' }
]

export const yesNoWithNullFilterScheme = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'NO' },
    { label: 'Null', value: 'null' },
    { label: 'Review Window Open', value: 'Review Window Open' }
]

export const ReconciliationTypeList = {
    completeBooking: 'COMPLETED_BOOKING',
    bookingReview: 'BOOKING_REVIEW',
    serviceCashPayments: 'SERVICE_CASH_PAYMENTS',
    fuelExpenseBill: 'FUEL_EXPENSE_BILL',
    maintenanceBill: 'MAINTENANCE_BILL',
    paymentSettlements: 'PAYMENT_SETTLEMENTS'
}

export const StyledRowSelectedBox = styled(Box)`
    display: flex;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.03);
    margin-left: 20px;
    margin-top: 15px;
`;


export const groupArray = (array) => {
    const groupedArray = array.reduce((acc, item) => {
        const { reconciliationType, id } = item;

        // Check if reconciliationType is already in the accumulator
        const existingGroup = acc.find(group => group.reconciliationType === reconciliationType);

        if (existingGroup) {
            existingGroup.entityIds.push(id);
        } else {
            acc.push({
                reconciliationType,
                entityIds: [id]
            });
        }

        return acc;
    }, []);

    return groupedArray;
}


export const getCollectionFilteredData = (rowsUnHandled, expenseHandled, bookingData) => {

    let filteredData = rowsUnHandled ? bookingData.filter(item => item.handled !== 'true') : bookingData;
    if (expenseHandled && expenseHandled.length) {
        filteredData = filteredData.filter(item => {
            if (expenseHandled.includes('YES') && expenseHandled.includes('NO')) {
                // If 'Yes' and 'No' are both included, include all items
                return item.handled === 'true' || item.handled === 'false';
            } else if (expenseHandled.includes('YES')) {
                // Include items where handled is 'true'
                return item.handled === 'true';
            } else if (expenseHandled.includes('NO')) {
                // Include items where handled is 'false'
                return item.handled === 'false';
            }
            return false;
        });
    }

    return filteredData
}

export const getExpenseFilteredData = (rowsUnHandled, expenseHandled, expenseCategory, bookingData) => {

    let filteredData = rowsUnHandled ? bookingData.filter(item => item.handled !== 'true') : bookingData;
    if (expenseHandled && expenseHandled.length) {
        filteredData = filteredData.filter(item => {
            if (expenseHandled.includes('YES') && expenseHandled.includes('NO')) {
                // If 'Yes' and 'No' are both included, include all items
                return item.handled === 'true' || item.handled === 'false';
            } else if (expenseHandled.includes('YES')) {
                // Include items where handled is 'true'
                return item.handled === 'true';
            } else if (expenseHandled.includes('NO')) {
                // Include items where handled is 'false'
                return item.handled === 'false';
            }
            return false;
        });
    }

    if (expenseCategory && expenseCategory.length) {
        filteredData = filteredData.filter(item => {
            if (expenseCategory.includes('FUEL') && expenseCategory.includes('MAINTENANCE')) {
                // If 'FUEL' and 'MAINTENANCE' are both included, include all items
                return item.expenseCategory === 'FUEL' || item.expenseCategory === 'MAINTENANCE';
            } else if (expenseCategory.includes('FUEL')) {
                // Include items where handled is 'FUEL'
                return item.expenseCategory === 'FUEL';
            } else if (expenseCategory.includes('MAINTENANCE')) {
                // Include items where handled is 'MAINTENANCE'
                return item.expenseCategory === 'MAINTENANCE';
            }
            return false;
        });
    }

    return filteredData
}


export const getBookingFilteredData = (rowsUnHandled, reviewHandled, fuelExpenseHandled, cashCollectionHandled, order, bookingData) => {

    let filteredData = bookingData.filter(item => {
        if (rowsUnHandled) {
            return checkBookingCanSync(
                item.cashCollectionExpenseHandled,
                item.fuelExpenseHandled,
                item.reviewHandled
            )
            // item.reviewHandled !== "Yes" ||
            //     item.fuelExpenseHandled !== "true" ||
            //     item.cashCollectionExpenseHandled !== "true";
        } else {
            // If rowsUnHandled is false then include all data
            return true;
        }
    });

    if (reviewHandled && reviewHandled.length) {
        filteredData = filteredData.filter(item => reviewHandled.includes(item.reviewHandled));
    }

    if (fuelExpenseHandled && fuelExpenseHandled.length) {
        filteredData = filteredData.filter(item => {
            if (fuelExpenseHandled.includes('YES') && fuelExpenseHandled.includes('NO')) {
                // If 'Yes' and 'No' are both included, include all items
                return item.fuelExpenseHandled === 'true' || item.fuelExpenseHandled === 'false';
            } else if (fuelExpenseHandled.includes('YES')) {
                // Include items where handled is 'true'
                return item.fuelExpenseHandled === 'true';
            } else if (fuelExpenseHandled.includes('NO')) {
                // Include items where handled is 'false'
                return item.fuelExpenseHandled === 'false';
            }
            return false;
        });
    }

    if (cashCollectionHandled && cashCollectionHandled.length) {
        filteredData = filteredData.filter(item => {
            if (cashCollectionHandled.includes('YES') && cashCollectionHandled.includes('NO')) {
                // If 'Yes' and 'No' are both included, include all items
                return item.cashCollectionExpenseHandled === 'true' || item.cashCollectionExpenseHandled === 'false';
            } else if (cashCollectionHandled.includes('YES')) {
                // Include items where handled is 'true'
                return item.cashCollectionExpenseHandled === 'true';
            } else if (cashCollectionHandled.includes('NO')) {
                // Include items where handled is 'false'
                return item.cashCollectionExpenseHandled === 'false';
            }
            return false;
        });
    };

    if (order) {
        filteredData = filteredData.sort((a, b) => {
            const dateA = parseInt(a.completionDate);
            const dateB = parseInt(b.completionDate);

            if (order === 'asc') {
                return dateA - dateB;
            } else if (order === 'desc') {
                return dateB - dateA;
            } else {
                // Handle invalid order value
                console.error('Invalid order value');
                return 0;
            }
        });
    }

    return filteredData
}

export const checkBookingCanSync = (cashCollection, fuelExpense, reviewHandled) => {
    if (cashCollection == 'true' &&
        fuelExpense == 'true' &&
        (reviewHandled == 'Yes' || reviewHandled == 'Review Window Open')
    ) {
        return false;
    }
    else {
        return true;
    }
}

export const getBookingFormattedPayloadData = (selectedModalList) => {
    const reconciliationTypeMapping = {
        "COMPLETED_BOOKING": "BOOKING_REVIEW",
        "BOOKING_REVIEW": "SERVICE_CASH_PAYMENTS",
        "SERVICE_CASH_PAYMENTS": "SERVICE_CASH_PAYMENT_UPDATE",
        "SERVICE_CASH_PAYMENT_UPDATE": "COMPLETED_BOOKING"
    };

    // Iterate over selectedModalList and create the new payload
    const resultPayload = Object.keys(reconciliationTypeMapping).map(newType => {
        return {
            reconciliationType: newType,
            entityIds: selectedModalList.map(item => item.id)
        };
    });

    return resultPayload;
}

