import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';

const CommonDatePicker = ({
    label,
    name,
    value,
    onChange,
    placeholder,
    required,
    helperText,
    disableFutureDates,
    shouldDisableDate,
    error,
    onBlur,
    disabled,
    maxDate,
    minDate,
}: {
    label: string,
    name : string,
    value :any,
    onChange: any,
    placeholder : string,
    required : boolean,
    helperText: any,
    disableFutureDates?: any,
    shouldDisableDate?: any,
    error : any,
    onBlur: any,
    disabled? : boolean,
    maxDate?: any,
    minDate?: any,
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
                label={label}
                value={value}
                onChange={onChange}
                disableFuture={disableFutureDates}
                shouldDisableDate={shouldDisableDate}
                format='DD/MM/YYYY'
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                slotProps={{
                    textField: {
                        name: name,
                        size: 'small',
                        fullWidth: true,
                        placeholder: placeholder,
                        required: required,
                        helperText: helperText,
                        error: error,
                        onBlur: onBlur
                        // disabled: true, // Disable manual input via keyboard
                    },
                }}
            />
        </LocalizationProvider>
    )
}


export default CommonDatePicker
