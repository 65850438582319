import { Box, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid } from '@mui/x-data-grid';
import { logEvent } from 'firebase/analytics';
import { trace } from 'firebase/performance';
import React, { useState } from 'react';
import SimpleBackdrop from 'src/components/Backdrop';
import { searchPartnerByMobileEvent } from 'src/constants';
import useAxios from 'src/hooks/useAxios';
import {
  ENDPOINTS,
  PartnerDetailsInterface,
  PARTNER_ROLES_COLUMNS
} from 'src/modules/PartnerDetails/constants';
import { getPartnerDetailsRowsData } from 'src/modules/PartnerDetails/helper';
import PartnerActivity from 'src/modules/PartnerDetails/PartnerActivity';
import PartnerDetailsSubHeader from 'src/modules/PartnerDetails/PartnerDetailsSubHeader';
import SearchBox from 'src/modules/PartnerDetails/SearchBox';
import { analytics, perf } from '../Firebase/app';

const GridLabelValuePair = (props: {
  label: string;
  value: string | number;
}) => {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={4}>
        <Typography
          sx={{
            fontSize: '14px',
            opacity: '0.7'
          }}>
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography
          sx={{
            fontSize: '14px'
          }}>
          {props.value || 'N.A'}
        </Typography>
      </Grid>
    </Grid>
  );
};

const PartnerDetails = () => {
  const [loading, setLoading] = useState(false);
  const [partnerDetails, setPartnerDetails] =
    useState<PartnerDetailsInterface | null>(null);
  const [noData, setNoData] = useState(false);

  const [partnerRolesRowsData, setPartnerRolesRowsData] = useState<Array<any>>(
    []
  );

  const handlePartnerDataSuccess = (data: PartnerDetailsInterface) => {
    setNoData(false);
    setPartnerDetails(data);
    setPartnerRolesRowsData(getPartnerDetailsRowsData(data));
  };
  const API = useAxios();

  const getPartnerDetails = async (mobileNumber: string) => {
    try {
      setLoading(true);
      const t = trace(perf, 'searchPartnerByMobile');
      t.start();
      const { data } = await API.post(ENDPOINTS.PARTNER_DETAILS, mobileNumber);
      t.stop();
      handlePartnerDataSuccess(data.data);
    } catch (err) {
      setNoData(true);
    } finally {
      setLoading(false);
    }
  };

  const handleValidMobileNumberInput = (newValue: string) => {
    logEvent(analytics, searchPartnerByMobileEvent, {
      name: 'Search Partner By Mobile'
    });
    getPartnerDetails(newValue);
  };
  return (
    <Box>
      <SearchBox
        onValidInput={handleValidMobileNumberInput}
        isLoading={loading}
        noDataPresent={noData}
      />
      {partnerDetails && (
        <Box marginTop="60px">
          <Card raised sx={{ borderRadius: '10px', padding: '10px' }}>
            <CardContent>
              <PartnerDetailsSubHeader>
                <Typography>Personal Information</Typography>
              </PartnerDetailsSubHeader>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                marginTop="20px"
                padding="5px">
                <Grid item xs={5}>
                  <GridLabelValuePair
                    label="Full Name"
                    value={partnerDetails?.fullName}
                  />
                </Grid>
                <Grid item xs={5}>
                  <GridLabelValuePair
                    label="Adhaar Number"
                    value={partnerDetails?.aadharNumber}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                  <GridLabelValuePair
                    label="Mobile Number"
                    value={partnerDetails?.mobileNumber}
                  />
                </Grid>
                <Grid item xs={5}>
                  <GridLabelValuePair
                    label="Pan Number"
                    value={partnerDetails?.panNumber}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                  <GridLabelValuePair
                    label="Partner ID"
                    value={partnerDetails?.partnerId}
                  />
                </Grid>
                <Grid item xs={5}>
                  <GridLabelValuePair
                    label="Bank Account No."
                    value={partnerDetails?.bankDetails
                      ?.map((bank) => bank.accountNumber)
                      .join(',')}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                  <GridLabelValuePair
                    label="Status"
                    value={partnerDetails?.status ? 'Active' : 'Inactive'}
                  />
                </Grid>
                <Grid item xs={5}>
                  <GridLabelValuePair
                    label="IFSC Code"
                    value={partnerDetails?.bankDetails
                      ?.map((bank) => bank.ifscCode)
                      .join(',')}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </CardContent>
          </Card>
          <Box marginTop="60px">
            <Card raised sx={{ borderRadius: '10px', padding: '10px' }}>
              <CardContent>
                <PartnerDetailsSubHeader>
                  <Typography>Partner Roles</Typography>
                </PartnerDetailsSubHeader>
                <Box height={'20px'}></Box>
                <Box display="flex" flexGrow="1">
                  <DataGrid
                    rows={partnerRolesRowsData}
                    columns={PARTNER_ROLES_COLUMNS}
                    disableSelectionOnClick
                    rowsPerPageOptions={[5]}
                    autoHeight
                    sx={{
                      '& .MuiDataGrid-root': {
                        border: 'none'
                      },
                      '& .MuiDataGrid-cell': {
                        // border: "none",
                      },
                      '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        opacity: '0.7'
                      }
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box marginTop="60px">
            <Card raised sx={{ borderRadius: '10px', padding: '10px' }}>
              <CardContent>
                <PartnerDetailsSubHeader>
                  <Typography>Partner Activity</Typography>
                </PartnerDetailsSubHeader>
                <Box height={'20px'}></Box>
                <PartnerActivity employeeId={partnerDetails?.partnerId} />
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
      <SimpleBackdrop open={loading} />
    </Box>
  );
};

export default PartnerDetails;
