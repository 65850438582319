import { Button, TextField, Box, Grid, Typography } from '@mui/material';
import { DownloadRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {
  SECONDARY_BACKGROUND_COLOR,
  PRIMARY_BACKGROUND_COLOR,
  PRIMARY_COLOR,
  downloadFileEvent,
  createRolesData
} from 'src/constants';
import CustomDropdown from 'src/components/CustomDropdown';
import moment from 'moment';
import { downloadReport } from '../VendorPayment/helper';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'src/modules/Firebase/app';

const StyledSearch = styled(TextField)(() => ({
  ' & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: PRIMARY_COLOR
  },
  ' & .MuiInputLabel-root.Mui-focused': {
    color: PRIMARY_COLOR
  }
}));

const PaymentCalculationsHeader = ({
  roles,
  selectedRole,
  setSelectedRole,
  paymentCycleId,
  handlePaymentCycleChange,
  fetchTableData,
  isDataAvailable,
  searchText,
  handleSearchTextChange,
  fetchPayoutCycleData,
  paymentCycleData,
  latestReportData
}) => {
  const createDateRange = (fromDate, toDate) => {
    return `${moment(fromDate).format("DD MMM'YY") || 'N.A'} - ${
      moment(toDate).format("DD MMM'YY") || 'N.A'
    }`;
  };

  const createPaymentCycleData = () => {
    const paymentCycles = paymentCycleData.map((cycle: any) => {
      return {
        label: createDateRange(cycle.fromDate, cycle.toDate),
        value: cycle.payoutCycleId
      };
    });
    return paymentCycles;
  };

  const handleRoleChange = (changedRole) => {
    setSelectedRole(changedRole);
    fetchPayoutCycleData(changedRole);
  };

  const onPhoneChange = (value) => {
    let regex = /^[0-9]+$/;
    let charArr = value.split('');
    if (charArr.length && !charArr[charArr.length - 1].match(regex)) {
      return;
    }
    handleSearchTextChange(value);
  };

  const downloadLatestReport = async () => {
    logEvent(analytics, downloadFileEvent, {
      name: selectedRole || 'Partner Earnings'
    });
    downloadReport(latestReportData?.reportLink || '');
  };

  const isDisabled = () => {
    return (
      selectedRole.length &&
      paymentCycleId &&
      (searchText.length === 0 || searchText.length === 10)
    );
  };

  return (
    <Box
      display="flex"
      marginBottom="40px"
      alignItems={'center'}
      justifyContent="space-between">
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={6} md={4} lg={3}>
          <CustomDropdown
            options={createRolesData(roles)}
            value={selectedRole}
            onChange={handleRoleChange}
            label="Select Partners"
            width="80%"
          />
        </Grid>
        <Grid item sm={6} md={4} lg={3}>
          <CustomDropdown
            options={createPaymentCycleData()}
            value={paymentCycleId}
            onChange={handlePaymentCycleChange}
            label="Select Payment Cycle"
            width="80%"
          />
        </Grid>
        <Grid item sm={6} md={4} lg={3}>
          <StyledSearch
            label="Search Partner Mobile Number"
            placeholder="Enter Mobile Number"
            size="small"
            value={searchText}
            onChange={(e) => onPhoneChange(e.target.value)}
            sx={{
              width: '80%',
              '& div': {
                borderRadius: '12px'
              }
            }}
            inputProps={{
              maxLength: 10
            }}></StyledSearch>
          {searchText.length > 0 && searchText.length < 10 && (
            <Typography color={'red'}>Please enter valid number</Typography>
          )}
        </Grid>
        <Grid item sm={6} md={4} lg={3}>
          <Button
            variant="contained"
            sx={{
              borderRadius: '10px',
              textTransform: 'capitalize',
              width: '80%',
              background: isDisabled()
                ? PRIMARY_BACKGROUND_COLOR
                : SECONDARY_BACKGROUND_COLOR
            }}
            size="medium"
            onClick={() => fetchTableData()}
            disabled={!isDisabled()}>
            Show Report
          </Button>
        </Grid>
        <Grid item sm={6} md={4}>
          {!!isDataAvailable && (
            <Button
              color="primary"
              size="medium"
              sx={{
                textTransform: 'capitalize',
                color: PRIMARY_COLOR
              }}
              startIcon={<DownloadRounded />}
              onClick={downloadLatestReport}>
              Download Report
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentCalculationsHeader;
