import axios from 'axios';
import { useSnackbar } from 'notistack';
import { CONFIG } from 'src/config';
import { getErrorMsg } from 'src/constants';
import { useMsalAuth } from './useMsalAuth';

const useAxios = () => {
  const { logout } = useMsalAuth();
  const { enqueueSnackbar } = useSnackbar();

  const API = axios.create({
    baseURL: CONFIG.API_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('partnerAccessToken')
        }`
    }
  });

  API.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('partnerAccessToken');
      if (token) {
        //@ts-ignore
        config.headers.Authorization = `Bearer ${localStorage.getItem('partnerAccessToken')}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  API.interceptors.response.use(
    (resp) => {
      return resp;
    },
    (error) => {
      const errorText = getErrorMsg(error?.response);
      if (
        error?.response?.status === 401 ||
        error?.response?.status === '401' ||
        error === undefined ||
        error?.response === undefined
      ) {
        enqueueSnackbar(errorText, {
          variant: 'error'
        });
        logout();
      } else {
        const errorText = getErrorMsg(error?.response);
        enqueueSnackbar(errorText, {
          variant: 'error'
        });
      }
      Promise.reject(error);
    }
  );
  return API;
};

export default useAxios;
