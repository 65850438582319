import { Box } from '@mui/material';
import React from 'react';
import myAppsLoginLogo from 'src/resources/images/myAppsLogin.png';
import myApp_guidLogo from 'src/resources/images/myApp_guid.png';
import { ACCESS_MAIL_ID, SUPPORT_MAIL_ID } from 'src/constants';
const index = () => {
  return (
    <Box>
      <p>
        <span style={{ fontSize: '18px' }}>
          <strong>
            You do not have access to the Partner Admin Portal. Please go
            through the following steps for getting access:
          </strong>
        </span>
      </p>

      <ul>
        <li>
          Please check you should be able to login using UGDN on the page&nbsp;
          <a href="https://myapps.microsoft.com/" target="_blank" rel="noreferrer">
            https://myapps.microsoft.com/
          </a>
          . If not able to login please contact IT Team.
        </li>
      </ul>

      <p
        style={{
          marginLeft: '40px'
        }}>
        <img src={myAppsLoginLogo} alt="login" style={{ height: '400px' }} />
      </p>

      <p>
        2. After logging in to the Microsoft check the below app (highlighted in red
        border) &#39;Parter Admin Portal&#39; shows up in the Microsoft myapps dashboard.
      </p>

      <p>
        <img src={myApp_guidLogo} style={{ height: '400px' }} alt="finder" />
      </p>

      <p>
        3.&nbsp;If the above app does not show in the Microsoft myapps dashboard please
        click on &ldquo;Service Now&rdquo; app in the Microsoft myapps dashboard and
        raise the IT request by following the below points.
      </p>

      <ul>
        <li>
          <p>
            Login in to&nbsp;
            <a
              href="https://uplonline.service-now.com/sp?id=sc_category"
              target="_blank"
              rel="noreferrer">
              https://uplonline.service-now.com/sp?id=sc_category
            </a>
          </p>
        </li>
        <li>
          <p>Click on Request --&gt; Raise a Request</p>
        </li>
        <li>
          <p>Choose &quot;Additional Service Request&quot;</p>
        </li>
        <li>
          <p>In application choose &quot;Microsoft myapps App Integration&quot;</p>
        </li>
        <li>
          <p>In the description add comment as below and submit</p>

          <p>
            <em>Please add &lt;UGDN&gt; to Partner Admin Portal</em>
          </p>

          <p>&nbsp;</p>

          <p>
            <u>Replace &lt;UGDN&gt; with appropriate UGDN.</u>
          </p>
        </li>
        <li>
          <p>
            Once the ticket is fullfilled, then application can be accessed with
            below url
          </p>

          <p>
            <a href={SUPPORT_MAIL_ID} target="_blank" rel="noreferrer">
              {SUPPORT_MAIL_ID}
            </a>
          </p>
        </li>
      </ul>

      <p>
        4. If approval is required, drop a mail to&nbsp;
        <a
          href={`mailto:%20${SUPPORT_MAIL_ID}`}
          target="_blank"
          rel="noreferrer">
          {SUPPORT_MAIL_ID}
        </a>
        &nbsp;for approval. Once approve attach the same to the ticket.
      </p>

      <p>
        5. Post the request is fulfilled, please check if the above app
        (highlighted in red arrow) shows up in the Microsoft myapps dashboard.
      </p>

      <p>
        6. If you have access to the Partner Admin Portal in the Microsoft myapps page and not able to login, please drop an email to&nbsp;
        <a
          href="mailto:%20web-support@nurture.farm?subject=Onboarding%20MDM&amp;body=Please%20provide%20MDM%20access%20to%20this%20{UGDN}"
          target="_blank"
          rel="noreferrer">
          {ACCESS_MAIL_ID}
        </a>
        &nbsp;with your UGDN and reson for the access.
      </p>
    </Box>
  );
};

export default index;
