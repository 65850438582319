import { END_POINTS, PAGE_SIZE } from './constant';

const {
  partnerBulkUploadStatusTyle,
  partnerUploadHistory,
  partnerUploadErrors,
  partnerAllErrors,
  uploadF3Report
} = END_POINTS;

export const getBulkTypeStatus = async (axiosInstance) => {
  const res = await axiosInstance.get(partnerBulkUploadStatusTyle);
  return res;
};

export const getPartnerUploadHistory = async (
  axiosInstance,
  currentPage,
  uploadType: any = undefined,
  uploadServiceType: any = undefined,
) => {
  const body: any = {
    pageNumber: currentPage,
    limit: PAGE_SIZE
  };

  if (uploadType) {
    body.uploadType = uploadType;
  }

  if (uploadServiceType) {
    body.uploadServiceType = uploadServiceType
  }
  const res = await axiosInstance.post(partnerUploadHistory, { ...body });
  return res;
};

export const getPartnerUploadErrorDetails = async (
  axiosInstance,
  currentPage,
  fileId
) => {
  const res = await axiosInstance.post(partnerUploadErrors, {
    pageNumber: currentPage,
    limit: 1000,
    fileId
  });
  return res;
};

export const getAllPartnerUploadErrors = async (axiosInstance, fileId) => {
  const res = await axiosInstance.get(`${partnerAllErrors}/${fileId}`);
  return res;
};

export const bulkUploadFile = async (axiosInstance, status, excelData, uploadServiceType?) => {
  let url;
  if (uploadServiceType) {
    url = `${uploadF3Report}?type=${status}&uploadServiceType=${uploadServiceType}`;
  } else {
    url = `${uploadF3Report}?type=${status}`;
  }
  const res = await axiosInstance.post(
    url,
    excelData
  );
  return res;
};

export const postSAPTransactionRequest = async (axiosInstance, payload) => {
  const res = await axiosInstance.post(`afs/api/partner/postSAPTransaction`, { ...payload });
  return res;
};