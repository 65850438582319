import styled from '@emotion/styled';
import { Badge, Box, Typography } from '@mui/material';
import { ThumbUpAltOutlined, Share, Comment, Close } from '@mui/icons-material';
import logo from 'src/resources/images/fc1ba46e-00d5-4277-87c5-d14b15ebd469_Top Operator Across State Mar-2022_1647799381949.png';
import logo1 from 'src/resources/images/ec8cc854-604b-4499-810f-43668c3caa06_IMG_20211007_120344.jpeg';
import { textPrimaryColor } from './constants';
import { PRIMARY_COLOR } from 'src/constants';

const MobileView = ({ setShowMobileView }) => {
  return (
    <StyledModal>
      <Close
        sx={{ float: 'right', cursor: 'pointer', color: PRIMARY_COLOR }}
        fontSize="large"
        onClick={() => setShowMobileView(false)}
      />
      <Typography sx={{ fontWeight: 600, fontSize: '18px' }}>
        Mobile View
      </Typography>
      <StyledMobileContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <div className="mobile-content-container">
            <div className="mobile-content">
              <div>
                <img
                  className="announcment-img"
                  src={logo}
                  alt="announcment-img"
                />
              </div>
              <Typography className="primary-text">
                This is the title
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '90%',
                  marginTop: '40px'
                }}>
                <StyledBadge color="primary" badgeContent={20} max={999}>
                  <ThumbUpAltOutlined />
                </StyledBadge>
                <StyledBadge color="primary" badgeContent={20} max={999}>
                  <Comment />
                </StyledBadge>
                <StyledBadge color="primary" badgeContent={20} max={999}>
                  <Share />
                </StyledBadge>
              </Box>
            </div>

            <div className="comment-section">
              <div className="mobile-content">
                <div className="date secondary-text">27 jul </div>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  <div className="profile-img">
                    <img className="img" alt="profile-img" src={logo1} />
                  </div>
                  <div>
                    <Typography className="primary-text">Angelina</Typography>
                    <Typography className="secondary-text">Operator</Typography>
                    <Typography className="secondary-text">977880</Typography>
                    <Typography className="secondary-text">
                      <Comment fontSize="small" />
                      <span className="comment">Good job bro</span>
                    </Typography>
                  </div>
                  <div>
                    <Typography className="secondary-text">
                      Telangana
                    </Typography>
                  </div>
                </Box>
              </div>
              <div className="mobile-content">
                <div className="date secondary-text">27 jul </div>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  <div className="profile-img">
                    <img className="img" alt="profile-img" src={logo1} />
                  </div>
                  <div>
                    <Typography className="primary-text">Angelina</Typography>
                    <Typography className="secondary-text">Operator</Typography>
                    <Typography className="secondary-text">977880</Typography>
                    <Typography className="secondary-text">
                      <Comment fontSize="small" />
                      <span className="comment">Good job bro</span>
                    </Typography>
                  </div>
                  <div>
                    <Typography className="secondary-text">
                      Telangana
                    </Typography>
                  </div>
                </Box>
              </div>
              <div className="mobile-content">
                <div className="date secondary-text">27 jul </div>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  <div className="profile-img">
                    <img className="img" alt="profile-img" src={logo1} />
                  </div>
                  <div>
                    <Typography className="primary-text">Angelina</Typography>
                    <Typography className="secondary-text">Operator</Typography>
                    <Typography className="secondary-text">977880</Typography>
                    <Typography className="secondary-text">
                      <Comment fontSize="small" />
                      <span className="comment">Good job bro</span>
                    </Typography>
                  </div>
                  <div>
                    <Typography className="secondary-text">
                      Telangana
                    </Typography>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </Box>
      </StyledMobileContainer>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  width: 60vw;
  padding: 20px;
  background: #f5f5f5;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  z-index: 10000;
`;

const StyledMobileContainer = styled.div`
  position: relative;
  width: 375px;
  height: 700px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  background: #e8f1e7;

  &:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }
  &:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
  }
  .mobile-content {
    padding: 10px;
    background: white;
    border-radius: 3px;
    margin: 5px;
  }
  .announcment-img {
    width: 100%;
    height: 160px;
    border: 2px dashed rgba(0, 0, 0, 0.15);
  }
  .comment-section {
    overflow: auto;
    max-height: 290px;
    .comment {
      position: relative;
      bottom: 6px;
      margin-left: 2px;
    }
    .img {
      width: 100%;
      border-radius: 50%;
      height: 100%;
      border: 1px solid ${textPrimaryColor};
    }
    .profile-img {
      width: 45px;
      height: 45px;
    }
    .date {
      text-align: right;
    }
  }
  .primary-text {
    color: ${textPrimaryColor};
    font-weight: bold;
  }
  .secondary-text {
    color: ${textPrimaryColor};
    font-size: 12px;
    margin-bottom: 4px;
  }
`;

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    right: -14,
    top: 13,
    padding: '6px 6px',
    fontSize: '12px',
    fontWeight: 600
  }
});

export default MobileView;
