import { trace } from 'firebase/performance';
import { perf } from 'src/modules/Firebase/app';
import { END_POINTS, PAGE_SIZE } from '../constants';

const { roles, payoutCycleReportLink, payoutCycleHistory } = END_POINTS;

export const getRoles = async (API) => {
  const res = await API.get(roles);
  return res;
};

export const getLatestReportLink = async (
  API,
  selectedRole,
  payoutCycleId?: number,
  partnerMobileNumber?: number,
  withBreakdown = false
) => {
  const [vertical, role] = selectedRole.split('-');
  let body = {
    role,
    vertical,
    payoutCycleId,
    partnerMobileNumber,
    withBreakdown
  };
  if (payoutCycleId) {
    body.payoutCycleId = payoutCycleId;
  }
  const t = trace(perf, 'downloadReport');
  t.start();
  const res = await API.post(payoutCycleReportLink, body);
  t.stop();
  return res;
};

export const getPartnerPayoutCycleHistory = async (API, selectedRole, page) => {
  const [vertical, role] = selectedRole.split('-');
  const body = {
    role,
    vertical,
    pageNumber: page,
    pageSize: PAGE_SIZE
  };
  const t = trace(perf, 'getPartnerPayrollFiles');
  t.start();
  const res = await API.post(payoutCycleHistory, body);
  t.stop();
  return res;
};
