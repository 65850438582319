import Backdrop from '@mui/material/Backdrop';
import nurtureLogo from 'src/resources/images/sustain-logo.svg';

export default function SimpleBackdrop(props: { open: boolean }) {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.open}
      >
        {/* <CircularProgress color="inherit" /> */}
        <img src={nurtureLogo} alt="nurture logo" />
      </Backdrop>
    </div>
  );
}
