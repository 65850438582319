import moment from 'moment';

export const formatDate = (milliseconds) => {
  return `${moment(milliseconds).format("DD MMM'YY hh:mm A") || 'N.A'}`;
};

export const createDateRange = (fromDate, toDate) => {
  return `${moment(fromDate).format("DD MMM'YY") || 'N.A'} - ${
    moment(toDate).format("DD MMM'YY") || 'N.A'
  }`;
};

export const downloadReport = (reportLink) => {
  const link = document.createElement('a');
  link.href = reportLink || '';
  link.setAttribute('download', 'file.csv');
  document.body.appendChild(link);
  link.click();
  link.remove();
};

 export const titleCase = (string) => {
   if (string?.length) {
     return string[0].toUpperCase() + string.slice(1).toLowerCase();
   } else {
     return string;
   }
 };
