import { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DownloadRounded } from '@mui/icons-material';
import CustomDropdown from 'src/components/CustomDropdown';
import { getLatestReportLink } from './actions';
import useAxios from 'src/hooks/useAxios';
import { LatestReportLinkInterface } from '../constants';
import { createDateRange, downloadReport, formatDate } from './helper';
import { createRolesData, PRIMARY_BACKGROUND_COLOR } from 'src/constants';

const PaymentCalculationsHeader = ({
  roles,
  selectedRole,
  handleRoleChange
}) => {
  const [latestReportData, setLatestReportData] = useState<
    LatestReportLinkInterface | undefined
  >(undefined);

  const API = useAxios();

  const fetchLatestReportLinkData = async (role) => {
    setLatestReportData(undefined);
    const response = await getLatestReportLink(API, role);
    if (response && response.data && response.data.data) {
      setLatestReportData(response.data.data);
    }
  };

  const onChange = (role) => {
    fetchLatestReportLinkData(role);
    handleRoleChange(role);
  };

  const createLatestReportRange = () => {
    const { fromDate, toDate } = latestReportData || { fromDate: '' };
    return createDateRange(fromDate, toDate);
  };

  const createLatestReportTime = () => {
    const { reportGeneratedOn } = latestReportData || { reportGeneratedOn: '' };
    return formatDate(reportGeneratedOn);
  };

  const downloadLatestReport = async () => {
    downloadReport(latestReportData?.reportLink || '');
  };

  return (
    <Box display="flex" marginBottom="50px">
      <Grid container spacing={2}>
        <Grid item md={6} lg={4} xl={4}>
          <CustomDropdown
            options={createRolesData(roles)}
            value={selectedRole}
            onChange={onChange}
            label="Select Partners"
            width="80%"
          />
        </Grid>
        <Grid item md={12} lg={12} xl={8} marginLeft="auto">
          {!!latestReportData && (
            <Box display="flex" alignItems={'center'}>
              {Object.keys(latestReportData).length && (
                <Typography>
                  <span
                    style={{
                      marginRight: '10px'
                    }}>{`Latest Report: ${createLatestReportRange()}`}</span>
                  <span>{`Generated On: ${createLatestReportTime()}`}</span>
                </Typography>
              )}
              <Button
                color="primary"
                variant="contained"
                size="medium"
                sx={{
                  borderRadius: '10px',
                  marginLeft: '30px',
                  textTransform: 'capitalize',
                  background: PRIMARY_BACKGROUND_COLOR
                }}
                onClick={downloadLatestReport}
                startIcon={<DownloadRounded />}>
                Download Report
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentCalculationsHeader;
