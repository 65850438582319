import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PRIMARY_COLOR } from 'src/constants';

const StyledSearch = styled(TextField)(() => ({
  ' & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: PRIMARY_COLOR
  },
  ' & .MuiInputLabel-root.Mui-focused': {
    color: PRIMARY_COLOR
  }
}));

const SearchBox = (props: {
  onValidInput: any;
  noDataPresent: boolean;
  isLoading: boolean;
}) => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(props.isLoading);
  const [noData, setNoData] = useState(props.noDataPresent);
  const [mobileNumberError, setMobileNumberError] = useState({
    hasError: false,
    label: ''
  });
  useEffect(() => {
    setNoData(props.noDataPresent);
  }, [props.noDataPresent]);
  useEffect(() => {
    setLoading(props.isLoading);
  }, [props.isLoading]);

  const handleMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    setMobileNumber(newValue);
    // Regular expression to check if string is a Indian mobile number
    const regexExp = /^[0-9]\d{9}$/gi;
    if (newValue) {
      if (regexExp.test(newValue)) {
        setMobileNumberError({
          hasError: false,
          label: ''
        });
        props.onValidInput(newValue);
      } else if (newValue.length === 10) {
        setMobileNumberError({
          hasError: true,
          label: 'Enter valid mobile number'
        });
        setNoData(false);
      } else {
        setMobileNumberError({
          hasError: false,
          label: ''
        });
      }
    } else {
      setMobileNumberError({
        hasError: false,
        label: ''
      });
      setNoData(false);
    }
  };
  return (
    <Box display="flex" padding="10px">
      <StyledSearch
        label="Search Partner"
        placeholder="Enter Mobile Number"
        size="medium"
        value={mobileNumber}
        onChange={handleMobileNumberChange}
        error={mobileNumberError.hasError}
        helperText={mobileNumberError.label}
        autoFocus
        sx={{
          width: '250px',
          '& div': {
            borderRadius: '12px'
          }
        }}
        inputProps={{
          maxLength: 10
        }}></StyledSearch>
      {loading && (
        <CircularProgress
          sx={{
            marginLeft: '5px',
            color: '#505a75',
            marginTop: '10px'
          }}
          size={30}
        />
      )}
      {!loading && noData && (
        <Box margin="auto 0 auto 5px">
          <Typography variant="body1">
            No data available with this mobile number!
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SearchBox;
