import { useState } from 'react'
import { styled } from '@mui/material/styles';
import { PRIMARY_BACKGROUND_COLOR, PRIMARY_COLOR, SECONDARY_BACKGROUND_COLOR } from 'src/constants';
import { Box, Button, Chip, Grid, TextField, Typography } from '@mui/material';
import CommonAdHocTable from '../adHocTransactions/adHocTable/table';
import CustomPagination from 'src/components/Pagination';
import SimpleBackdrop from 'src/components/Backdrop';
import useAxios from 'src/hooks/useAxios';
import { getHondurasPartnersDetails } from './api';
import { PAGE_SIZE } from './constants';

const StyledSearch = styled(TextField)(() => ({
    ' & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: PRIMARY_COLOR
    },
    ' & .MuiInputLabel-root.Mui-focused': {
        color: PRIMARY_COLOR
    }
}));

const ViewPartners = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [mobileNumber, setMobileNumber] = useState('');
    const [showAll, setShowAllPartners] = useState(false);
    const [allPartnersData, setPartnersData] = useState<any>([]);
    const [showLoader, setShowLoader] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const axiosInstance = useAxios();


    const handleMobileNumberChange = (value) => {
        let regex = /^[0-9]+$/;
        let charArr = value.split('');
        if (charArr.length && !charArr[charArr.length - 1].match(regex)) {
            return;
        }
        setMobileNumber(value);
    }

    const searchPartner = () => {
        setCurrentPage(1);
        setPageCount(1);
        getPartnersData();
    }

    const columns = [
        {
            field: 'fullName', headerName: 'Name', flex: 1,
            renderCell: (params) => {
                return params?.row?.fullName ? <Typography sx={{
                    color: PRIMARY_COLOR
                }}>{params?.row?.fullName}</Typography> : '-';
            }
        },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'mobileNumber', headerName: 'Phone No.', flex: 0.8 },
        {
            field: 'businessName', headerName: 'Business Name.', flex: 0.8,
            renderCell: (params) => {
                if(params?.row?.businessName) {
                    let jsonObj = JSON.parse(params?.row?.businessName);
                    return jsonObj?.businessName ? jsonObj?.businessName : '-';
                } else {
                   return '-';
                }
            }
        },
        {
            field: 'status', headerName: 'Status.', flex: 0.8,
            renderCell: (params) => {
                if (params?.row?.status == true) {
                    return <Chip label="Active" color="success" />
                } else {
                    return <Chip label="Inactive" style={{color:"#FFFFFF", backgroundColor:"#0000004D"}} />
                }
            }
        },
    ];

    const resetFiter = () => {
        setMobileNumber("");
        setPartnersData([]);
        setCurrentPage(1);
        setPageCount(1);
        setShowAllPartners(false);
    }

    const getPartnersData = async (page = currentPage) => {
        setShowLoader(true);
        const response = await getHondurasPartnersDetails(axiosInstance, page, mobileNumber);
        if (response?.data?.responseDTOList?.length) {
            setPartnersData(response?.data?.responseDTOList);
            if (response?.data?.responseDTOList.length >= PAGE_SIZE) {
                setPageCount((pageCount) => pageCount + 1);
            }
        } else {
            setPartnersData([]);
            setPageCount(1);
        }
        setShowAllPartners(true);
        setShowLoader(false);
    }

    const onPageChange = (page) => {
        setCurrentPage(page);
        getPartnersData(page);
    }

    return (
        <Box padding="10px" marginTop="30px">
            <Grid container style={{ "display": "flex" }}>
                <Grid item className='search-box-container'>
                    <StyledSearch
                        label="Search Partner by Phone Number"
                        placeholder="Enter Mobile Number"
                        size="medium"
                        value={mobileNumber}
                        onChange={(e) => handleMobileNumberChange(e.target.value)}
                        sx={{
                            width: '340px',
                            '& div': {
                                borderRadius: '12px'
                            }
                        }}
                        inputProps={{
                            maxLength: 8
                        }}></StyledSearch>
                    {mobileNumber.length > 0 && mobileNumber.length < 8 && (
                        <Typography color={'red'}>Please enter valid number</Typography>
                    )}
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        sx={{
                            borderRadius: '10px',
                            textTransform: 'capitalize',
                            width: '90px',
                            height: '50px',
                            marginTop: "2px",
                            marginLeft: "20px",
                            background: mobileNumber.length >= 8
                                ? PRIMARY_BACKGROUND_COLOR
                                : SECONDARY_BACKGROUND_COLOR
                        }}
                        onClick={searchPartner}
                        disabled={mobileNumber.length < 8}>
                        Search
                    </Button>
                    <Button
                        color="primary"
                        size="medium"
                        sx={{
                            width: '90px',
                            height: '50px',
                            marginTop: "2px",
                            marginLeft: "20px",
                            textTransform: 'capitalize',
                            color: PRIMARY_COLOR
                        }}
                        onClick={resetFiter}>
                        Reset
                    </Button>
                </Grid>
            </Grid>
            {!showAll &&
                <Box marginTop='40px' marginBottom='40px'>
                    View all partners
                    <Button
                        color="primary"
                        size="medium"
                        sx={{
                            border: `2px solid ${PRIMARY_COLOR}`,
                            borderRadius: "8px",
                            marginLeft: "10px",
                            textTransform: 'capitalize',
                            color: PRIMARY_COLOR
                        }}
                        onClick={() => getPartnersData()}>
                        View All
                    </Button>
                </Box>
            }
            {showAll && <Box marginTop='40px'>
                <CommonAdHocTable
                    getRowId={(row) => row.partnerId}
                    tableData={allPartnersData}
                    columns={columns}
                    rowHeight={50}
                    cellContentPadding={0}
                />
            </Box>}
            {showAll &&
                <CustomPagination
                    page={currentPage}
                    setPage={onPageChange}
                    pageCount={pageCount}
                    dataLength={allPartnersData.length}
                />
            }
            {showLoader && <SimpleBackdrop open={showLoader} />}
        </Box>
    )
}

export default ViewPartners
