import { createRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import Upload from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { PRIMARY_COLOR } from 'src/constants';
import CustomButton from 'src/components/CustomButton';
import { Link } from '@mui/material';

const UploadFile = ({
  handleFileChange,
  downloadTemplateS3Link,
  width = '60%',
  marginBottom = '30px'
}) => {
  const fileUploadRef: any = createRef();
  const [dragActive, setDragActive] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(false);

  const handleClick = () => {
    fileUploadRef.current.click();
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e?.dataTransfer?.files?.length) {
      handleFileChange(e.dataTransfer.files[0]);
    }
  };

  const onChange = (e) => {
    const files = e?.target?.files;
    if (files.length) {
      handleFileChange(files[0]);
      fileUploadRef.current.value = '';
    }
  };

  return (
    <StyledUploadContainer
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      sx={{
        marginBottom,
        width
      }}>
      <Typography component={'div'} sx={{ color: 'rgba(0, 0, 0, 0.3)', mb: 2 }}>
        {uploadedFileName}
      </Typography>
      <TextField
        inputRef={fileUploadRef}
        type={'file'}
        sx={{ display: 'none' }}
        inputProps={{
          accept: '.xlsx,.xls'
        }}
        onChange={onChange}
      />
      <CustomButton
        title="Upload File"
        variant="contained"
        onClick={handleClick}
        startIcon={<Upload />}
      />
      <Typography component={'div'} sx={{ textAlign: 'center', mt: 1 }}>
        <Typography component={'div'} sx={{ color: 'rgba(0, 0, 0, 0.3)' }}>
          or
        </Typography>
        <div>drop a file</div>
      </Typography>
      <Link href={downloadTemplateS3Link} target={'_blank'} underline="none">
        <Button
          sx={{
            textTransform: 'capitalize',
            color: PRIMARY_COLOR,
            mt: 4
          }}
          variant="text">
          Download Template
        </Button>
      </Link>
    </StyledUploadContainer>
  );
};

const StyledUploadContainer = styled(Box)`
  border: 4px dashed #e9e9e9;
  padding: 30px 0;
  position: relative;
  left: 50%;
  border-radius: 4px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default UploadFile;
