import { RemoveRedEye } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useState } from 'react';
import CustomPagination from 'src/components/Pagination';
import CommonTable from 'src/components/table';
import { BORDER_COLOR } from 'src/constants';
import UploadErrorList from './uploadErrorList';

const UploadHistory = ({
  uploadHistoryData,
  onPageChange,
  pageCount,
  currentPage
}) => {
  const [selectedFileDetails, setSelectedFileDetails] = useState({
    fileId: null,
    fileName: ''
  });

  const columns = [
    {
      field: 'createdOn',
      headerName: 'Created on',
      flex: 1,
      renderCell: (params) =>
        moment(params?.row?.createdOn).format('DD MMM YYYY hh:mm A')
    },
    { field: 'uploadStatus', headerName: 'Upload status', flex: 1 },
    { field: 'fileName', headerName: 'File name', flex: 1 },
    { field: 'createdByActorName', headerName: 'Uploaded by', flex: 1 },
    {
      field: 'errorRowNumber',
      headerName: 'Errors',
      width: 120,
      renderCell: (params) => {
        if (
          params?.row?.uploadStatus === 'PARTNER_UPLOAD_COMPLETED' &&
          params?.row?.errorRowNumber
        ) {
          return (
            <Box>
              <span>{params?.row?.errorRowNumber} error</span>
              <RemoveRedEye
                sx={{
                  position: 'relative',
                  top: '5px',
                  ml: 1,
                  cursor: 'pointer',
                  color:
                    selectedFileDetails.fileId === params?.row?.id
                      ? BORDER_COLOR
                      : 'black'
                }}
                fontSize="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedFileDetails({
                    fileId: params?.row?.id,
                    fileName: params?.row?.fileName
                  });
                }}
              />
            </Box>
          );
        } else return '-';
      }
    }
  ];

  const useStyles = makeStyles({
    active: {
      background: '#f7f3ff',
      fontWeight: 600,
      '&:hover': {
        background: '#f7f3ff !important',
        fontWeight: 600
      }
    }
  });

  const classes = useStyles();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box
        sx={{
          width: '56%',
          height: '750px',
          overflowY: 'auto'
        }}>
        <Typography sx={{ fontWeight: 600, mb: 2 }}>Upload History</Typography>
        <CommonTable
          tableData={uploadHistoryData}
          headers={columns}
          getRowId={(row: any) => row.id}
          getRowHeight={() => 'auto'}
          rowPadding="10px 0"
          onRowClick={() => {
            return null;
          }}
          getRowClassName={(params) =>
            selectedFileDetails.fileId === params?.row?.id ? classes.active : ''
          }
        />
        <CustomPagination
          page={currentPage + 1}
          setPage={onPageChange}
          pageCount={pageCount}
        />
      </Box>
      {selectedFileDetails.fileId && (
        <UploadErrorList selectedFileDetails={selectedFileDetails} />
      )}
    </Box>
  );
};

export default UploadHistory;
