import {
  PRIMARY_BACKGROUND_COLOR,
  SUCCESS_COLOR,
  WARNING_COLOR
} from 'src/constants';

export const FILE_UPLOAD_STATUS = {
  checking: {
    color: WARNING_COLOR,
    backgroundColor: '#FBF9F4'
  },
  verified: {
    color: SUCCESS_COLOR,
    backgroundColor: '#F6FAF5'
  },
  upload: {
    color: '#fff',
    backgroundColor: PRIMARY_BACKGROUND_COLOR
  }
};

const BASE_URI = '/afs/api/partner';

export const END_POINTS = {
  preSignedUrl: `${BASE_URI}/get-presigned-url`,
  processFileUrl: `${BASE_URI}/validate-process-file `,
  getTransactionTypesUrl: `${BASE_URI}/get-transaction-list`,
  getFileTransactionsUrl: `${BASE_URI}/get-file-transaction`,
  transactionUploadHistoryUrl: `${BASE_URI}/get-transaction-upload-history`
};

export const PARTNER_UPLOAD_TYPE = {
  maker: 'BULK_UPLOAD_ADHOC_TRANSACTION_MAKER_DATA',
  checker: 'BULK_UPLOAD_ADHOC_TRANSACTION_CHECKER_DATA',
  posp: 'BULK_UPLOAD_POSP_CERTIFICATION_DATA'
};

export const FILE_ACTION = { upload: 'UPLOAD', download: 'DOWNLOAD' };

export const transactionStatus = {
  no_status: 'NO_STATUS',
  submitted: 'SUBMITTED',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  invalid: 'INVALID'
};

export const UPLOAD_STATUS = {
  PARTNER_UPLOAD_COMPLETED: 'UPLOADED',
  PARTNER_UPLOAD_IN_PROGRESS: 'IN_PROGRESS'
};

export const XLSX_ADHOC_FILE_COLUMNS = [
  { key: 'role', columnName: 'Role' },
  { key: 'subRole', columnName: 'Sub Role' },
  { key: 'geographyId', columnName: 'GeoId' },
  { key: 'partnerId', columnName: 'PartnerId' },
  { key: 'transactionType', columnName: 'Transaction Type' },
  { key: 'transactionSubType', columnName: 'Transaction subType' },
  { key: 'nature', columnName: 'Credit/Debit' },
  { key: 'amount', columnName: 'Amount' },
  { key: 'remark', columnName: 'Remarks' },
  { key: 'referencePaymentId', columnName: 'Reference Transaction' },
  { key: 'status', columnName: 'Status' },
  { key: 'statusReason', columnName: 'Status Reason' }
];

export const AD_HOC_FILE_TEMPLATES = {
  CHECKER:
    'https://afs-static-content.s3.ap-south-1.amazonaws.com/partner-upload-templates/BULK_UPLOAD_ADHOC_CHECKER_TEMPLATE.xlsx',
  MAKER:
    'https://afs-static-content.s3.ap-south-1.amazonaws.com/partner-upload-templates/Bulk_upload_Adhoc_maker_template.xlsx'
};

export const CHECKER_TRANSACTIONS_STATUS = {
  PARTNER_UPLOAD_PENDING: { label: 'Upload Final Status' },
  PARTNER_UPLOAD_IN_PROGRESS: {
    label: 'Checking...',
    backgroundColor: '#FBF9F4',
    color: WARNING_COLOR
  },
  PARTNER_UPLOAD_COMPLETED: {
    label: 'Verified',
    backgroundColor: '#F6FAF5',
    color: SUCCESS_COLOR
  }
};
