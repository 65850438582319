import { Box, Button, Grid, IconButton, Typography } from "@mui/material"
import { useState } from "react"
import CustomButton from "src/components/CustomButton";
import LoopIcon from '@mui/icons-material/Loop';
import CustomSwitch from "src/components/CustomSwitch";
import AdvanceTable from "src/components/CustomTable/AdvanceTable";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CommonMenuBarWithCheckBox from "../CommonMenuBarWithCheckBox";
import { ReconciliationTypeList, StyledRowSelectedBox, checkBookingCanSync, getBookingFilteredData, getBookingFormattedPayloadData, yesNoFilterScheme, yesNoWithNullFilterScheme } from "../constants";
import SimpleBackdrop from "src/components/Backdrop";
import React from "react";
import CommonQueryPopup from "../CommonQueryPopup";
import { ALERT_COLOR, PRIMARY_COLOR, SUCCESS_COLOR, convertDateIntoMilles, convertEndDateIntoMilles } from "src/constants";
import useAxios from "src/hooks/useAxios";
import { getPartnerDetails, getReconciliationEntries, syncEntries } from "../api";
import FilterListTwoToneIcon from '@mui/icons-material/FilterListTwoTone';
import moment from "moment";

const Bookings = ({
    isOperatorSearched,
    searchFormValues,
    forwardedRef
}) => {

    const axiosInstance = useAxios();
    const [checked, setChecked] = useState(false);
    const [bookingData, setBookingData] = useState<any>([])
    const [originalBookingData, setOriginalBookingData] = useState<any>([]);
    const [selectionModel, setSelectionModel] = useState<any>([]);
    const [headerFilterList, setHeaderFilterList] = useState<any>([]);
    const [headerFilterId, setHeaderFilterId] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [queryDialogOpen, setQueryDialogPopup] = useState(false);
    const [isQueryExExecutedSuccess, setIsQueryExExecutedSuccess] = useState(false);
    const [popupMessage, setPopupMessage] = useState<any>('');
    const [showLoader, setShowLoader] = useState(false);
    const [bookingCompletionDateOrder, setBookingCompletionDateOrder] = useState('');
    const [cashCollectionHandledFilterData, setCashCollectionHandledFilterData] = useState<any>([]);
    const [fuelExpenseHandledFilterData, setFuelExpenseHandledFilterData] = useState<any>([]);
    const [reviewHandledFilterData, setReviewHandledFilterData] = useState<any>([]);
    const [headerSelectedItems, setHeaderSelectedItem] = useState<any>([]);
    const [partnerDetails, setPartnerDetails] = useState<any>(null);
    const [searchOperator, setSearchOperator] = useState(false);

    React.useImperativeHandle(forwardedRef, () => ({
        handleSearch,
    }));

    const handleSearch = () => {
        getPartnerDetailByMobileNumber(searchFormValues?.operatorMobileNo)
    }

    // for getting partner ID 
    const getPartnerDetailByMobileNumber = async (mobileNumber) => {
        setShowLoader(true);
        setSearchOperator(false);
        try {
            const response = await getPartnerDetails(axiosInstance, mobileNumber);
            if (response?.data?.data?.partnerId) {
                setPartnerDetails(response?.data?.data);
                getBookingData(response?.data?.data?.partnerId);
            } else {
                setPartnerDetails(null);
                setShowLoader(false);
                setBookingData([]);
                setOriginalBookingData([]);
            }
        } catch (err) {
            setPartnerDetails(null);
            setShowLoader(false);
            setBookingData([]);
            setOriginalBookingData([]);
        } finally {
            setSearchOperator(true);
        }
    }

    const getBookingData = async (partnerId) => {
        const payload = {
            reconciliationTypeList: [ReconciliationTypeList.completeBooking],
            partnerId: partnerId,
            startDate: convertDateIntoMilles(searchFormValues?.startDate),
            endDate: convertEndDateIntoMilles(searchFormValues?.endDate)
        }
        getInitialFilter();
        setShowLoader(true);
        try {
            const response = await getReconciliationEntries(axiosInstance, payload);
            if (response?.data?.entityInfo && response?.data?.entityInfo.length) {
                const bookingDataList = getFormattedData(response?.data?.entityInfo);
                setBookingData(bookingDataList);
                setOriginalBookingData(bookingDataList);
            } else {
                setBookingData([]);
                setOriginalBookingData([]);
            }
        } catch (err) {
            setBookingData([]);
            setOriginalBookingData([]);
        } finally {
            setShowLoader(false);
        }
    }

    const getFormattedData = (array) => {
        const newArray = array.map(({ attributes, entityDataList, ...rest }) => ({
            ...rest,
            ...attributes
        }));

        return newArray
    }

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    };

    const handleSelect = (newSelect) => {

        let mergedArray = [];
        newSelect.map((selectedId) => {
            let tempArray1 = [];
            let tempArray2 = [];
            if (selectionModel.some(item => item.id === selectedId)) {
                tempArray1 = selectionModel.filter(item => item.id === selectedId);
            } else {
                const filteredArray = bookingData.filter(item => item.entityId === selectedId);
                // Map the filtered array to the desired format
                tempArray2 = filteredArray.map(item => ({ id: item.entityId, reconciliationType: item.reconciliationType }));
            }
            mergedArray = [...mergedArray, ...tempArray1, ...tempArray2];
        })

        if (mergedArray && mergedArray.length) {
            // Use a set to keep track of unique bookingIds
            const uniqueBookingIds = new Set();
            // Filter the array to keep only unique objects based on uniqueBookingIds
            const uniqueArray = mergedArray.filter((item: any) => {
                if (!uniqueBookingIds.has(item.id)) {
                    uniqueBookingIds.add(item.id);
                    return true;
                }
                return false;
            });
            handleSelectionModelChange(uniqueArray);
        } else {
            handleSelectionModelChange(mergedArray);
        }

    }


    const handleRowClick = (params) => {

        // for not selecting row when params?.row?.handled is 'true'
        if (checkBookingCanSync(
            params?.row?.cashCollectionExpenseHandled,
            params?.row?.fuelExpenseHandled,
            params?.row?.reviewHandled
        ) == false) {
            return
        }
        // Check if the row is already selected
        const isSelected = selectionModel.some((item) => item.id === params.row.entityId);
        // Toggle the selection
        const newSelection = isSelected
            ? selectionModel.filter((item) => item.id !== params.row.entityId)
            : [...selectionModel, { id: params.row.entityId, reconciliationType: params.row.reconciliationType }];
        handleSelectionModelChange(newSelection);
    };

    const exeCuteQueryAgain = () => {
        setQueryDialogPopup(false);
        callExecuteQuery(getBookingFormattedPayloadData(selectionModel));
    }

    const callExecuteQuery = async (array) => {

        const payload = {
            missingEntityDetailsList: array
        }

        setShowLoader(true);
        try {
            const response = await syncEntries(axiosInstance, payload);
            if (response) {
                setPopupMessage((selectionModel.length * 90) / 60);
                setIsQueryExExecutedSuccess(true);
                setQueryDialogPopup(true);
                setSelectionModel([]);
            } else {
                setPopupMessage('Internal Server Error');
                setIsQueryExExecutedSuccess(false);
                setQueryDialogPopup(true);
            }
        } catch (err) {
            setIsQueryExExecutedSuccess(false);
            setQueryDialogPopup(true);
            setPopupMessage('Internal Server Error');
        } finally {
            setShowLoader(false);
        }
    }

    const openCashCollectionHandledFilter = (event) => {
        setHeaderFilterList([...yesNoFilterScheme]);
        setHeaderSelectedItem([...cashCollectionHandledFilterData])
        setHeaderFilterId('cashCollectionHandled');
        setAnchorEl(event?.currentTarget);
    }

    const openFuelExpenseHandledFilter = (event) => {
        setHeaderFilterList([...yesNoFilterScheme]);
        setHeaderSelectedItem([...fuelExpenseHandledFilterData])
        setHeaderFilterId('fuelExpenseHandled');
        setAnchorEl(event?.currentTarget);
    }

    const openReviewHandledFilter = (event) => {
        setHeaderFilterList([...yesNoWithNullFilterScheme]);
        setHeaderSelectedItem([...reviewHandledFilterData])
        setHeaderFilterId('reviewHandled');
        setAnchorEl(event?.currentTarget);
    }

    const handleApplyFilter = (id, items) => {
        switch (id) {
            case 'cashCollectionHandled':
                setCashCollectionHandledFilterData([...items]);
                getFilteredData(
                    checked,
                    reviewHandledFilterData,
                    fuelExpenseHandledFilterData,
                    [...items],
                    bookingCompletionDateOrder
                );
                break;
            case 'fuelExpenseHandled':
                setFuelExpenseHandledFilterData([...items]);
                getFilteredData(
                    checked,
                    reviewHandledFilterData,
                    [...items],
                    cashCollectionHandledFilterData,
                    bookingCompletionDateOrder
                );
                break;
            case 'reviewHandled':
                setReviewHandledFilterData([...items]);
                getFilteredData(
                    checked,
                    [...items],
                    fuelExpenseHandledFilterData,
                    cashCollectionHandledFilterData,
                    bookingCompletionDateOrder
                );
                break;
            default:
            // code block
        }
        setAnchorEl(null);
    }

    const handleSwitchChange = (event: any) => {
        setChecked(event.target.checked);
        getFilteredData(event.target.checked, [], [], [], '');
        setCashCollectionHandledFilterData([]);
        setFuelExpenseHandledFilterData([]);
        setReviewHandledFilterData([]);
        setBookingCompletionDateOrder('');
    };

    const handleSort = (order) => {
        setBookingCompletionDateOrder(order);
        getFilteredData(
            checked,
            reviewHandledFilterData,
            fuelExpenseHandledFilterData,
            cashCollectionHandledFilterData,
            order
        );
    }

    const getFilteredData = (
        rowsUnHandled,
        reviewHandled,
        fuelExpenseHandled,
        cashCollectionHandled,
        order
    ) => {
        const filteredData = getBookingFilteredData(rowsUnHandled,
            reviewHandled,
            fuelExpenseHandled,
            cashCollectionHandled,
            order,
            originalBookingData
        );
        setBookingData(filteredData);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClosePopup = () => {
        setQueryDialogPopup(false);
    }

    const getInitialFilter = () => {
        setChecked(false);
        setCashCollectionHandledFilterData([]);
        setFuelExpenseHandledFilterData([]);
        setReviewHandledFilterData([]);
        setBookingCompletionDateOrder('');
    }

    const handleDismiss = () => {
        handleClosePopup();
        getBookingData(partnerDetails?.partnerId);
    }


    const columns = [
        {
            field: 'entityId', headerName: 'Booking ID', width: 130,
            renderCell: (params) => {
                return params?.row?.entityId ? <Typography>
                    {params?.row?.entityId}
                </Typography> : ''
            }
        },
        {
            field: 'completionDate', headerName: 'Booking Completion Date', width: 185,
            renderHeader: (params) => (
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="subtitle1" sx={{ lineHeight: 'normal', opacity: '0.7', fontSize: '14px', fontWeight: 700 }}>
                            Booking
                        </Typography>
                        <Typography variant="subtitle1" sx={{ lineHeight: 'normal', opacity: '0.7', fontSize: '14px', fontWeight: 700 }}>
                            Completion Date
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box display="flex" flexDirection="column">
                            <IconButton
                                size="small"
                                onClick={() => handleSort('asc')}
                                sx={{ padding: 0, marginLeft: '5px', }}
                            >
                                <ArrowDropUpIcon fontSize="small" sx={{ border: bookingCompletionDateOrder == 'asc' ? '2px solid rgba(0, 0, 0, 0.54)' : 'none' }} />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={() => handleSort('desc')}
                                sx={{ padding: 0, marginLeft: '5px', }}
                            >
                                <ArrowDropDownIcon fontSize="small" sx={{ border: bookingCompletionDateOrder == 'desc' ? '2px solid rgba(0, 0, 0, 0.54)' : 'none' }} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            ),
            renderCell: (params) => {
                return params?.row?.completionDate ? <Typography>
                    {moment(Number(params?.row?.completionDate)).format('DD MMM YYYY')}
                </Typography> : ''
            }
        },
        {
            field: 'mobileNumber', headerName: 'Farmer Details', width: 200,
            renderCell: (params) => {
                return params?.row?.mobileNumber ? <Box>
                    <Typography>{params?.row?.farmerName ? params?.row?.farmerName : ''}</Typography>
                    <Typography>{params?.row?.mobileNumber ? params?.row?.mobileNumber : ''}</Typography>
                </Box> : '-'
            }
        },
        {
            field: 'completionAcres', headerName: 'Completion Acres', width: 110,
            renderCell: (params) => {
                return params?.row?.completionAcres ? <Typography>
                    {params?.row?.completionAcres}
                </Typography> : ''
            }
        },
        {
            field: 'reviewedAcres', headerName: 'Reviewed Acres', width: 100,
            renderCell: (params) => {
                const reviewedAcres = Number(params?.row?.reviewedAcres) || 0;
                const completionAcres = Number(params?.row?.completionAcres) || 0;

                return (
                    <Box>
                        <Typography>
                            {reviewedAcres < completionAcres && (
                                <ArrowDropDownIcon sx={{ color: ALERT_COLOR }} fontSize='medium' />
                            )}
                            {reviewedAcres > completionAcres && (
                                <ArrowDropUpIcon sx={{ color: SUCCESS_COLOR }} fontSize="medium" />
                            )}
                            {reviewedAcres == completionAcres && (
                                <Box component='span' marginLeft='24px'></Box>
                            )}
                            {params?.row?.reviewedAcres ? params?.row?.reviewedAcres : '-'}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'bookingValue', headerName: 'Booking Value(Rs.)', width: 125,
            renderCell: (params) => {
                return params?.row?.bookingValue ? <Typography>
                    {params?.row?.bookingValue}
                </Typography> : ''
            }
        },
        {
            field: 'cashCollectionExpenseHandled', headerName: 'Cash Collection Handled', width: 195,
            renderHeader: (params) => (
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="subtitle1" sx={{ lineHeight: 'normal', opacity: '0.7', fontSize: '14px', fontWeight: 700 }}>
                            Cash Collection
                        </Typography>
                        <Typography variant="subtitle1" sx={{ lineHeight: 'normal', opacity: '0.7', fontSize: '14px', fontWeight: 700 }}>
                            Handled
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box display="flex" flexDirection="column">
                            {cashCollectionHandledFilterData.length > 0 &&
                                <IconButton
                                    size="small"
                                    onClick={openCashCollectionHandledFilter}
                                    id="cashCollectionHandled"
                                    sx={{ padding: 0, marginLeft: '5px' }}
                                >
                                    <Box sx={{ position: 'relative', color: PRIMARY_COLOR }}>
                                        <Box component='span'>
                                            <FilterListTwoToneIcon sx={{
                                                position: 'relative',
                                                fontSize: '28px'
                                            }} />
                                        </Box>
                                        <Box component='span'
                                            sx={{
                                                position: 'relative',
                                                top: '-18px',
                                                fontSize: '50px',
                                                left: '-11px'
                                            }}
                                        >.</Box>
                                    </Box>
                                </IconButton>
                            }
                            {!cashCollectionHandledFilterData.length &&
                                <IconButton
                                    size="small"
                                    onClick={openCashCollectionHandledFilter}
                                    id="cashCollectionHandled"
                                    sx={{ padding: 0, marginLeft: '5px' }}
                                >
                                    <FilterListTwoToneIcon sx={{ fontSize: '28px', color: PRIMARY_COLOR }} />
                                </IconButton>
                            }
                        </Box>
                    </Grid>
                </Grid>
            ),
            renderCell: (params) => {
                return params?.row?.cashCollectionExpenseHandled ? params?.row?.cashCollectionExpenseHandled == 'true' ?
                    <Typography color={SUCCESS_COLOR}>Yes </Typography>
                    : params?.row?.cashCollectionExpenseHandled == 'false' ?
                        <Typography color={ALERT_COLOR}>No </Typography>
                        : <Typography>{params?.row?.cashCollectionExpenseHandled} </Typography>
                    : 'Null'
            }
        },
        {
            field: 'fuelExpenseHandled', headerName: 'Fuel Expense Handled', width: 170,
            renderHeader: (params) => (
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="subtitle1" sx={{ lineHeight: 'normal', opacity: '0.7', fontSize: '14px', fontWeight: 700 }}>
                            Fuel Expense
                        </Typography>
                        <Typography variant="subtitle1" sx={{ lineHeight: 'normal', opacity: '0.7', fontSize: '14px', fontWeight: 700 }}>
                            Handled
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box display="flex" flexDirection="column">
                            {fuelExpenseHandledFilterData.length > 0 &&
                                <IconButton
                                    size="small"
                                    onClick={openFuelExpenseHandledFilter}
                                    id="fuelExpenseHandled"
                                    sx={{ padding: 0, marginLeft: '5px' }}
                                >
                                    <Box sx={{ position: 'relative', color: PRIMARY_COLOR }}>
                                        <Box component='span'>
                                            <FilterListTwoToneIcon sx={{
                                                position: 'relative',
                                                fontSize: '28px'
                                            }} />
                                        </Box>
                                        <Box component='span'
                                            sx={{
                                                position: 'relative',
                                                top: '-18px',
                                                fontSize: '50px',
                                                left: '-11px'
                                            }}
                                        >.</Box>
                                    </Box>
                                </IconButton>
                            }
                            {!fuelExpenseHandledFilterData.length &&
                                <IconButton
                                    size="small"
                                    onClick={openFuelExpenseHandledFilter}
                                    id="fuelExpenseHandled"
                                    sx={{ padding: 0, marginLeft: '5px' }}
                                >
                                    <FilterListTwoToneIcon sx={{ fontSize: '28px', color: PRIMARY_COLOR }} />
                                </IconButton>
                            }
                        </Box>
                    </Grid>
                </Grid>
            ),
            renderCell: (params) => {
                return params?.row?.fuelExpenseHandled ? params?.row?.fuelExpenseHandled == 'true' ?
                    <Typography color={SUCCESS_COLOR}>Yes </Typography>
                    : params?.row?.fuelExpenseHandled == 'false' ?
                        <Typography color={ALERT_COLOR}>No </Typography>
                        : <Typography>{params?.row?.fuelExpenseHandled} </Typography>
                    : 'Null'
            }
        },
        {
            field: 'reviewHandled', headerName: 'Review Handled', width: 200,
            renderHeader: (params) => (
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="subtitle1" sx={{ lineHeight: 'normal', opacity: '0.7', fontSize: '14px', fontWeight: 700 }}>
                            Review Handled
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box display="flex" flexDirection="column">
                            {reviewHandledFilterData.length > 0 &&
                                <IconButton
                                    size="small"
                                    onClick={openReviewHandledFilter}
                                    id="reviewHandled"
                                    sx={{ padding: 0, marginLeft: '5px' }}
                                >
                                    <Box sx={{ position: 'relative', color: PRIMARY_COLOR }}>
                                        <Box component='span'>
                                            <FilterListTwoToneIcon sx={{
                                                position: 'relative',
                                                fontSize: '28px'
                                            }} />
                                        </Box>
                                        <Box component='span'
                                            sx={{
                                                position: 'relative',
                                                top: '-18px',
                                                fontSize: '50px',
                                                left: '-11px'
                                            }}
                                        >.</Box>
                                    </Box>
                                </IconButton>
                            }
                            {!reviewHandledFilterData.length &&
                                <IconButton
                                    size="small"
                                    onClick={openReviewHandledFilter}
                                    id="reviewHandled"
                                    sx={{ padding: 0, marginLeft: '5px' }}
                                >
                                    <FilterListTwoToneIcon sx={{ fontSize: '28px', color: PRIMARY_COLOR }} />
                                </IconButton>
                            }
                        </Box>
                    </Grid>
                </Grid>
            ),
            renderCell: (params) => {
                return params?.row?.reviewHandled ? params?.row?.reviewHandled == 'Yes' ?
                    <Typography color={SUCCESS_COLOR}>Yes </Typography>
                    : params?.row?.reviewHandled == 'NO' ?
                        <Typography color={ALERT_COLOR}>No </Typography>
                        : <Typography>{params?.row?.reviewHandled} </Typography>
                    : 'Null'
            }
        },
    ];

    return (
        <Box>
            {isOperatorSearched &&
                <Box>
                    {partnerDetails?.partnerId &&
                        <Box>
                            <Box>
                                <Grid container style={{ display: "flex", marginTop: '20px' }}>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <CustomButton
                                            title='Re-execute'
                                            variant='contained'
                                            buttonType='button'
                                            onClick={exeCuteQueryAgain}
                                            disabled={selectionModel && selectionModel.length ? false : true}
                                            marginLeft='20px'
                                            startIcon={<LoopIcon />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={8} lg={8}>
                                        <CustomSwitch
                                            label={'Show only unhandled rows'}
                                            labelPosition='start'
                                            value={checked}
                                            handleChange={handleSwitchChange}
                                            required={false}
                                            disabled={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            {selectionModel.length > 0 &&
                                <StyledRowSelectedBox>
                                    Total {selectionModel.length} Rows Selected.
                                    <Button
                                        variant="text"
                                        type="button"
                                        sx={{
                                            textTransform: 'none',
                                            color: PRIMARY_COLOR
                                        }}
                                        onClick={() => { setSelectionModel([]) }}
                                    >
                                        Clear All
                                    </Button>
                                </StyledRowSelectedBox>
                            }
                            <Box sx={{ marginTop: '10px', marginLeft: '20px' }}>
                                <AdvanceTable
                                    headers={columns}
                                    tableData={bookingData}
                                    rowsPerPage={10}
                                    getRowId={(row: any) => row.entityId}
                                    onRowClick={(params) => handleRowClick(params)}
                                    checkboxSelection={true}
                                    selectionModel={selectionModel.map((item) => item.id)}
                                    handleSelectionModelChange={(newItem) => handleSelect(newItem)}
                                    isRowSelectable={(params) =>
                                        checkBookingCanSync(
                                            params?.row?.cashCollectionExpenseHandled,
                                            params?.row?.fuelExpenseHandled,
                                            params?.row?.reviewHandled
                                        )
                                    }
                                />
                            </Box>
                        </Box>
                    }
                    {searchOperator &&
                        <Box>
                            {!partnerDetails?.partnerId &&
                                <Box>
                                    <Typography sx={{ textAlign: 'center' }}>
                                        Operator not found
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    }
                </Box>
            }
            {!isOperatorSearched &&
                <Box>
                    <Typography sx={{ textAlign: 'center' }}>
                        Please enter the operator&apos;s details to retrieve Booking information.
                    </Typography>
                </Box>
            }
            {anchorEl &&
                <CommonMenuBarWithCheckBox
                    id={headerFilterId}
                    handleClose={handleClose}
                    listItem={headerFilterList}
                    labelKey={'label'}
                    valueKey={'value'}
                    anchorEl={anchorEl}
                    handleApplyFilter={handleApplyFilter}
                    selectedItem={headerSelectedItems}
                />
            }
            <Box>
                <CommonQueryPopup
                    open={queryDialogOpen}
                    handleClose={handleClosePopup}
                    handleReExecute={exeCuteQueryAgain}
                    isQueryExExecutedSuccess={isQueryExExecutedSuccess}
                    popupMessage={popupMessage}
                    handleDismiss={handleDismiss}
                />
            </Box>
            <Box>
                {showLoader && <SimpleBackdrop open={showLoader} />}
            </Box>
        </Box>
    )
}

export default Bookings
