import { Button, Checkbox, FormControlLabel, FormGroup, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { PRIMARY_COLOR } from 'src/constants';

const CommonMenuBarWithCheckBox = ({
    id,
    handleClose,
    listItem,
    labelKey,
    valueKey,
    anchorEl,
    selectedItem,
    handleApplyFilter
}) => {

    const open = Boolean(anchorEl);
    const [checkedItem, setCheckedItem] = useState<any>([]);


    useEffect(() => {
        setCheckedItem([]);
        setCheckedItem(selectedItem);
    }, [])

    const handleCheckboxChange = (item) => {
        // Create a copy of the checkedItem array
        let tempArray = [...checkedItem];

        // Check if the item's value is already in the array
        const index = tempArray.indexOf(item[valueKey]);

        if (index >= 0) {
            // If the item is found, remove it from the array
            tempArray.splice(index, 1);
            setCheckedItem(tempArray);
        } else {
            // If the item is not found, add it to the array
            tempArray.push(item[valueKey]);
            setCheckedItem(tempArray);
        }
    };


    const onClickClose = () => {
        handleClose();
    }

    const handleApply = () => {
        handleApplyFilter(id, checkedItem);
    }

    return (
        <Menu
            id={id}
            anchorEl={anchorEl}
            open={open}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            onClose={onClickClose}
        >
            {listItem.map((item) => (
                <MenuItem key={item[valueKey]}
                    sx={{ padding: 0 }}
                >
                    <FormGroup
                        sx={{ width: '100%', margin: '0', padding: '0' }}>
                        <FormControlLabel
                            sx={{ width: '100%', margin: '0', padding: '5px 10px' }}
                            control={<Checkbox
                                checked={checkedItem.includes(item[valueKey]) ? true : false}
                                onChange={() => handleCheckboxChange(item)}
                            />}
                            label={item[labelKey]}
                        />
                    </FormGroup>
                </MenuItem>
            ))}
            <Button
                variant="text"
                onClick={() => { setCheckedItem([]) }}
                sx={{
                    color: '#00000080',
                    textTransform: 'none'
                }}
            >
                Clear
            </Button>
            <Button
                variant="text"
                sx={{
                    color: PRIMARY_COLOR,
                    textTransform: 'none',
                    float: 'right'
                }}
                onClick={handleApply}
            >
                Apply
            </Button>
        </Menu>
    );
}

export default CommonMenuBarWithCheckBox;
