import styled from '@emotion/styled';
import { Button } from '@mui/material';
import {
  PRIMARY_BACKGROUND_COLOR,
  SECONDARY_BACKGROUND_COLOR
} from 'src/constants';

const CustomButton = (props) => {
  const {
    buttonType,
    title,
    variant,
    width,
    size,
    borderRadius,
    startIcon,
    endIcon,
    onClick,
    disabled,
    marginRight = '20px',
    marginLeft,
  } = props;
  return (
    <StyledButton
      sx={{
        width: width,
        borderRadius: borderRadius || '10px',
        marginLeft: marginLeft || 0,
        marginRight
      }}
      size={size || 'medium'}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      type={buttonType || 'button'}>
      {title}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  background: ${(props) =>
    props.disabled ? SECONDARY_BACKGROUND_COLOR : PRIMARY_BACKGROUND_COLOR};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export default CustomButton;
