// @ts-nocheck
import { CssBaseline } from '@mui/material';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { intersection } from 'lodash';
import {
  BrowserRouter,
  Redirect,
  Route,
  Router,
  Switch
} from 'react-router-dom';
import AppBar from 'src/components/AppBar';
import SimpleBackdrop from 'src/components/Backdrop';
import Drawer from 'src/components/Drawer';
import PrivateRoute from 'src/components/PrivateRoute';
import {
  AUTH_STATUS,
  DEFAULT_USER_DATA,
  UserDataResponseInterface
} from 'src/constants';
import Dashboard from 'src/modules/Dashboard';
import PartnerDetails from 'src/modules/PartnerDetails';
import {
  MSAL_VALIDATION_BODY,
  MSAL_VALIDATION_ENDPOINT
} from 'src/modules/PartnerDetails/constants';
import UnAuthenticated from 'src/modules/UnAuthenticated';
import { PRIVATE_ROUTES, ROUTES } from './routingConstant';
import { loginRequest } from '../modules/MsalAuth/authConfig';
import { useMsalAuth } from 'src/hooks/useMsalAuth';

export const UserAuthContext = React.createContext<{
  authStatus: string;
  setAuthStatus: any;
}>({
  authStatus: AUTH_STATUS.pending,
  setAuthStatus: () => { }
});
export const UserDataContext = React.createContext<{
  userData: UserDataResponseInterface;
  setUserData: any;
}>({
  userData: DEFAULT_USER_DATA,
  setUserData: () => { }
});

const HomeWithRouter = () => {
  const theme = createTheme();
  const browserHistory = createBrowserHistory();
  const { isAuthenticated, instance, accounts } = useMsalAuth();
  const [authStatus, setAuthStatus] = useState(AUTH_STATUS.pending);
  const [userData, setUserData] = useState(DEFAULT_USER_DATA);
  const [userRoles, setUserRoles] = useState<any>([]);
  const [msalInitialized, setMsalInitialized] = useState(false);

  useEffect(() => {
    const initializeMsal = async () => {
      await instance.initialize(); // Initialize MSAL
      setMsalInitialized(true); // Mark MSAL as initialized
    };

    initializeMsal();
  }, [instance]);

  useEffect(() => {
    if (isAuthenticated && msalInitialized) {
      getMsalToken();
    }
  }, [isAuthenticated, msalInitialized]);

  const getMsalToken = async () => {
    await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    }).then((response) => {
      getAFSToken(response?.accessToken);
    }).catch((err) => {
      console.log("error while getting msal token", err)
    })
  }

  const getAFSToken = (authToken) => {
    axios
      .post(MSAL_VALIDATION_ENDPOINT, MSAL_VALIDATION_BODY, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'X-Domain': 'NURTURE_FARM'
        }
      })
      .then((resp) => {
        setAuthStatus(AUTH_STATUS.authenticated);
        setUserData(resp?.data);
        const authorityList = createAuthorityList(resp?.data?.authorities);
        setUserRoles(authorityList);
        localStorage.setItem('userRoles', authorityList);
        localStorage.setItem('partnerAccessToken', resp.data?.oauthResponseDTO?.access_token);
      })
      .catch(() => {
        setAuthStatus(AUTH_STATUS.unauthenticated);
      });
  }

  const createAuthorityList = (authorities) => {
    const authorityList = authorities.map((access) => {
      return access?.authority
    })
    return authorityList;
  };

  return (
    /* tslint:disable */
    <Router history={browserHistory}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={ROUTES.appRoot}>
          <UserAuthContext.Provider value={{ authStatus, setAuthStatus }}>
            <UserDataContext.Provider value={{ userData, setUserData }}>
              <Home userRoles={userRoles} />
            </UserDataContext.Provider>
          </UserAuthContext.Provider>
        </BrowserRouter>
      </ThemeProvider>
    </Router>
  );
};

const Home = ({ userRoles }) => {
  const { authStatus } = React.useContext(UserAuthContext);

  const isArrayWithLength = (arr) => {
    return Array.isArray(arr) && arr.length;
  };

  const getAllowedRoutes = () => {
    const allowedRoutes = PRIVATE_ROUTES.filter((route) => {
      const permissions = route.permissions;
      if (!permissions) {
        return true;
      } else if (!isArrayWithLength(permissions)) return true;
      else return intersection(permissions, userRoles).length;
    });
    return allowedRoutes;
  };

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)' }}>
      <CssBaseline />
      <AppBar />
      <Drawer userRoles={userRoles} />
      {authStatus !== AUTH_STATUS.pending && (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: 'calc(100vh-100px)',
            overflow: 'auto',
            marginTop: '20px'
          }}>
          <Box
            sx={{
              margin: '100px 20px 20px 20px'
            }}>
            <Switch>
              <PrivateRoute
                exact
                path={ROUTES.partnerDetails}
                component={PartnerDetails}
              />
              <PrivateRoute
                exact
                path={ROUTES.dashboard}
                component={Dashboard}
              />
              {getAllowedRoutes().map((route) => (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              ))}
              <Route
                path={ROUTES.unauthenticated}
                component={UnAuthenticated}
              />
              <Route path="*" element={<Redirect to="/" />} />
            </Switch>
          </Box>
        </Box>
      )}
      <SimpleBackdrop open={authStatus === AUTH_STATUS.pending}>
      </SimpleBackdrop>
    </Box>
  );
};

export default HomeWithRouter;
