import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { CONFIG } from 'src/config';

export const MSAL_VALIDATION_ENDPOINT = `${CONFIG.VALIDATE_TOKEN}`;
export const MSAL_VALIDATION_BODY = {
  clientId: 'sampleRXapp',
  clientSecret: 'my-secret-token-to-change-in-production',
  grantType: 'password',
  oktaLoginIssuer: 'https://login.microsoftonline.com/8bc97f9a-ea86-472e-8ab7-19db58c4c8af/v2.0'
};
export const ENDPOINTS = {
  PARTNER_DETAILS: '/afs/api/partner/detail',
  PARTNER_JOB_TYPES: '/afs/api/partner/job-types',
  PARTNER_JOB_INFORMATION: '/afs/api/partner/job-information'
};
export interface PartnerDetailsInterface {
  active: boolean;
  fullName: string;
  mobileNumber: string;
  partnerId: number;
  status: boolean;
  aadharNumber: string;
  panNumber: string;
  dateOfJoining: number;
  employeeCode: string;
  bankDetails: Array<{
    accountNumber: string;
    ifscCode: string;
  }>;
  roles: Array<{
    role: string;
    subRole: string;
    geographies: string[];
  }>;
}
export interface JobType {
  active: boolean;
  id: number;
  entity: JobEntities;
  action: string;
  namespace: string;
  lastRunAt: number;
}
export enum JobEntities {
  JOB_TYPE_RETAILER_ONBOARDING = 'JOB_TYPE_RETAILER_ONBOARDING',
  JOB_TYPE_ORDER_CREATION = 'JOB_TYPE_ORDER_CREATION',
  JOB_TYPE_FARMER_ONBOARDING = 'JOB_TYPE_FARMER_ONBOARDING',
  JOB_TYPE_PRODUCT_SCAN = 'JOB_TYPE_PRODUCT_SCAN',
  JOB_TYPE_SERVICE_BOOKING = 'JOB_TYPE_SERVICE_BOOKING',
  JOB_TYPE_HEALTH_PACKAGE = 'JOB_TYPE_HEALTH_PACKAGE',
  JOB_TYPE_AWD_ONBOARDED = 'JOB_TYPE_AWD_ONBOARDED',
  JOB_TYPE_DSR_ONBOARDED = 'JOB_TYPE_DSR_ONBOARDED',
  JOB_TYPE_PRODUCT_INTEREST = 'JOB_TYPE_PRODUCT_INTEREST'
}
export interface PartnerActivity {
  active: boolean;
  role: string;
  externalId: number;
  userName: string;
  mobileNumber: string;
  userType: string;
  jobType: JobEntities;
  jobStatus: string;
  createdTime: number;
  completedTime: number;
}

export const PARTNER_ROLES_COLUMNS: GridColDef[] = [
  {
    field: 'role',
    headerName: 'Role',
    width: 200,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'employeeCode',
    headerName: 'Employee Code',
    width: 200,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'geography',
    headerName: 'Geography',
    width: 200,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'reportingTo',
    headerName: 'Reporting To',
    width: 200,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'dateOfJoining',
    headerName: 'Date of Joining',
    width: 200,
    align: 'center',
    headerAlign: 'center'
  }
];
const dateComparator = (date1, date2) => {
  return moment(date1, PARTNER_ACTIVITY_DATE_FORMAT).diff(
    moment(date2, PARTNER_ACTIVITY_DATE_FORMAT)
  );
};
export const PARTNER_ACTIVITY_COLUMNS: GridColDef[] = [
  {
    field: 'role',
    headerName: 'Role',
    width: 150,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'externalId',
    headerName: 'ID',
    width: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'userName',
    headerName: 'User Name',
    width: 200,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'mobileNumber',
    headerName: 'Mobile No.',
    width: 150,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'userType',
    headerName: 'User Type',
    width: 200,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'jobType',
    headerName: 'Job',
    width: 200,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'jobStatus',
    headerName: 'Job Status',
    width: 200,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'createdTime',
    headerName: 'Created Time',
    width: 200,
    align: 'center',
    headerAlign: 'center',
    type: 'date',
    sortComparator: dateComparator
  },
  {
    field: 'completedTime',
    headerName: 'Completed Time',
    width: 200,
    align: 'center',
    headerAlign: 'center',
    type: 'date',
    sortComparator: dateComparator
  }
];

export enum JobStatus {
  JOB_STATUS_COMPLETED = 'JOB_STATUS_COMPLETED',
  JOB_STATUS_CANCELLED = 'JOB_STATUS_CANCELLED',
  JOB_STATUS_TO_DO = 'JOB_STATUS_TO_DO'
}
export const JOB_STATUS_TO_NAME_MAP = {
  [JobStatus.JOB_STATUS_COMPLETED]: 'Completed',
  [JobStatus.JOB_STATUS_CANCELLED]: 'Cancelled',
  [JobStatus.JOB_STATUS_TO_DO]: 'To-Do'
};
export const PARTNER_ACTIVITY_DATE_FORMAT = 'DD MMM, YYYY hh:mm:ss';
