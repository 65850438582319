import * as XLSX from 'xlsx';
import { XLSX_ADHOC_FILE_COLUMNS } from '../adHocTransactions/constants';

export const PAGE_SIZE = 20;

const BASE_URI = '/unimart/api';

export const END_POINTS = {
  partnerBulkUploadStatusTyle: `${BASE_URI}/bulk-upload-status-type`,
  partnerUploadHistory: `${BASE_URI}/get-partner-upload-history`,
  partnerUploadErrors: `${BASE_URI}/get-partner-upload-error-details-pagination`,
  partnerAllErrors: `${BASE_URI}/get-partner-upload-error-details`,
  uploadF3Report: `${BASE_URI}/uploadPartnerUsers`
};

export const POSP_BULK_UPLOAD = 'BULK_UPLOAD_POSP_CERTIFICATION_DATA';
export const GET_PARTNER_UPLOAD_HONDURAS = 'PARTNER_UPLOAD_HONDURAS';
export const BULK_UPLOAD_PARTNER_DATA = 'BULK_UPLOAD_PARTNER_DATA';

export const exportToExcel = (allErrorsData, fileName) => {
  fileName = fileName + '.xlsx';
  let file: any = [];
  for (let row of allErrorsData) {
    let tempData: any = {
      errorMessage: ''
    };
    tempData['row Number'] = row.rowNumber;
    const errorData = row?.errorMessage?.length
      ? JSON.parse(row.errorMessage)
      : '';
    for (let i = 0; i < errorData.length; i++) {
      tempData['errorMessage'] = errorData[i].message
        ? tempData.errorMessage + ',' + errorData[i].message
        : '';
    }
    file.push(tempData);
  }
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(file);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'test');
  XLSX.writeFile(wb, fileName);
};

export const adhocTransactionsExportToExcel = (data, fileName, role) => {
  let file: any = [];
  try {
    for (let row of data) {
      let tempData: any = {};
      XLSX_ADHOC_FILE_COLUMNS.forEach((data) => {
        if (
          data.key === 'role' ||
          data.key === 'geographyId' ||
          data.key === 'nature' ||
          data.key === 'subRole'
        ) {
          tempData[data.columnName] = row?.transactionConfig[data.key];
        } else if (data.key === 'transactionType') {
          tempData[data.columnName] = row?.transactionConfig[data.key]?.name;
        } else if (data.key === 'transactionSubType') {
          tempData[data.columnName] = row?.transactionConfig[data.key]?.name;
        }  else if (data.key === 'statusReason') {
          tempData[data.columnName] = row?.statusReason?.trim().length ? row?.statusReason : ''
        } else {
          tempData[data.columnName] = row[data.key];
        }
      });
      file.push(tempData);
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(file);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'sheet');
    XLSX.writeFile(wb, fileName);
  } catch (err) {
    console.log(err, 'excel file error');
    // returning if error is there
    return true;
  }
  
  return false;
};
