import { AxiosInstance } from 'axios';
import { END_POINTS, PAGE_SIZE } from './constants';
import {
  IAllAnnouncementTutorial,
  IFetchAllAnnouncementsProps,
  IUploadMediaFunction
} from './Interfaces';

const { allAnnouncenmentsRoute, uploadMediaRoute, geographyData } = END_POINTS;

export const getAllAnnouncenments: IFetchAllAnnouncementsProps = async ({
  API,
  appName,
  pageNumber,
  selectedRole,
  state,
  geographyId,
  noticeType
}) => {
  let roles: Array<{
    role: string;
    vertical: string;
  }> = [];
  if (selectedRole?.length) {
    roles = selectedRole.map((elt) => {
      const [vertical, role] = elt.value.split('-');
      return {
        role,
        vertical
      };
    });
  }
  let body = {
    // role: roles || [],
    appName,
    pageNumber,
    limit: PAGE_SIZE,
    // geographyId,
    noticeType
  };
  const response: IAllAnnouncementTutorial = await API.post(
    allAnnouncenmentsRoute,
    body
  );
  return response;
};

export const uploadFileToS3Bucket: IUploadMediaFunction = async (
  API: AxiosInstance,
  body
) => {
  const res = await API.post(uploadMediaRoute, body);
  return res;
};

export const getAllStates = async ({ API }) => {
  const response = await API.get(geographyData);
  return response;
};

export const createNewAnnouncement = async (API: AxiosInstance, body) => {
  const roles = body.roles.map((elt: any) => {
    const [, role] = elt?.split('-');
    return role;
  });
  body.roles = roles;
  const res = await API.post('/afs/api/announcement', body);
  return res;
};