import { Redirect, Route } from 'react-router-dom'
import { useMsalAuth } from 'src/hooks/useMsalAuth';

const CustomSecureRoute = ({ component: Component, ...rest }) => {

    const { accounts } = useMsalAuth();

    return (
        <Route
            {...rest}
            render={(props) =>
                // Checks if user is logged in through msal 
                accounts?.length ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    )
}

export default CustomSecureRoute
