import React from 'react';
import { DataGrid } from '@mui/x-data-grid/DataGrid';

const CommonTable: React.FC<{
  headers: any;
  tableData: any;
  onRowClick?: any;
  getRowId: any;
  getRowHeight?: any;
  rowPadding?: any;
  getRowClassName?: any;
}> = ({
  headers,
  tableData,
  onRowClick,
  getRowId,
  getRowHeight,
  rowPadding,
  getRowClassName
}) => {
  return (
    <DataGrid
      rows={tableData}
      columns={headers.map((column: any) => ({
        ...column,
        sortable: false
      }))}
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      pageSize={10}
      getRowId={(row) => getRowId(row)}
      getRowHeight={() => 'auto'}
      getEstimatedRowHeight={() => 200}
      onRowClick={(row) => onRowClick(row)}
      getRowClassName={getRowClassName}
      sx={{
        '& .MuiDataGrid-root': {
          border: 'none'
        },
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
          py: '22px'
        },
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer'
        },

        '& .MuiDataGrid-cellContent': {
          padding: rowPadding
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        '& .MuiDataGrid-cell': {
          wordBreak: 'break-all'
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
          outline: 'none'
        },

        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          opacity: '0.7'
        },
        '& .MuiDataGrid-footerContainer': {
          display: 'none'
        }
      }}
    />
  );
};

export default CommonTable;
