import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { PRIMARY_COLOR } from 'src/constants';

const CustomSwitch = ({ label, labelPosition, value, handleChange, required, disabled }) => {

    const switchStyles = {
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: PRIMARY_COLOR
        },
    };


    return (
        <FormGroup sx={{float: 'right'}}>
            <FormControlLabel
                required={required}
                control={
                    <Switch
                        checked={value}
                        onChange={handleChange}
                        sx={switchStyles}
                    />
                }
                label={label}
                labelPlacement={labelPosition}
                disabled={disabled}
            />
        </FormGroup>
    )
}

export default CustomSwitch
