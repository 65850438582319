import React from 'react';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DownloadRounded } from '@mui/icons-material';
import moment from 'moment';
import { getLatestReportLink } from './actions';
import useAxios from 'src/hooks/useAxios';
import { createDateRange, downloadReport } from './helper';
import CustomPagination from 'src/components/Pagination';
import { downloadFileEvent, PRIMARY_COLOR } from 'src/constants';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'src/modules/Firebase/app';

function VendorPaymentCalculationList({
  tableData,
  selectedRole,
  fetchPaymentData,
  pageCount
}) {
  const API = useAxios();
  const [currentPage, setCurrentPage] = React.useState(1);

  const downloadPayoutReport = async (payoutCycleId: number) => {
    logEvent(analytics, downloadFileEvent, { name: selectedRole });
    try {
      const res = await getLatestReportLink(API, selectedRole, payoutCycleId);
      if (res && res.data && res.data.data && res.data.data.reportLink) {
        downloadReport(res.data.data.reportLink);
      }
    } catch (err) {
      // empty
    }
  };

  const columns = [
    { field: 'payCycle', headerName: 'Pay Cycle', width: 300 },
    { field: 'partnerCount', headerName: 'No. of Partners', width: 150 },
    { field: 'totalAmount', headerName: 'Total Amount', width: 250 },
    { field: 'totalArrears', headerName: 'Total Arrears', width: 250 },
    { field: 'generatedOn', headerName: 'Generated On', width: 250 },
    {
      field: 'downloadLink',
      headerName: 'Action',
      width: 250,
      renderCell: (params) => (
        <strong>
          <Button
            color="primary"
            sx={{
              borderRadius: '10px',
              textTransform: 'capitalize',
              color: PRIMARY_COLOR
            }}
            startIcon={<DownloadRounded />}
            size="small"
            onClick={() => downloadPayoutReport(params.row.payoutCycleId)}>
            Download Report
          </Button>
        </strong>
      )
    }
  ];

  const generateTableRowData = () => {
    const formattedData = tableData.map((data) => {
      return {
        ...data,
        id: `${data.fromDate} - ${data.toDate}`,
        payCycle: createDateRange(data.fromDate, data.toDate),
        generatedOn: moment(data.generatedOn).format('DD MMM hh:mm A'),
        downloadLink: data.reportLink
      };
    });
    return formattedData;
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    fetchPaymentData(selectedRole, page);
  };

  return (
    <>
      <DataGrid
        rows={generateTableRowData()}
        columns={columns.map((column) => ({
          ...column,
          sortable: false
        }))}
        disableColumnMenu
        disableSelectionOnClick
        autoHeight
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none'
          },
          '& .MuiDataGrid-footerContainer': {
            display: 'none'
          },
          '& .MuiDataGrid-cell': {},
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            opacity: '0.7'
          }
        }}
      />
      <CustomPagination
        page={currentPage}
        setPage={onPageChange}
        pageCount={pageCount}
      />
    </>
  );
}

export default VendorPaymentCalculationList;
