import { AxiosInstance } from 'axios';
import React, { useEffect, useState } from 'react';
import SimpleBackdrop from 'src/components/Backdrop';
import useAxios from 'src/hooks/useAxios';
import { getAllAnnouncenments } from '../actions';
import { APP_NAME, NOTICE_TYPES } from '../constants';
import { IAllAnnouncementTutorial, IAnnouncementTutorial } from '../Interfaces';
import InboxTable from '../Table';

const Tutorials = () => {
  const [selectedRole, setSelectedRole] = useState<any>([]);
  const [selectedState, setSelectedState] = useState<any>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [pageCount, setPageCount] = React.useState<number>(1);
  const [allTutorials, setAllTutorials] = React.useState<
    Array<IAnnouncementTutorial>
  >([]);
  const [searchText, setSearchText] = React.useState<string>('');
  const [loading, setLoading] = useState(false);
  const API: AxiosInstance = useAxios();

  useEffect(() => {
    try {
      (async function fetchAllTutorials() {
        setLoading(true);
        const response: IAllAnnouncementTutorial = await getAllAnnouncenments({
          API,
          appName: APP_NAME,
          pageNumber: currentPage - 1,
          selectedRole,
          state: '',
          geographyId: selectedState,
          noticeType: NOTICE_TYPES.tutorial
        });
        if (response?.data?.elements) {
          setAllTutorials(response?.data?.elements);
        }
      })();
    } catch (err) {
      // console.log(err);
    } finally {
      setLoading(false);
    }
  }, [selectedRole, selectedState, currentPage]);

  return (
    <>
      <InboxTable
        data={allTutorials}
        isTutorial={true}
        pageCount={pageCount}
        setPageCount={setPageCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSelectedRole={setSelectedRole}
        selectedRole={selectedRole}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        setSearchText={setSearchText}
        searchText={searchText}
      />
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default Tutorials;
