import _ from 'lodash';
import moment from 'moment';
import {
  JobType,
  JOB_STATUS_TO_NAME_MAP,
  PartnerActivity as PartnerActivityInterface,
  PartnerDetailsInterface
} from 'src/modules/PartnerDetails/constants';
export const getPartnerDetailsRowsData = (data: PartnerDetailsInterface) => {
  return data.roles.map((role, index) => {
    return {
      role: role.role || 'N.A',
      employeeCode: data.employeeCode || 'N.A',
      geography: role.geographies.join(',') || 'N.A',
      reportingTo: 'N.A',
      dateOfJoining:
        moment(data.dateOfJoining * 1000).format('DD MMM, YYYY') || 'N.A',
      id: `${role.role}-${index}`
    };
  });
};

export const getPartnerActivityRowsData = (
  data: PartnerActivityInterface[],
  jobTypeData: JobType[]
) => {
  return data.map((activity, index) => {
    return {
      id: `${activity.externalId}-${index}-${activity.role}-${activity.jobType}-${activity.mobileNumber}-${activity.createdTime}`,
      role: activity.role || 'N.A',
      externalId: activity.externalId || 'N.A',
      userName: activity.userName || 'N.A',
      mobileNumber: activity.mobileNumber || 'N.A',
      userType: activity.userType || 'N.A',
      jobType:
        jobTypeData
          .find((job) => job.entity === activity.jobType)
          ?.action.split('_')
          .map((item) => _.capitalize(item))
          .join(' ') || 'N.A',
      jobStatus:
        JOB_STATUS_TO_NAME_MAP[activity.jobStatus] ||
        activity.jobStatus ||
        'N.A',
      createdTime:
        moment(activity.createdTime).format('DD MMM, YYYY hh:mm:ss') || 'N.A',
      completedTime:
        moment(activity.completedTime).format('DD MMM, YYYY hh:mm:ss') || 'N.A'
    };
  });
};
