import styled from '@emotion/styled';
import { Download, RemoveRedEye } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import { useEffect, useState } from 'react';
import CustomButton from 'src/components/CustomButton';
import CustomPagination from 'src/components/Pagination';
import CommonTable from 'src/components/table';
import { BORDER_COLOR, PRIMARY_COLOR } from 'src/constants';
import useAxios from 'src/hooks/useAxios';
import { getAllPartnerUploadErrors, getPartnerUploadErrorDetails } from './api';
import { exportToExcel, PAGE_SIZE } from './constant';

const columns = [
  {
    field: 'errorMessage',
    headerName: 'Message',
    flex: 8,
    renderCell: (params) => {
      if (params?.row?.errorMessage?.length) {
        const data = JSON.parse(params?.row?.errorMessage);
        let messages;
        if (data && data.length) {
          messages = data.map((elt) => elt?.message);
        }
        return messages?.length ? messages.join(', ') : '-';
      }
    }
  },
  { field: 'rowNumber', headerName: 'Row Number', flex: 3 }
];

let maxPageGenerated = 1;

const UploadErrorList = ({ selectedFileDetails }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [uploadErrorsData, setUploadErrorsData] = useState([]);

  const [allErrorsData, setAllErrorsData] = useState<any>([]);

  const [pageCount, setPageCount] = useState(1);

  const axiosInstance = useAxios();

  const fetchAllUploadError = async () => {
    const response = await getAllPartnerUploadErrors(
      axiosInstance,
      selectedFileDetails.fileId
    );
    if (response?.data?.length) {
      setAllErrorsData(response?.data);
    }
  };

  const fetchUploadErrorList = async () => {
    const response = await getPartnerUploadErrorDetails(
      axiosInstance,
      currentPage,
      selectedFileDetails.fileId
    );
    if (response?.data?.length) {
      setUploadErrorsData(response?.data);
      if (
        response.data?.length === PAGE_SIZE &&
        currentPage >= maxPageGenerated - 1
      ) {
        setPageCount((pageCount) => pageCount + 1);
      }
    }
  };

  useEffect(() => {
    if (selectedFileDetails.fileId) {
      fetchUploadErrorList();
      fetchAllUploadError();
    }
  }, [currentPage, selectedFileDetails.fileId]);

  const onPageChange = (page) => {
    if (page > maxPageGenerated) {
      maxPageGenerated = page;
    }
    setCurrentPage(page - 1);
  };

  return (
    <Box sx={{ width: '42%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
          alignItems: 'center'
        }}>
        <Typography
          sx={{
            width: '60%',
            fontSize: '13px',
            fontWeight: 600,
            overflowWrap: 'break-word'
          }}>
          {selectedFileDetails?.fileName || ''}
        </Typography>
        <Button
          color="primary"
          size="medium"
          sx={{
            textTransform: 'capitalize',
            color: PRIMARY_COLOR,
            border: `1px solid ${BORDER_COLOR}`,
            borderRadius: '10px',
            ':hover': {
              background: '#f7f3ff',
              border: `1px solid ${BORDER_COLOR}`
            }
          }}
          variant="outlined"
          startIcon={<Download />}
          onClick={() =>
            exportToExcel(allErrorsData, selectedFileDetails.fileName)
          }>
          Export Data
        </Button>
      </Box>
      <CommonTable
        tableData={uploadErrorsData}
        headers={columns}
        getRowId={(row: any) => row.rowNumber}
        getRowHeight={() => 'auto'}
        rowPadding="10px 0"
      />
      <CustomPagination
        page={currentPage + 1}
        setPage={onPageChange}
        pageCount={pageCount}
        justifyContent="center"
      />
    </Box>
  );
};

const StyledContaner = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const StyledText = styled(Typography)`
  textalign: left;
  word-wrap: break-word;
  width: 46%;
  color: rgb(102, 102, 102);
`;

export default UploadErrorList;
