import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import useAxios from 'src/hooks/useAxios';
import moment from 'moment';
import SimCardDownloadOutlined from '@mui/icons-material/SimCardDownloadOutlined';
import Typography from '@mui/material/Typography';
import { PRIMARY_COLOR, SECONDARY_BACKGROUND_COLOR } from 'src/constants';
import UploadFile from '../adHocTransactions/common/fileUpload';
import { StyledLoadMoreBtn } from 'src/modules/adHocTransactions/check';
import CommonAdHocTable from 'src/modules/adHocTransactions/adHocTable/table';
import {
  getBulkTypeStatus,
  getPartnerUploadErrorDetails,
  getPartnerUploadHistory
} from '../PartnerUpload/api';
import useUploadFile from '../PartnerUpload/useUploadFile';
import {
  exportToExcel,
  PAGE_SIZE,
  POSP_BULK_UPLOAD
} from '../PartnerUpload/constant';
import { getPreSignedUrlAPI } from '../adHocTransactions/apiActions';
import {
  FILE_ACTION,
  PARTNER_UPLOAD_TYPE
} from '../adHocTransactions/constants';
import { downloadReport } from '../PaymentCalculations/VendorPayment/helper';
import { Refresh, RefreshOutlined, SaveAltOutlined } from '@mui/icons-material';
import SimpleBackdrop from 'src/components/Backdrop';

function Posp() {
  const API = useAxios();
  const [currentPage, setCurrentPage] = useState(0);
  const [uploadHistoryData, setUploadHistoryData] = useState<any>([]);
  const [pospDownloadTemplateLink, setPospDownloadTemplateLink] =
    useState<any>();
  const [showLoader, setShowLoader] = useState(false);

  const fetchUploadStatusList = async () => {
    const response = await getBulkTypeStatus(axiosInstance);
    if (response?.data?.responseDTOList?.length) {
      const pospStatusType: any = response?.data?.responseDTOList.filter(
        (elt) => elt.bulkUploadType === POSP_BULK_UPLOAD
      );
      if (pospStatusType?.length) {
        setPospDownloadTemplateLink(pospStatusType[0]?.bulkUploadTemplateURI);
      }
    }
  };

  useEffect(() => {
    fetchUploadStatusList();
  }, []);

  const [uploadFile] = useUploadFile();

  const axiosInstance = useAxios();

  const fetchUploadHistoryAfterUploadFile = async () => {
    setShowLoader(true);
    try {
      const response = await getPartnerUploadHistory(
        axiosInstance,
        0,
        POSP_BULK_UPLOAD
      );
      if (response?.data?.length) {
        setUploadHistoryData(response?.data);
        setCurrentPage(1);
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchUploadHistoryList = async (page = currentPage) => {
    setShowLoader(true);
    try {
      const response = await getPartnerUploadHistory(
        axiosInstance,
        page,
        POSP_BULK_UPLOAD
      );
      if (response?.data?.length) {
        setUploadHistoryData([...uploadHistoryData, ...response?.data]);
        setCurrentPage((page) => page + 1);
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchUploadHistoryList();
  }, []);

  const handleDownload = async (fileName, id) => {
    const body = {
      fileAction: FILE_ACTION.download,
      fileName: fileName,
      fileExtension: 'xlsx',
      partnerUploadType: PARTNER_UPLOAD_TYPE.posp,
      uploadId: id
    };
    const response = await getPreSignedUrlAPI(API, body);
    if (response?.data?.data?.preSignedUrl) {
      downloadReport(response?.data?.data?.preSignedUrl);
    }
  };

  const columns = [
    {
      field: 'fileName',
      headerName: 'File',
      flex: 3,
      renderCell: (params) => {
        return (
          <Box>
            <Button
              color="primary"
              size="medium"
              sx={{
                textTransform: 'capitalize',
                color: PRIMARY_COLOR,
                ':hover': {
                  background: '#f7f3ff',
                  border: 'none'
                }
              }}
              onClick={() =>
                handleDownload(params.row?.fileName, params.row?.id)
              }
              variant="text"
              startIcon={
                <SimCardDownloadOutlined
                  sx={{
                    color: 'rgba(0, 0, 0, 0.7)'
                  }}
                />
              }>
              {params?.row?.fileName}
            </Button>
            <Typography component={'span'} sx={{ color: 'rgba(0, 0, 0, 0.5)' }}>
              {`(${params?.row?.totalRowNumber} rows)`}
            </Typography>
          </Box>
        );
      }
    },
    { field: 'createdByActorName', headerName: 'Submitted By', flex: 1 },
    {
      field: 'createdOn',
      headerName: 'Submitted On',
      flex: 1,
      renderCell: (params) =>
        moment(params?.row?.createdOn).format('DD MMM YYYY hh:mm A')
    },
    { field: 'uploadStatus', headerName: 'Upload Status', flex: 1.6 },
    {
      field: 'errorRowNumber',
      headerName: 'Errors',
      flex: 1,
      renderCell: (params) => {
        if (params?.row?.uploadStatus === 'PARTNER_UPLOAD_COMPLETED') {
          return (
            <strong>
              <Button
                color="primary"
                sx={{
                  borderRadius: '10px',
                  textTransform: 'capitalize',
                  color:
                    params?.row?.errorRowNumber === 0
                      ? SECONDARY_BACKGROUND_COLOR
                      : PRIMARY_COLOR
                }}
                endIcon={<SaveAltOutlined />}
                size="small"
                onClick={() => {
                  if (params?.row?.errorRowNumber) {
                    fetchUploadErrorList(
                      params?.row?.id,
                      params?.row?.fileName
                    );
                  }
                }}>
                {params?.row?.errorRowNumber} rows
              </Button>
            </strong>
          );
        } else return '-';
      }
    }
  ];

  const handleFileChange = (file) => {
    uploadFile(file, POSP_BULK_UPLOAD, fetchUploadHistoryAfterUploadFile);
  };

  const fetchUploadErrorList = async (fileId, fileName) => {
    const response = await getPartnerUploadErrorDetails(
      axiosInstance,
      0,
      fileId
    );
    if (response?.data?.length) {
      exportToExcel(response?.data, fileName);
    }
  };

  const loadMoreData = () => {
    fetchUploadHistoryList();
  };

  return (
    <Box>
      <UploadFile
        handleFileChange={handleFileChange}
        downloadTemplateS3Link={pospDownloadTemplateLink}
      />
      <Box
        sx={{ fontWeight: 600, mb: 4, display: 'flex', alignItems: 'center' }}>
        <Typography>Upload History</Typography>
        <RefreshOutlined
          sx={{
            color: PRIMARY_COLOR,
            height: '24px',
            width: '24px',
            ml: 2,
            cursor: 'pointer'
          }}
          onClick={() => {
            fetchUploadHistoryAfterUploadFile();
          }}
        />
      </Box>

      <CommonAdHocTable
        getRowId={(row) => row.id}
        tableData={uploadHistoryData}
        columns={columns}
        rowHeight={50}
        cellContentPadding={0}
      />
      <StyledLoadMoreBtn
        startIcon={<Add />}
        variant="text"
        disabled={uploadHistoryData.length < currentPage * PAGE_SIZE}
        onClick={loadMoreData}>
        Load More
      </StyledLoadMoreBtn>
      {showLoader && <SimpleBackdrop open={showLoader} />}
    </Box>
  );
}

export default Posp;
