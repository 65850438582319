import styled from '@emotion/styled';
import { Autocomplete, TextField } from '@mui/material';

const MultiSelect = (props) => {
  const { onChange, value, options, label, styles, required } = props;
  return (
    <StyledAutoComplete
      autoHighlight
      value={value}
      multiple={true}
      limitTags={2}
      disableCloseOnSelect
      size="small"
      onChange={onChange}
      options={[{ label: 'Select All', value: 'all' }, ...options]}
      sx={styles}
      isOptionEqualToValue={(option: any, value: any) =>
        option.value === value.value
      }
      renderInput={(params) => {
        return (
          <TextField
            required={required || false}
            {...params}
            label={label}
            variant="filled"
          />
        );
      }}
    />
  );
};

const StyledAutoComplete = styled(Autocomplete)``;

export default MultiSelect;
