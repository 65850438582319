import Box from '@mui/material/Box';
import React from 'react';

const PartnerDetailsSubHeader = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#eeeeff',
        height: '50px',
        color: '#000000',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        boxSizing: 'border-box',
        borderRadius: '12px 0px 12px 0px',
        width: '250px'
      }}
    >
      {children}
    </Box>
  );
};

export default PartnerDetailsSubHeader;
