import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { PRIMARY_COLOR } from 'src/constants';

const CustomDialog = ({
  showModal,
  handleClose,
  dialogContent,
  showCloseIcon = true
}) => {
  return (
    <Dialog open={showModal} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        {showCloseIcon && (
          <IconButton
            sx={{
              float: 'right',
              '&:hover': {
                transform: 'rotate(180deg)',
                transition: 'all .3s ease',
                color: PRIMARY_COLOR
              }
            }}
            onClick={handleClose}>
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ padding: '100px' }}>{dialogContent}</DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
