import { Box, Button, Grid, IconButton, Typography } from "@mui/material"
import { useState } from "react"
import CustomButton from "src/components/CustomButton";
import LoopIcon from '@mui/icons-material/Loop';
import CustomSwitch from "src/components/CustomSwitch";
import AdvanceTable from "src/components/CustomTable/AdvanceTable";
import CommonMenuBarWithCheckBox from "../CommonMenuBarWithCheckBox";
import { ReconciliationTypeList, StyledRowSelectedBox, fuelExpenseScheme, getExpenseFilteredData, groupArray, yesNoFilterScheme } from "../constants";
import SimpleBackdrop from "src/components/Backdrop";
import React from "react";
import CommonQueryPopup from "../CommonQueryPopup";
import { ALERT_COLOR, PRIMARY_COLOR, SUCCESS_COLOR, convertDateIntoMilles, convertEndDateIntoMilles } from "src/constants";
import { getPartnerDetails, getReconciliationEntries, syncEntries } from "../api";
import useAxios from "src/hooks/useAxios";
import moment from "moment";
import FilterListTwoToneIcon from '@mui/icons-material/FilterListTwoTone';


const Expense = ({
    isOperatorSearched,
    searchFormValues,
    forwardedRef
}) => {

    const axiosInstance = useAxios();
    const [checked, setChecked] = useState(false);
    const [bookingData, setBookingData] = useState<any>([]);
    const [originalBookingData, setOriginalBookingData] = useState<any>([]);
    const [selectionModel, setSelectionModel] = useState<any>([]);
    const [headerFilterList, setHeaderFilterList] = useState<any>([]);
    const [headerFilterId, setHeaderFilterId] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [queryDialogOpen, setQueryDialogPopup] = useState(false);
    const [isQueryExExecutedSuccess, setIsQueryExExecutedSuccess] = useState(false);
    const [popupMessage, setPopupMessage] = useState<any>('');
    const [showLoader, setShowLoader] = useState(false);
    const [expenseCategoryFilterData, setExpenseCategoryHandledFilterData] = useState<any>([]);
    const [fuelExpenseHandledFilterData, setFuelExpenseHandledFilterData] = useState<any>([]);
    const [headerSelectedItems, setHeaderSelectedItem] = useState<any>([]);
    const [partnerDetails, setPartnerDetails] = useState<any>(null);
    const [searchOperator, setSearchOperator] = useState(false);

    React.useImperativeHandle(forwardedRef, () => ({
        handleSearch,
    }));

    const handleSearch = () => {
        getPartnerDetailByMobileNumber(searchFormValues?.operatorMobileNo)
    }

    // for getting partner ID 
    const getPartnerDetailByMobileNumber = async (mobileNumber) => {
        setShowLoader(true);
        setSearchOperator(false);
        try {
            const response = await getPartnerDetails(axiosInstance, mobileNumber);
            if (response?.data?.data?.partnerId) {
                setPartnerDetails(response?.data?.data);
                getExpenseData(response?.data?.data?.partnerId);
            } else {
                setPartnerDetails(null);
                setShowLoader(false);
                setBookingData([]);
                setOriginalBookingData([]);
            }
        } catch (err) {
            setPartnerDetails(null);
            setShowLoader(false);
            setBookingData([]);
            setOriginalBookingData([]);
        } finally {
            setSearchOperator(true);
        }
    }

    const getExpenseData = async (partnerId) => {
        const payload = {
            reconciliationTypeList: [ReconciliationTypeList.fuelExpenseBill, ReconciliationTypeList.maintenanceBill],
            partnerId: partnerId,
            startDate: convertDateIntoMilles(searchFormValues?.startDate),
            endDate: convertEndDateIntoMilles(searchFormValues?.endDate)
        }
        getInitialFilter();
        setShowLoader(true);
        try {
            const response = await getReconciliationEntries(axiosInstance, payload);
            if (response?.data?.entityInfo && response?.data?.entityInfo.length) {
                const bookingDataList = getFormattedData(response?.data?.entityInfo);
                setBookingData(bookingDataList)
                setOriginalBookingData(bookingDataList);
            } else {
                setBookingData([]);
                setOriginalBookingData([]);
            }
        } catch (err) {
            setBookingData([]);
            setOriginalBookingData([]);
        } finally {
            setShowLoader(false);
        }
    }

    const getFormattedData = (array) => {
        const newArray = array.map(({ attributes, entityDataList, ...rest }) => ({
            ...rest,
            ...attributes
        }));

        return newArray
    }

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    };

    const handleSelect = (newSelect) => {

        let mergedArray = [];
        newSelect.map((selectedId) => {
            let tempArray1 = [];
            let tempArray2 = [];
            if (selectionModel.some(item => item.id === selectedId)) {
                tempArray1 = selectionModel.filter(item => item.id === selectedId);
            } else {
                const filteredArray = bookingData.filter(item => item.entityId === selectedId);
                // Map the filtered array to the desired format
                tempArray2 = filteredArray.map(item => ({ id: item.entityId, reconciliationType: item.reconciliationType }));
            }
            mergedArray = [...mergedArray, ...tempArray1, ...tempArray2];
        })

        if (mergedArray && mergedArray.length) {
            // Use a set to keep track of unique expenseIds
            const uniqueExpenseIds = new Set();
            // Filter the array to keep only unique objects based on expenseId
            const uniqueArray = mergedArray.filter((item: any) => {
                if (!uniqueExpenseIds.has(item.id)) {
                    uniqueExpenseIds.add(item.id);
                    return true;
                }
                return false;
            });
            handleSelectionModelChange(uniqueArray);
        } else {
            handleSelectionModelChange(mergedArray);
        }
    }


    const handleRowClick = (params) => {

        // for not selecting row when params?.row?.handled is 'true'
        if (params?.row?.handled && params?.row?.handled == 'true') {
            return
        }
        // Check if the row is already selected
        const isSelected = selectionModel.some((item) => item.id === params.row.entityId);
        // Toggle the selection
        const newSelection = isSelected
            ? selectionModel.filter((item) => item.id !== params.row.entityId)
            : [...selectionModel, { id: params.row.entityId, reconciliationType: params.row.reconciliationType }];
        handleSelectionModelChange(newSelection);
    };

    const exeCuteQueryAgain = () => {
        setQueryDialogPopup(false);
        callExecuteQuery(groupArray(selectionModel));
    }

    const callExecuteQuery = async (array) => {
        const payload = {
            missingEntityDetailsList: array
        }
        setShowLoader(true);
        try {
            const response = await syncEntries(axiosInstance, payload);
            if (response) {
                setPopupMessage((selectionModel.length * 30) / 60);
                setIsQueryExExecutedSuccess(true);
                setQueryDialogPopup(true);
                setSelectionModel([]);
            } else {
                setPopupMessage('Internal Server Error');
                setIsQueryExExecutedSuccess(false);
                setQueryDialogPopup(true);
            }
        } catch (err) {
            setIsQueryExExecutedSuccess(false);
            setQueryDialogPopup(true);
            setPopupMessage('Internal Server Error');
        } finally {
            setShowLoader(false);
        }
    }


    const openExpenseCategoryHandledFilter = (event) => {
        setHeaderFilterList([...fuelExpenseScheme]);
        setHeaderSelectedItem([...expenseCategoryFilterData])
        setHeaderFilterId('expenseCategory');
        setAnchorEl(event?.currentTarget);
    }

    const openFuelExpenseHandledFilter = (event) => {
        setHeaderFilterList([...yesNoFilterScheme]);
        setHeaderSelectedItem([...fuelExpenseHandledFilterData])
        setHeaderFilterId('fuelExpenseHandled');
        setAnchorEl(event?.currentTarget);
    }

    const handleApplyFilter = (id, items) => {
        switch (id) {
            case 'expenseCategory':
                setExpenseCategoryHandledFilterData([...items]);
                getFilteredData(checked, fuelExpenseHandledFilterData, [...items]);
                break;
            case 'fuelExpenseHandled':
                setFuelExpenseHandledFilterData([...items]);
                getFilteredData(checked, [...items], expenseCategoryFilterData);
                break;
        }
        setAnchorEl(null);
    }

    const handleSwitchChange = (event: any) => {
        setChecked(event.target.checked);
        getFilteredData(event.target.checked, [], []);
        setExpenseCategoryHandledFilterData([]);
        setFuelExpenseHandledFilterData([]);
    };

    const getFilteredData = (rowsUnHandled, expenseHandled, expenseCategory) => {
        setBookingData(getExpenseFilteredData(rowsUnHandled, expenseHandled, expenseCategory, originalBookingData));
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClosePopup = () => {
        setQueryDialogPopup(false);
    }

    const getInitialFilter = () => {
        setChecked(false);
        setExpenseCategoryHandledFilterData([]);
        setFuelExpenseHandledFilterData([]);
    }

    const handleDismiss = () => {
        handleClosePopup();
        getExpenseData(partnerDetails?.partnerId);
    }

    const columns = [
        {
            field: 'entityId', headerName: 'Expense ID', width: 130,
            renderCell: (params) => {
                return params?.row?.entityId ? <Typography>
                    {params?.row?.entityId}
                </Typography> : ''
            }
        },

        {
            field: 'submissionDate', headerName: 'Submission Date', width: 145,
            renderCell: (params) => {
                return params?.row?.submissionDate ? <Typography>
                    {moment(Number(params?.row?.submissionDate)).format('DD MMM YYYY')}
                </Typography> : ''
            }
        },

        {
            field: 'verificationDate', headerName: 'Verification Date', width: 145,
            renderCell: (params) => {
                return params?.row?.verificationDate ? <Typography>
                    {moment(Number(params?.row?.verificationDate)).format('DD MMM YYYY')}
                </Typography> : ''
            }
        },

        {
            field: 'expenseAmount', headerName: 'Expense Amount', width: 145,
            renderCell: (params) => {
                return params?.row?.expenseAmount ? <Typography>
                    {params?.row?.expenseAmount}
                </Typography> : ''
            }
        },
        {
            field: 'expenseCategory', headerName: 'Expense Category', width: 215,
            renderHeader: (params) => (
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography sx={{ lineHeight: 'normal', opacity: '0.7' }}>
                            Expense Category
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box display="flex" flexDirection="column">
                            {expenseCategoryFilterData.length > 0 &&
                                <IconButton
                                    size="small"
                                    onClick={openExpenseCategoryHandledFilter}
                                    id="expenseCategory"
                                    sx={{ padding: 0, marginLeft: '5px' }}
                                >
                                    <Box sx={{ position: 'relative', color: PRIMARY_COLOR }}>
                                        <Box component='span'>
                                            <FilterListTwoToneIcon sx={{
                                                position: 'relative',
                                                fontSize: '28px'
                                            }} />
                                        </Box>
                                        <Box component='span'
                                            sx={{
                                                position: 'relative',
                                                top: '-18px',
                                                fontSize: '50px',
                                                left: '-11px'
                                            }}
                                        >.</Box>
                                    </Box>
                                </IconButton>
                            }
                            {!expenseCategoryFilterData.length &&
                                <IconButton
                                    size="small"
                                    onClick={openExpenseCategoryHandledFilter}
                                    id="expenseCategory"
                                    sx={{ padding: 0, marginLeft: '5px' }}
                                >
                                    <FilterListTwoToneIcon sx={{ fontSize: '28px', color: PRIMARY_COLOR }} />
                                </IconButton>
                            }
                        </Box>
                    </Grid>
                </Grid>
            ),
            renderCell: (params) => {
                return params?.row?.expenseCategory ? <Typography >
                    {params?.row?.expenseCategory.charAt(0).toUpperCase() +
                    params?.row?.expenseCategory.slice(1).toLowerCase()}
                </Typography> : ''
            }
        },
        {
            field: 'handled', headerName: 'Expense Handled', width: 210,
            renderHeader: (params) => (
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography sx={{ lineHeight: 'normal', opacity: '0.7' }}>
                            Expense Handled
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box display="flex" flexDirection="column">
                            {fuelExpenseHandledFilterData.length > 0 &&
                                <IconButton
                                    size="small"
                                    onClick={openFuelExpenseHandledFilter}
                                    id="fuelExpenseHandled"
                                    sx={{ padding: 0, marginLeft: '5px' }}
                                >
                                    <Box sx={{ position: 'relative', color: PRIMARY_COLOR }}>
                                        <Box component='span'>
                                            <FilterListTwoToneIcon sx={{
                                                position: 'relative',
                                                fontSize: '28px'
                                            }} />
                                        </Box>
                                        <Box component='span'
                                            sx={{
                                                position: 'relative',
                                                top: '-18px',
                                                fontSize: '50px',
                                                left: '-11px'
                                            }}
                                        >.</Box>
                                    </Box>
                                </IconButton>
                            }
                            {!fuelExpenseHandledFilterData.length &&
                                <IconButton
                                    size="small"
                                    onClick={openFuelExpenseHandledFilter}
                                    id="fuelExpenseHandled"
                                    sx={{ padding: 0, marginLeft: '5px' }}
                                >
                                    <FilterListTwoToneIcon sx={{ fontSize: '28px', color: PRIMARY_COLOR }} />
                                </IconButton>
                            }
                        </Box>
                    </Grid>
                </Grid>
            ),
            renderCell: (params) => {
                return params?.row?.handled ?
                    params?.row?.handled == 'true' ? <Typography color={SUCCESS_COLOR}>Yes </Typography>
                        : params?.row?.handled == 'false' ? <Typography color={ALERT_COLOR}>No </Typography>
                            : <Typography>{params?.row?.handled} </Typography>
                    : 'Null'
            }
        }
    ];

    return (
        <Box>
            {isOperatorSearched &&
                <Box>
                    {partnerDetails?.partnerId &&
                        <Box>
                            <Box>
                                <Grid container style={{ display: "flex", marginTop: '20px' }}>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <CustomButton
                                            title='Re-execute'
                                            variant='contained'
                                            buttonType='button'
                                            onClick={exeCuteQueryAgain}
                                            disabled={selectionModel && selectionModel.length ? false : true}
                                            marginLeft='20px'
                                            startIcon={<LoopIcon />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={8} lg={8}>
                                        <CustomSwitch
                                            label={'Show only unhandled rows'}
                                            labelPosition='start'
                                            value={checked}
                                            handleChange={handleSwitchChange}
                                            required={false}
                                            disabled={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            {selectionModel.length > 0 &&
                                <StyledRowSelectedBox>
                                    Total {selectionModel.length} Rows Selected.
                                    <Button
                                        variant="text"
                                        type="button"
                                        sx={{
                                            textTransform: 'none',
                                            color: PRIMARY_COLOR
                                        }}
                                        onClick={() => { setSelectionModel([]) }}
                                    >
                                        Clear All
                                    </Button>
                                </StyledRowSelectedBox>
                            }
                            <Box sx={{ marginTop: '10px', marginLeft: '20px' }}>
                                <AdvanceTable
                                    headers={columns}
                                    tableData={bookingData}
                                    rowsPerPage={10}
                                    getRowId={(row: any) => row.entityId}
                                    onRowClick={(params) => handleRowClick(params)}
                                    checkboxSelection={true}
                                    selectionModel={selectionModel.map((item) => item.id)}
                                    handleSelectionModelChange={(newItem) => handleSelect(newItem)}
                                    isRowSelectable={(params) => params.row.handled != 'true'}
                                />
                            </Box>
                        </Box>
                    }
                    {searchOperator &&
                        <Box>
                            {!partnerDetails?.partnerId &&
                                <Box>
                                    <Typography sx={{ textAlign: 'center' }}>
                                        Operator not found
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    }
                </Box>
            }
            {!isOperatorSearched &&
                <Box>
                    <Typography sx={{ textAlign: 'center' }}>
                        Please enter the operator&apos;s details to retrieve expense information.
                    </Typography>
                </Box>
            }
            {anchorEl &&
                <CommonMenuBarWithCheckBox
                    id={headerFilterId}
                    handleClose={handleClose}
                    listItem={headerFilterList}
                    labelKey={'label'}
                    valueKey={'value'}
                    anchorEl={anchorEl}
                    handleApplyFilter={handleApplyFilter}
                    selectedItem={headerSelectedItems}
                />
            }
            <Box>
                <CommonQueryPopup
                    open={queryDialogOpen}
                    handleClose={handleClosePopup}
                    handleReExecute={exeCuteQueryAgain}
                    isQueryExExecutedSuccess={isQueryExExecutedSuccess}
                    popupMessage={popupMessage}
                    handleDismiss={handleDismiss}
                />
            </Box>
            <Box>
                {showLoader && <SimpleBackdrop open={showLoader} />}
            </Box>
        </Box>
    )
}

export default Expense
