import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react'
import ManagePartners from './managePartners';
import ViewPartners from './viewPartners';
import { PRIMARY_BACKGROUND_COLOR, PRIMARY_COLOR } from 'src/constants';


const HondurasPosp = () => {

    const [avtiveLink, setActiveLink] = useState("manage");

    return (
        <Box>
            <Grid container spacing={2} paddingLeft="15px">
                <Grid item xs={6} onClick={() => setActiveLink('manage')}
                    sx={{
                        padding: '14px',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        color: avtiveLink == 'manage' ? '#FFFFFF' : PRIMARY_COLOR,
                        background: avtiveLink == 'manage' ? PRIMARY_BACKGROUND_COLOR : '#FFFFFF',
                        boxShadow: avtiveLink == 'manage' ? 'none' : '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <Typography>Create & Manage Partners</Typography>
                </Grid>
                <Grid item xs={6} onClick={() => setActiveLink('view')}
                    sx={{
                        padding: '14px',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        color: avtiveLink == 'view' ? '#FFFFFF' : PRIMARY_COLOR,
                        background: avtiveLink == 'view' ? PRIMARY_BACKGROUND_COLOR : '#FFFFFF',
                        boxShadow: avtiveLink == 'view' ? 'none' : '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
                    }}>
                    <Typography>View Partners</Typography>
                </Grid>
            </Grid>
            {avtiveLink === 'manage' && <ManagePartners />}
            {avtiveLink === 'view' && <ViewPartners />}
        </Box>
    )

}


export default HondurasPosp;