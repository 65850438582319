import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import useAxios from './useAxios';
import { FILE_ACTION } from 'src/modules/adHocTransactions/constants';
import { CONFIG } from 'src/config';

// Hook to upload files in S3 bucket with presigned URL

const useUploadS3Files = (
  getPresignedUrl: any,
  processFile: any,
  partnerUploadType: any
) => {
  const [uploadedFileData, setUploadedFileData] = useState<{
    file: any;
  }>({
    file: null
  });
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadedState, setFileUploadedState] = useState({
    showModal: false,
    isError: false,
    message: ''
  });

  const axiosInstance = useAxios();

  const processAndValidateFile = async (contentLink) => {
    try {
      const body = {
        partnerUploadType,
        contentLink,
        fileName: uploadedFileData.file.name,
        createdTimestamp: Date.now()
      };

      // no need of interceptors for this request
      const API = axios.create({
        baseURL: CONFIG.API_BASE_URL,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('partnerAccessToken')}`
        }
      });
      const response = await processFile(API, body);
      if (response?.data?.httpStatusCode == 200) {
        setFileUploadedState({
          showModal: true,
          isError: false,
          message: ''
        });
      } else {
        setFileUploadedState({
          showModal: true,
          isError: true,
          message: response?.data?.responseMessage || 'File validation failed'
        });
      }
    } catch (err) {
      const error: any = err;
      setFileUploadedState({
        showModal: true,
        isError: true,
        message:
          error?.response?.data?.responseMessage || 'File validation failed'
      });
    }
  };

  const uploadFileToCloud = async (presignedUrl: any, contentLink: any) => {
    if (uploadedFileData.file) {
      try {
        setIsLoading(true);
        await axios.put(presignedUrl, uploadedFileData.file, {
          headers: { 'Content-Type': uploadedFileData.file.type }
        });
        // call api to send file data to backend
        await processAndValidateFile(contentLink);
      } catch (error) {
        enqueueSnackbar('Please try again', {
          variant: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const validateFile = async () => {
    if (uploadedFileData.file) {
      const { file }: any = uploadedFileData;
      const fileExtension = file.name.substr(file.name.lastIndexOf('.') + 1);
      const fileName = file.name.split('.')[0];
      if (file?.size > 1073741824) {
        setFileUploadedState({
          showModal: true,
          isError: true,
          message: 'File size exceeding 1 GB'
        });
        return;
      } else if (fileExtension === 'xlsx') {
        try {
          setIsLoading(true);
          // call api to get presignURL
          const body = {
            fileAction: FILE_ACTION.upload,
            fileName,
            fileExtension,
            partnerUploadType
          };
          const presignedUrlResponse: any = await getPresignedUrl(
            axiosInstance,
            body
          );
          if (presignedUrlResponse?.data?.data?.preSignedUrl) {
            // call uploadFileToCloud with presignURL
            uploadFileToCloud(
              presignedUrlResponse?.data?.data?.preSignedUrl,
              presignedUrlResponse?.data?.data?.contentLink
            );
          }
        } catch (error) {
          enqueueSnackbar('Please try again', {
            variant: 'error'
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        setFileUploadedState({
          showModal: true,
          isError: true,
          message: 'File format not .xlx or .xlsx'
        });
      }
    }
  };

  useEffect(() => {
    if (uploadedFileData.file) validateFile();
  }, [uploadedFileData.file]);

  const uploadFile = (file: any) => {
    setUploadedFileData({
      file
    });
  };

  return [
    uploadedFileData,
    uploadFile,
    isLoading,
    fileUploadedState,
    setFileUploadedState
  ];
};

export default useUploadS3Files;
