export const getPartnerDetails = async (axiosInstance, payload) => {
    const res = await axiosInstance.post(`/afs/api/partner/detail`, payload);
    return res;
};

export const getReconciliationEntries = async (axiosInstance, payload) => {
    const res = await axiosInstance.post(`/afs/api/reconciliation/get-entries`, payload);
    return res;
};

export const syncEntries = async (axiosInstance, payload) => {
    const res = await axiosInstance.post(`/afs/api/reconciliation/sync-entries`, payload);
    return res;
};