import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../app';

const logCurrentPage = () => {
  logEvent(analytics, 'screen_view', {
    firebase_screen: window.location.pathname,
    firebase_screen_class: ''
  });
};

const AnalyticsComponent = () => {
  const history = useHistory();
  useEffect(() => {
    logCurrentPage(); // log the first page visit
    history.listen(() => {
      logCurrentPage();
    });
  }, [history]);
  return <div></div>;
};

export default AnalyticsComponent;
