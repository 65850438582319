import PersonIcon from '@mui/icons-material/Person';
import Campaign from '@mui/icons-material/Campaign';
import PaymentOutlined from '@mui/icons-material/PaymentOutlined';
import Check from '@mui/icons-material/Check';
import Upload from '@mui/icons-material/Upload';
import YouTube from '@mui/icons-material/YouTube';
import CurrencyRupeeOutlined from '@mui/icons-material/CurrencyRupeeOutlined';
import FileCopy from '@mui/icons-material/FileCopy';
import PaymentsOutlined from '@mui/icons-material/PaymentsOutlined';
import ReceiptLongOutlined from '@mui/icons-material/ReceiptLongOutlined';
import UploadFile from '@mui/icons-material/UploadFile';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import Dashboard from './Dashboard';
import PartnerDetails from './PartnerDetails';
import PartnerUploads from 'src/modules/PartnerUpload';
import PartnerPaymentCalculation from './PaymentCalculations/PartnerLevelEnrichedData';
import VendorPaymentCalculation from './PaymentCalculations/VendorPayment';
import Announcements from './Inbox/Announcements';
import AddAnnouncement from './Inbox/Announcements/create';
import Tutorials from './Inbox/Tutorials';
import AddTutorial from './Inbox/Tutorials/create';
import AdHocDefinitions from './adHocTransactions/definations';
import { AdHocCheckTransactions } from './adHocTransactions/check';
import AdHocUploadTransactions from './adHocTransactions/uploads';
import Posp from './posp';
import HondurasPosp from './hondurasPosp/index';
import { PERMISSIONS } from 'src/constants';
import LedgerReconciliation from './LedgerReconciliation';

export const ROUTES = {
  partnerDetails: '/partner-details',
  dashboard: '/',
  appRoot: '/app',
  unauthenticated: '/unauthenticated',
  login: '/login',
  partnerPayrollFiles: '/partner/payroll/files',
  partnerPayoutEarnings: '/partner/payout/earnings',
  announcements: '/inbox/announcements',
  addAnnouncements: '/inbox/announcements/create',
  tutorials: '/inbox/tutorials',
  addTutorial: '/inbox/tutorials/create',
  adHocTransactions: {
    definations: '/adHocTransactions/definations',
    check: '/adHocTransactions/check',
    upload: '/adHocTransactions/upload'
  },
  posp: '/posp',
  partnerUploads: '/partner-uploads',
  hondurasPosp: '/honduras-posp',
  ledgerReconciliation: '/reconciliation'
};

export const PRIVATE_ROUTES = [
  { path: '/', component: Dashboard },
  {
    path: ROUTES.partnerDetails,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.PARTNER_PERFORMANCE
    ],
    component: PartnerDetails
  },
  {
    path: ROUTES.partnerPayrollFiles,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.PARTNER_PAYROLL_FILES
    ],
    component: VendorPaymentCalculation
  },
  {
    path: ROUTES.partnerPayoutEarnings,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.PARTNER_EARNINGS
    ],
    component: PartnerPaymentCalculation
  },
  {
    path: ROUTES.partnerUploads,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.VIEW_PARTNER_UPLOADS
    ],
    component: PartnerUploads
  },
  {
    path: ROUTES.announcements,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.CONTENT_MANAGEMENT
    ],
    component: Announcements
  },
  {
    path: ROUTES.addAnnouncements,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.CONTENT_MANAGEMENT
    ],
    component: AddAnnouncement
  },
  {
    path: ROUTES.tutorials,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.CONTENT_MANAGEMENT
    ],
    component: Tutorials
  },
  {
    path: ROUTES.addTutorial,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.CONTENT_MANAGEMENT
    ],
    component: AddTutorial
  },
  {
    path: ROUTES.adHocTransactions.definations,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.AD_HOC_TRANSACTIONS_ROLES.admin
    ],
    component: AdHocDefinitions
  },
  {
    path: ROUTES.adHocTransactions.check,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.AD_HOC_TRANSACTIONS_ROLES.checker
    ],
    component: AdHocCheckTransactions
  },
  {
    path: ROUTES.adHocTransactions.upload,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.AD_HOC_TRANSACTIONS_ROLES.maker
    ],
    component: AdHocUploadTransactions
  },
  {
    path: ROUTES.ledgerReconciliation,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.VIEW_LEDGER_RECONCILIATION
    ],
    component: LedgerReconciliation
  },
  {
    path: ROUTES.posp,
    permissions: [PERMISSIONS.PARTNER_PORTAL_SUPERADMIN, PERMISSIONS.VIEW_POSP],
    component: Posp
  },
  {
    path: ROUTES.hondurasPosp,
    permissions: [PERMISSIONS.PARTNER_PORTAL_SUPERADMIN, PERMISSIONS.VIEW_HONDURAS_PARTNERS],
    component: HondurasPosp
  }
];

export const PARTNER_SUPERADMIN_ITEMS = [
  {
    route: ROUTES.partnerDetails,
    icon: PersonIcon,
    title: 'Partner Details',
    permission: PERMISSIONS.PARTNER_PERFORMANCE
  },
  {
    route: ROUTES.partnerPayoutEarnings,
    icon: CurrencyRupeeOutlined,
    title: 'Partner Earnings',
    permission: PERMISSIONS.PARTNER_EARNINGS
  },
  {
    route: ROUTES.partnerPayrollFiles,
    icon: PaymentsOutlined,
    title: 'Partner Payroll Files',
    permission: PERMISSIONS.PARTNER_PAYROLL_FILES
  },
  {
    route: ROUTES.partnerUploads,
    icon: UploadFile,
    title: 'Partner Uploads'
  },
  {
    title: 'Inbox',
    route: 'inbox',
    icon: PaymentOutlined,
    permission: PERMISSIONS.CONTENT_MANAGEMENT,
    children: [
      {
        route: ROUTES.announcements,
        icon: Campaign,
        title: 'Announcements',
        depth: 1
      },
      {
        route: ROUTES.tutorials,
        icon: YouTube,
        title: 'Tutorials',
        depth: 1
      }
    ]
  },
  {
    title: 'Ad hoc transactions',
    route: 'adHocTransactions',
    icon: PaymentOutlined,
    children: [
      {
        route: ROUTES.adHocTransactions.definations,
        icon: ReceiptLongOutlined,
        title: 'Definitions',
        depth: 1,
        permission: PERMISSIONS.AD_HOC_TRANSACTIONS_ROLES.admin
      },
      {
        route: ROUTES.adHocTransactions.upload,
        icon: Upload,
        title: 'Upload',
        depth: 1,
        permission: PERMISSIONS.AD_HOC_TRANSACTIONS_ROLES.maker
      },
      {
        route: ROUTES.adHocTransactions.check,
        icon: Check,
        title: 'Check',
        depth: 1,
        permission: PERMISSIONS.AD_HOC_TRANSACTIONS_ROLES.checker
      }
    ]
  },
  {
    route: ROUTES.ledgerReconciliation,
    icon: NoteAltOutlinedIcon,
    title: 'Ledger Reconciliation',
    permission: PERMISSIONS.VIEW_LEDGER_RECONCILIATION
  },
  {
    route: ROUTES.posp,
    icon: FileCopy,
    title: 'POSP',
    permission: PERMISSIONS.VIEW_POSP
  },
  {
    route: ROUTES.hondurasPosp,
    icon: FileCopy,
    title: 'Honduras Posp',
    pemission: PERMISSIONS.VIEW_HONDURAS_PARTNERS
  },
];

export const PARTNER_PAYROLL_ITEMS = [
  {
    route: ROUTES.partnerPayrollFiles,
    icon: PaymentOutlined,
    title: 'Partner Payroll Files'
  }
];

export const PARTNER_EARNING_ITEMS = [
  {
    route: ROUTES.partnerPayoutEarnings,
    icon: PaymentOutlined,
    title: 'Partner Earnings'
  }
];
