import { END_POINTS, PAGE_SIZE } from './constants';

const {
    partnerDetails
} = END_POINTS;

export const getHondurasPartnersDetails = async (
    axiosInstance,
    currentPage,
    partnerMobileNumber: any = undefined,
) => {
    const body: any = {
        pageNumber: currentPage,
        pageSize: PAGE_SIZE,
        namespace: 'HONDURAS',
        partnerMobileNumber: partnerMobileNumber ? partnerMobileNumber : '',
    };

    const res = await axiosInstance.post(partnerDetails, { ...body });
    return res;
};