import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PRIMARY_COLOR } from 'src/constants';

const StyledSelect = styled(Select)((props) => ({
  ' &.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: `${props.error ? 'red' : PRIMARY_COLOR}`,
    color: PRIMARY_COLOR
  }
}));

const StyledField = styled(InputLabel)(() => ({
  ' &.Mui-focused': {
    color: PRIMARY_COLOR
  }
}));

const CustomDropdown = (props) => {
  const {
    options,
    onChange,
    value,
    disabled,
    label,
    width,
    marginRight,
    error,
    helperText,
    required,
    onBlur,
    showDefaultOption = true
  } = props;
  return (
    <Box display="flex">
      <FormControl
        variant="outlined"
        size="small"
        sx={{
          width: width,
          marginRight: marginRight || '',
          '& div': {
            borderRadius: '12px'
          }
        }}>
        <StyledField id="test-select-label">{label}</StyledField>
        <StyledSelect
          sx={{ width: '100%' }}
          variant="outlined"
          value={value}
          onChange={(e) => {
            onChange ? onChange(e.target.value) : null;
          }}
          error={error}
          helperText={helperText}
          labelId="test-select-label"
          label={label}
          disabled={disabled}
          required={required || false}
          onBlur={onBlur}
          defaultValue={value}>
          {showDefaultOption && (
            <MenuItem key={''} value={''}>
              Select
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledSelect>
        {/* {error && <FormHelperText>{helperText}</FormHelperText>} */}
      </FormControl>
    </Box>
  );
};

export default CustomDropdown;
