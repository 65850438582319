import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid';

const CommonAdHocTable = ({
  tableData,
  columns,
  getRowId,
  rowHeight = 'auto',
  cellContentPadding = '20px',
  tableWidth = 'auto'
}: {
  tableData: any;
  columns: any;
  getRowId?: any;
  rowHeight?: any;
  cellContentPadding?: any;
  tableWidth?: any;
}) => {
  return (
    <DataGrid
      rows={tableData}
      columns={columns.map((column) => ({
        ...column,
        sortable: false
      }))}
      disableColumnMenu
      disableSelectionOnClick
      getRowId={(row) => getRowId(row)}
      autoHeight
      getRowHeight={() => rowHeight}
      sx={{
        '&.MuiDataGrid-root': {
          border: 'none !important',
          width: tableWidth
        },
        '&.MuiDataGrid-row': {
          marginBottom: '20px'
        },
        '&.MuiDataGrid-columnHeader:focus': {
          outline: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important'
        },
        '.MuiDataGrid-columnSeparator': {
          display: 'none'
        },
        '& .MuiDataGrid-colCell:focus': {
          outline: 'none'
        },
        '& .MuiDataGrid-footerContainer': {
          display: 'none'
        },
        '& .MuiDataGrid-cellContent': {
          padding: cellContentPadding
        },
        '& .MuiDataGrid-cell': {
          borderBottom: 'none',
          borderRight: 'none',
          color: 'rgba(0, 0, 0, 0.7)',
          wordBreak: 'break-all'
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          color: 'rgba(0, 0, 0, 0.7)'
        },
        '& .MuiDataGrid-columnHeader': {
          background: 'rgba(0, 0, 0, 0.05)'
        }
      }}
    />
  );
};

export default CommonAdHocTable;
