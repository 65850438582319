import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip, Divider, Typography } from '@mui/material';
import useAxios from 'src/hooks/useAxios';
import CustomPagination from 'src/components/Pagination';
import { ALERT_COLOR, SUCCESS_COLOR } from 'src/constants';
import styled from '@emotion/styled';
import { getTransactionTypesAPI } from '../apiActions';
import { PAGE_SIZE } from 'src/modules/PartnerUpload/constant';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import SimpleBackdrop from 'src/components/Backdrop';
import { titleCase } from 'src/modules/PaymentCalculations/VendorPayment/helper';

export const HEADERS = [
  { key: 'transactionNature', label: '' },
  { key: 'transactionSubType', label: 'Type' },
  { key: 'threshhold', label: 'Threshholds Approver' },
  { key: 'role', label: 'Roles' },
  { key: 'geographyName', label: 'Geography' },
  { key: 'makerFullNameList', label: 'Maker' },
  { key: 'comment', label: 'Comments' },
  { key: 'isActive', label: 'Status' }
];

function DataRow(props) {
  const { rowData } = props;

  const createNameString = (list) => {
    let names = '';
    list.forEach((elt, index) => {
      if (elt?.trim().length) {
        names = names.concat(index < list.length - 1 ? `${elt}, ` : `${elt} `);
      }
    });
    return names;
  };

  const createTransactioType = (type) => {
    let text = titleCase(type);
    text = text?.split('_').join(' ');
    return text;
  };

  const getColumnData = (header, index) => {
    if (header.key === 'threshhold') {
      return (
        <StyledTableCell
          scope="row"
          sx={{
            color: 'rgba(0, 0, 0, 0.70)',
            borderBottom: 'none',
            width: '24%'
          }}
          key={index}>
          {rowData?.checkerThresholdConfigList?.length > 0 &&
            <>
              {rowData.checkerThresholdConfigList.map((elt, index) => {
                return (
                  <React.Fragment key={index}>
                    <Box sx={{ display: 'flex' }}>
                      <StyledSpannedCell sx={{ width: '45%' }}>
                        {elt?.finalExpressionInString}
                      </StyledSpannedCell>
                      <Divider
                        orientation="vertical"
                        sx={{
                          height: 'auto',
                          borderColor: 'rgba(0, 0, 0, 0.05)'
                        }}
                      />
                      <StyledSpannedCell
                        sx={{
                          width: '55%',
                          color: `${elt?.checkerFullNameList?.length &&
                              elt?.checkerFullNameList[0] === 'Auto Rejection'
                              ? 'rgba(0, 0, 0, 0.5)'
                              : 'rgba(0, 0, 0, 0.7)'
                            }`
                        }}>
                        {elt?.checkerFullNameList?.length
                          ? createNameString(elt?.checkerFullNameList)
                          : '-'}
                      </StyledSpannedCell>
                    </Box>
                    {index < rowData?.checkerThresholdConfigList?.length - 1 && (
                      <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} />
                    )}
                  </React.Fragment>
                );
              })}
            </>
          }
        </StyledTableCell>
      );
    } else if (header.key === 'transactionSubType') {
      return (
        <StyledCell
          scope="row"
          sx={{
            color: 'rgba(0, 0, 0, 0.70)',
            borderBottom: 'none',
            width: '10%'
          }}
          key={index}>
          {rowData[header.key]?.length
            ? createTransactioType(rowData[header.key])
            : ''}
          <Typography
            sx={{
              color: 'rgba(0, 0, 0, 0.5)',
              fontSize: '14px',
              marginTop: '3px'
            }}>
            {titleCase(rowData.transactionType)}
          </Typography>
        </StyledCell>
      );
    } else if (header.key === 'makerFullNameList') {
      return (
        <StyledCell
          scope="row"
          sx={{
            color: 'rgba(0, 0, 0, 0.70)',
            borderBottom: 'none',
            width: '15%'
          }}
          key={index}>
          {rowData[header.key]?.length
            ? createNameString(rowData[header.key])
            : '-'}
        </StyledCell>
      );
    } else if (header.key === 'isActive') {
      return (
        <StyledCell
          scope="row"
          sx={{
            color: 'rgba(0, 0, 0, 0.70)',
            borderBottom: 'none'
          }}
          key={index}>
          {rowData[header.key] ? (
            <Chip label="Active" color="success" />
          ) : (
            <Chip label="Inactive" color="error" />
          )}
        </StyledCell>
      );
    } else if (header.key === 'transactionNature') {
      return (
        <StyledCell
          scope="row"
          sx={{
            color: 'rgba(0, 0, 0, 0.70)',
            borderBottom: 'none'
          }}
          key={index}>
          {rowData[header.key] === 'CREDIT' ? (
            <Box sx={{ textAlign: 'center' }}>
              <ArrowUpward sx={{ color: SUCCESS_COLOR }} />
              <Typography sx={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                Credit
              </Typography>
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <ArrowDownward sx={{ color: ALERT_COLOR }} />
              <Typography sx={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                Debit
              </Typography>
            </Box>
          )}
        </StyledCell>
      );
    } else {
      return (
        <StyledCell
          scope="row"
          sx={{
            color: 'rgba(0, 0, 0, 0.70)',
            borderBottom: 'none',
            width: header.key === 'comment' ? '20%' : '16%'
          }}
          key={index}>
          {rowData[header.key]}
        </StyledCell>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
        {HEADERS.map((header, index) => getColumnData(header, index))}
      </TableRow>
    </React.Fragment>
  );
}

const createTableHeader = () => {
  return (
    <Box sx={{ display: 'flex', height: '56.5px' }}>
      <StyledSpannedCell sx={{ width: '45%', paddingLeft: '0px !important' }}>
        Threshholds
      </StyledSpannedCell>
      <StyledSpannedCell sx={{ width: '55%', paddingLeft: '0px !important' }}>
        Approver
      </StyledSpannedCell>
    </Box>
  );
};

let maxPageGenerated: any = 1;

export default function AdHocDefinitions() {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [transactiosData, setTransactionsData] = useState([]);

  const API = useAxios();

  const fetchTransactionTypes = async (page = currentPage) => {
    setShowLoader(true);
    try {
      const response = await getTransactionTypesAPI(API, page);
      if (response?.data?.dataList?.length) {
        setTransactionsData(response?.data?.dataList);
        if (
          response.data?.dataList?.length === PAGE_SIZE &&
          currentPage >= maxPageGenerated - 1
        ) {
          setPageCount((pageCount) => pageCount + 1);
        }
      }
    } catch (err) {
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchTransactionTypes();
  }, [currentPage]);

  const onPageChange = (page) => {
    if (page > maxPageGenerated) {
      maxPageGenerated = page;
    }
    setCurrentPage(page - 1);
  };

  return (
    <>
      <Typography sx={{ fontWeight: 600, mb: 4 }}>Transaction Types</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead sx={{ background: '#F7F7F7', color: '#808080' }}>
            <TableRow>
              {HEADERS.map((header, index) => {
                return header.key === 'threshhold' ? (
                  <StyledTableHeader key={`${header.key}-${index}`}>
                    {createTableHeader()}
                  </StyledTableHeader>
                ) : (
                  <StyledTableHeader key={`${header.key}-${index}`}>
                    {header.label}
                  </StyledTableHeader>
                );
              })}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {!!transactiosData.length &&
              transactiosData.map((data: any, index) => (
                <DataRow key={index} rowData={data} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!transactiosData.length && (
        <Typography
          sx={{ margin: '30px 0px', textAlign: 'center', fontSize: '18px' }}>
          No Data Available
        </Typography>
      )}
      {pageCount >= 0 && (
        <CustomPagination
          page={currentPage + 1}
          setPage={onPageChange}
          pageCount={pageCount}
          dataLength={transactiosData.length}
        />
      )}
      {showLoader && <SimpleBackdrop open={showLoader} />}
    </>
  );
}

const StyledSpannedCell = styled(Box)`
  padding: 16px;
`;

const StyledTableCell = styled(TableCell)`
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0px;
`;

const StyledCell = styled(TableCell)`
  border-left: 1px solid rgba(0, 0, 0, 0.05);
`;

const StyledTableHeader = styled(TableCell)`
  borderleft: none;
  padding: 0px;
  padding-left: 10px;
  color: #808080;
  font-size: 15px;
`;
