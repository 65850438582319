import { AxiosInstance } from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SimpleBackdrop from 'src/components/Backdrop';
import useAxios from 'src/hooks/useAxios';
import { ROUTES } from 'src/modules/routingConstant';
import { createNewAnnouncement } from '../actions';
import { APP_NAME, NOTICE_TYPES } from '../constants';
import InboxForm from '../form';

const AddTutorial = () => {
  const API: AxiosInstance = useAxios();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const createData = (values, mediaData) => {
    const data = { ...values };
    data.endDate = moment(values.endDate).valueOf() + 86399000; // changing time from 12AM to 23:59:59
    const geographyIds =
      values?.geographyIds.map((elt: any) => elt.value) || [];
    const roles = values?.roles.map((elt: any) => elt.value) || [];
    data.roles = roles;
    data.geographyIds = geographyIds;
    data.startDate = moment(values.startDate).valueOf();
    data.appName = APP_NAME;
    data.announcementMediaId = mediaData?.announcementMediaId;
    data.announcementMediaURL = mediaData?.announcementMediaURL;
    delete data.img;
    data.noticeType = NOTICE_TYPES.tutorial;
    return data;
  };

  const handleCreate = async (values, mediaData) => {
    const newAnnouncementdata = createData(values, mediaData);
    try {
      setLoading(true);
      const response = await createNewAnnouncement(API, newAnnouncementdata);
      if (response?.data) {
        enqueueSnackbar('Tutorial created successfully', {
          variant: 'success'
        });
        history.push(ROUTES.tutorials);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <InboxForm isTutorial={true} handleCreate={handleCreate} />
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default AddTutorial;
