import React, { useState } from 'react';
import {
    useIsAuthenticated,
    useMsal,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import { loginRequest } from '../modules/MsalAuth/authConfig';

export const useMsalAuth = () => {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [accessToken, setAccessToken] = useState<any>(null);

    const loginMsal = async () => {
        try {
            await instance.loginRedirect(loginRequest).then((response) => {
                handleResponse(response);
            }).catch((err) => {
                console.log("MSal Login failed")
            });
        } catch (error) {
            alert('login failed');
        }
    };

    const logout = () => {
        instance.logout();
        localStorage.clear();
    };

    const handleResponse = (response) => {
        if (response !== null && response?.account !== null) {
            setAccessToken(response?.accessToken);
        }
    };

    return {
        accessToken,
        loginMsal,
        logout,
        isAuthenticated,
        instance,
        accounts,
        AuthenticatedTemplate,
        UnauthenticatedTemplate
    };
};
