import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import AppWithMsalAuth from './modules/MsalAuth/AppWithMsalAuth';
import './app.css';
import AnalyticsComponent from './modules/Firebase/PageAnalytics';


const App = () => {
  return (
    <BrowserRouter>
      <AnalyticsComponent />
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        autoHideDuration={1500}
        preventDuplicate={true}>
        <AppWithMsalAuth />
      </SnackbarProvider>
    </BrowserRouter>
  );
};

export default App;
