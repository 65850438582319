import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ALERT_COLOR, PRIMARY_COLOR, SUCCESS_COLOR } from 'src/constants';
import CustomButton from 'src/components/CustomButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const CommonQueryPopup = ({ open, handleClose, handleReExecute, isQueryExExecutedSuccess, popupMessage, handleDismiss }) => {
  return (
    <Dialog open={open} >
      <DialogContent>
        {isQueryExExecutedSuccess &&
          <Box>
            <Grid container style={{ "display": "flex" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <IconButton
                  onClick={handleDismiss}
                  sx={{
                    float: 'right',
                    marginBottom: '10px'
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <Box textAlign={'center'} marginBottom={'20px'}>
                  <CheckCircleOutlineIcon
                    sx={{
                      height: '111px',
                      width: '111px',
                      color: SUCCESS_COLOR
                    }} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <Box textAlign={'center'}>
                  <Typography color={SUCCESS_COLOR}>
                    Re-execution has been triggered successfully
                  </Typography>
                  <Typography>
                    Please rerun the query in {popupMessage} Minutes to see changes.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
        {!isQueryExExecutedSuccess &&
          <Box>
            <Grid container style={{ "display": "flex" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <IconButton
                  onClick={handleClose}
                  sx={{
                    float: 'right',
                    marginBottom: '10px'
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <Box textAlign={'center'} marginBottom={'20px'}>
                  <CancelOutlinedIcon
                    sx={{
                      height: '111px',
                      width: '111px',
                      color: ALERT_COLOR
                    }} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <Box textAlign={'center'}>
                  <Typography color={ALERT_COLOR}>
                    Error
                  </Typography>
                  <Typography>
                    Could not re-execute entries due to:
                  </Typography>
                  <Typography>
                    Error occurred during syncing entries
                  </Typography>
                  <Typography>
                    Please retry to re-execute
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          marginBottom: '25px'
        }}
      >
        {isQueryExExecutedSuccess &&
          <Button size="medium"
            sx={{ color: PRIMARY_COLOR, textTransform: 'capitalize' }}
            onClick={handleDismiss}
          >
            Dismiss
          </Button>
        }
        {!isQueryExExecutedSuccess &&
          <Grid container style={{ "display": "flex" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} textAlign={'center'}>
              <CustomButton
                title='Retry'
                buttonType="button"
                variant='contained'
                marginRight='0'
                onClick={handleReExecute}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} textAlign={'center'}>
              <Button size="medium"
                sx={{ color: PRIMARY_COLOR, marginTop: '20px', textTransform: 'capitalize' }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        }

      </DialogActions>
    </Dialog>
  )
}

export default CommonQueryPopup;
