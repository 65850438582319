import { Box, Tab, Tabs } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useRef, useState } from 'react';
import Bookings from './bookings/Bookings';
import Expense from './expense/Expense';
import CollectionSettlements from './collectionSettlements/CollectionSettlements';
import CommonFilterForReconciliation from './commonFilter/CommonFilterForReconciliation';

const LedgerReconciliation = () => {

    const [formValues, setFormValues] = useState<any>();
    const [tabValue, setTabValue] = useState('booking');
    const [isOperatorSearched, setIsOperatorSearched] = useState(false);
    const childRef = useRef<any>(null);

    const handleSearchOperator = () => {
        setIsOperatorSearched(true);
        childRef?.current?.handleSearch();
    }

    const handleChange = (event: any, newValue: string) => {
        setTabValue(newValue);
        setIsOperatorSearched(false);
    };

    return (
        <Box>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange}
                        sx={{
                            '& .Mui-selected': {
                                color: '#4003A6 !important',
                            },
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#4003A6',
                            },
                        }}>
                        <Tab label="Booking" value="booking" />
                        <Tab label="Expense" value="expense" />
                        <Tab label="Collection Settlements" value="collectionSettlements" />
                    </TabList>
                </Box>
                <Box>
                    <CommonFilterForReconciliation
                        setFormValues={setFormValues}
                        onSearch={handleSearchOperator}
                        openedTab={tabValue}
                    />
                </Box>
                <TabPanel value="booking" sx={{ padding: 0, marginTop: '10px' }}>
                    <Bookings
                        isOperatorSearched={isOperatorSearched}
                        searchFormValues={formValues}
                        forwardedRef={childRef}
                    />
                </TabPanel>
                <TabPanel value="expense" sx={{ padding: 0, marginTop: '10px' }}>
                    <Expense
                        isOperatorSearched={isOperatorSearched}
                        searchFormValues={formValues}
                        forwardedRef={childRef}
                    />
                </TabPanel>
                <TabPanel value="collectionSettlements" sx={{ padding: 0, marginTop: '10px' }}>
                    <CollectionSettlements
                        isOperatorSearched={isOperatorSearched}
                        searchFormValues={formValues}
                        forwardedRef={childRef}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    )
}



export default LedgerReconciliation
