import { PAGE_SIZE } from '../PartnerUpload/constant';
import { END_POINTS } from './constants';

const {
  preSignedUrl,
  processFileUrl,
  getTransactionTypesUrl,
  getFileTransactionsUrl,
  transactionUploadHistoryUrl
} = END_POINTS;

// used for getting PreSignedUrl for uploading and downloading file
export const getPreSignedUrlAPI = async (axiosInstance, body) => {
  const res = await axiosInstance.post(`${preSignedUrl}`, body);
  return res;
};

export const processFileAPI = async (axiosInstance, body) => {
  const res = await axiosInstance.post(`${processFileUrl}`, body);
  return res;
};

export const getTransactionTypesAPI = async (axiosInstance, currentPage) => {
  const body: any = {
    offset: currentPage * PAGE_SIZE,
    limit: PAGE_SIZE
  };
  const res = await axiosInstance.post(`${getTransactionTypesUrl}`, {
    ...body
  });
  return res;
};

export const getFileTransactionsAPI = async (axiosInstance, body) => {
  const res = await axiosInstance.post(`${getFileTransactionsUrl}`, body);
  return res;
};

export const getTransactionUploadHistoryAPI = async (axiosInstance, body) => {
  const res = await axiosInstance.post(`${transactionUploadHistoryUrl}`, body);
  return res;
};
